import {
  IActivityBase,
  IActivityItemBase,
  IActivityScheduleBase,
  IActivityScheduleBlockBase,
} from '../../models/activities';
import { IBookingBase } from '../../models/bookings';
import { ISpotBase, ISpotModalityBase } from '../../models/spots';
import { IVentureBase } from '../../models/ventures';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IDetailedActivitySchedule extends IActivityScheduleBase {
  bookings: IBookingBase[];
  modality?: ISpotModalityBase;
  scheduleBlocks: IActivityScheduleBlockBase[];
}

interface IDetailedActivitySpot extends ISpotBase {
  modalities: ISpotModalityBase[];
  venture: IVentureBase;
}

export interface IDetailedActivity extends IActivityBase {
  items: IActivityItemBase[];
  schedules: IDetailedActivitySchedule[];
  spot: IDetailedActivitySpot;
}

export const showActivitiesService = async (
  activityId: string,
): Promise<IDetailedActivity> => {
  const { data } = await wbsBookingsApi.get<IDetailedActivity>(
    `/activities/${activityId}`,
  );

  return data;
};
