import {
  Text,
  ButtonGroup,
  Button,
  Icon,
  HStack,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { useUser } from '../../../../../../../../../../../hooks/user';
import { createWalletsService } from '../../../../../../../../../../../services/Wallets/CreateWalletsService';
import { createWalletTransactionsService } from '../../../../../../../../../../../services/Wallets/CreateWalletTransactionsService';
import {
  IDetailedWallet,
  showWalletsService,
} from '../../../../../../../../../../../services/Wallets/ShowWalletsService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import {
  maskDateTime,
  maskMoney,
} from '../../../../../../../../../../../utils/formatters/handleMask';
import { WalletTransactionModal } from './components/WalletTransactionModal';
import {
  IWalletTransactionTableItem,
  WalletTransactionTable,
} from './components/WalletTransactionTable';

interface IWallet extends IDetailedWallet {
  formattedBalance?: string;
  transactions: IWalletTransactionTableItem[];
}
const Wallet = (): JSX.Element => {
  const { user: authenticatedUser } = useAuth();
  const { user: member } = useUser();

  const toast = useToast();

  const [wallet, setWallet] = useState<IWallet>();
  const [isLoading, setIsLoading] = useState(true);
  const [walletTransactionType, setWalletTransactionType] = useState<
    'INCOME' | 'OUTCOME'
  >();

  useEffect(() => {
    async function loadWallet(walletOwnerId: string): Promise<void> {
      const userWallet = await showWalletsService(walletOwnerId);

      setWallet(
        userWallet
          ? {
              ...userWallet,
              formattedBalance: maskMoney(userWallet.balance || 0),
              transactions: userWallet.transactions.map((transaction) => ({
                ...transaction,
                formattedAmount: maskMoney(transaction.amount),
                formattedCreatedAt: maskDateTime(transaction.createdAt),
              })),
            }
          : undefined,
      );
      setIsLoading(false);
    }

    if (member?.id) {
      loadWallet(member?.id);
    }
  }, [member?.id]);

  const handleNewWallet = useCallback(async () => {
    if (member?.id) {
      const userWallet = await createWalletsService(member.id);

      setWallet({
        ...userWallet,
        formattedBalance: maskMoney(userWallet.balance || 0),
      });
    }
  }, [member?.id]);

  const handleWalletTransactionType = useCallback(
    (type?: 'INCOME' | 'OUTCOME') => {
      setWalletTransactionType(type);
    },
    [],
  );

  const handleTransaction = useCallback(
    async (amount: number) => {
      if (!!wallet && !!walletTransactionType) {
        try {
          const updatedWallet = await createWalletTransactionsService({
            amount,
            type: walletTransactionType,
            walletId: wallet.id,
          });

          setWallet({
            ...updatedWallet,
            formattedBalance: maskMoney(updatedWallet.balance || 0),
            transactions: updatedWallet.transactions.map((transaction) => ({
              ...transaction,
              formattedAmount: maskMoney(transaction.amount),
              formattedCreatedAt: maskDateTime(transaction.createdAt),
            })),
          });

          handleWalletTransactionType(undefined);

          toast({
            title: 'Transação concluída com sucesso',
            description: 'A transação foi efetuada corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha na transação',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao efetuar a transação, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [handleWalletTransactionType, toast, wallet, walletTransactionType],
  );

  return (
    <>
      {!wallet ? (
        <ButtonGroup justifyContent="flex-end" w="100%">
          {!isLoading &&
            authenticatedUser.featureGroup.features.some((feature) =>
              ['WALLET_FULL_ACCESS', 'WALLET_WRITE_ACCESS'].includes(
                feature.key,
              ),
            ) && (
              <Button
                colorScheme="green"
                size="sm"
                leftIcon={<Icon as={RiAddLine} fontSize="16" />}
                onClick={handleNewWallet}
              >
                Criar carteira
              </Button>
            )}
        </ButtonGroup>
      ) : (
        <>
          <WalletTransactionModal
            isOpen={!!walletTransactionType}
            onClose={handleWalletTransactionType}
            onSave={handleTransaction}
          />

          <HStack justifyContent="space-between">
            <HStack fontSize="2xl">
              <Text fontWeight="bold">Saldo:</Text>
              <Text
                color={(wallet.balance || 0) >= 0 ? 'green.500' : 'red.500'}
              >
                {wallet.formattedBalance}
              </Text>
            </HStack>

            {authenticatedUser.featureGroup.features.some((feature) =>
              ['WALLET_FULL_ACCESS', 'WALLET_WRITE_ACCESS'].includes(
                feature.key,
              ),
            ) && (
              <ButtonGroup>
                <Button
                  colorScheme="green"
                  size="sm"
                  leftIcon={<Icon as={RiAddLine} fontSize="16" />}
                  onClick={() => handleWalletTransactionType('INCOME')}
                >
                  Adicionar Crédito
                </Button>

                <Button
                  size="sm"
                  colorScheme="red"
                  leftIcon={<Icon as={RiSubtractLine} fontSize="20" />}
                  onClick={() => handleWalletTransactionType('OUTCOME')}
                >
                  Remover Crédito
                </Button>
              </ButtonGroup>
            )}
          </HStack>

          <WalletTransactionTable transactions={wallet.transactions} />
        </>
      )}
    </>
  );
};

export default Wallet;
