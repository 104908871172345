import { IActivityBase, IActivityScheduleBase } from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { ISpotBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IListBookingQueueProps {
  activityId?: string;
  activityName?: string;
  bookedDate?: string;
  showQueuedBookingsOnly?: boolean;
  spotName?: string;
  userDocument?: string;
  userName?: string;
  userId?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface IBookingQueueListActivity extends IActivityBase {
  spot: ISpotBase;
}

export interface IBookingQueueListItem extends IBookingQueueBase {
  activity: IBookingQueueListActivity;
  activitySchedule?: IActivityScheduleBase;
  user: IUserBase;
}

export interface IBookingQueueList {
  items: IBookingQueueListItem[];
  pages: number;
  total: number;
}

export const listBookingQueuesService = async (
  filters?: IListBookingQueueProps,
): Promise<IBookingQueueList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          activityId: filters.activityId || undefined,
          activityName: filters.activityName || undefined,
          bookedDate: filters.bookedDate || undefined,
          showQueuedBookingsOnly: filters.showQueuedBookingsOnly,
          spotName: filters.spotName || undefined,
          userDocument: filters.userDocument || undefined,
          userName: filters.userName || undefined,
          userId: filters.userId,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await wbsBookingsApi.get<IBookingQueueList>(
    '/booking-queues',
    {
      params,
    },
  );

  return data;
};
