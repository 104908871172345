import wbsBookingsApi from '../apis/wbsBookingsApi';
import { IDetailedUser } from './ShowUsersService';

interface IResetPasswordProps {
  password: string;
  passwordConfirmation: string;
  token: string;
}

const confirmUsersSignupService = async ({
  password,
  passwordConfirmation,
  token,
}: IResetPasswordProps): Promise<IDetailedUser> => {
  const { data } = await wbsBookingsApi.put<IDetailedUser>(
    '/sessions/signup-confirm',
    {
      password,
      passwordConfirmation,
      token,
    },
  );

  return data;
};

export default confirmUsersSignupService;
