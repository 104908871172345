import React, { createContext, useCallback, useState, useContext } from 'react';
import { IWalletTransactionBase } from '../models/wallets';
import { IDetailedBooking } from '../services/Bookings/ShowBookingsService';
import {
  maskDate,
  maskDateTime,
  maskMoney,
} from '../utils/formatters/handleMask';

interface IBookingContextTransactions extends IWalletTransactionBase {
  formattedAmount: string;
  formattedCreatedAt: string;
}

export interface IBookingContextState extends IDetailedBooking {
  formattedBookedDate: string;
  formattedBookingPrice: string;
  formattedCreatedAt: string;
  formattedCheckinAt?: string;
  formattedCheckoutAt?: string;
  formattedEndTime: string;
  formattedPaymentPendingAmount: string;
  formattedStartTime: string;
  transactions: IBookingContextTransactions[];
}

interface IBookingContextData {
  booking?: IBookingContextState;
  handleBooking: (booking?: IDetailedBooking) => void;
}

const BookingContext = createContext<IBookingContextData>(
  {} as IBookingContextData,
);

const BookingProvider: React.FC = ({ children }) => {
  const [booking, setBooking] = useState<IBookingContextState>();

  const handleBooking = useCallback((bookingData?: IDetailedBooking) => {
    if (bookingData) {
      const [bookedYear, bookedMonth, bookedDay] =
        bookingData.bookedDate.split('-');

      setBooking({
        ...bookingData,
        formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
        formattedBookingPrice: bookingData.bookingPrice
          ? maskMoney(bookingData.bookingPrice)
          : '-',
        formattedCreatedAt: maskDate(bookingData.createdAt),
        formattedCheckinAt: bookingData.checkinAt
          ? maskDateTime(bookingData.checkinAt)
          : undefined,
        formattedCheckoutAt: bookingData.checkoutAt
          ? maskDateTime(bookingData.checkoutAt)
          : undefined,
        formattedEndTime: bookingData.endTime
          ? bookingData.endTime.slice(11, 16)
          : bookingData.activitySchedule.endTime.slice(0, 5),
        formattedPaymentPendingAmount: maskMoney(
          bookingData.paymentPendingAmount,
        ),
        formattedStartTime: bookingData.startTime
          ? bookingData.startTime.slice(11, 16)
          : bookingData.activitySchedule.startTime.slice(0, 5),
        transactions: bookingData.transactions.map((transaction) => ({
          ...transaction,
          formattedAmount: maskMoney(transaction.amount),
          formattedCreatedAt: maskDateTime(transaction.createdAt),
        })),
      });
    } else {
      setBooking(undefined);
    }
  }, []);

  return (
    <BookingContext.Provider
      value={{
        booking,
        handleBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

const useBooking = (): IBookingContextData => useContext(BookingContext);

export { BookingProvider, useBooking };
