import { IVentureBase } from '../../models/ventures';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IListVenturesProps {
  cnpj?: string;
  email?: string;
  name?: string;
  phone?: string;
  sort?: 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export type IVentureListItem = IVentureBase;

export interface IVentureList {
  items: IVentureListItem[];
  pages: number;
  total: number;
}

export const listVenturesService = async (
  filters?: IListVenturesProps,
): Promise<IVentureList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          cnpj: filters.cnpj || undefined,
          email: filters.email || undefined,
          name: filters.name || undefined,
          phone: filters.phone || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await wbsBookingsApi.get<IVentureList>('/ventures', {
    params,
  });

  return data;
};
