import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { EventProps } from 'react-big-calendar';

export const EventComponent: React.FC<EventProps> = ({ title }: EventProps) => (
  <Box display="flex" justifyContent="space-around" width="100%" height="100%">
    <Box
      display="flex"
      alignItems="center"
      bg="teal"
      height="100%"
      // width="98%"
      borderRadius={10}
      borderColor="teal"
      p={3}
    >
      <Text
        fontSize="md"
        color="white"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {title}
      </Text>
    </Box>
  </Box>
);
