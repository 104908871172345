import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  ButtonGroup,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { RiAddLine, RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../../../../../components/LinkButton';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { useUser } from '../../../../../../../../../../../hooks/user';
import { IUserBase } from '../../../../../../../../../../../models/users';
import { listUserGuestsService } from '../../../../../../../../../../../services/Users/ListUserGuestsService';

type IMemberDependantTableProps = BoxProps;

export const MemberDependantTable = (
  props: IMemberDependantTableProps,
): JSX.Element => {
  const { user: authenticatedUser } = useAuth();

  const { user: member } = useUser();

  const [dependants, setDependants] = useState<IUserBase[]>([]);

  useEffect(() => {
    async function loadMemberGuests(memberId: string): Promise<void> {
      const dependantsList = await listUserGuestsService({
        groupKey: 'DEPENDANT',
        hostId: memberId,
      });

      setDependants(dependantsList.items);
    }

    if (member?.id) {
      loadMemberGuests(member.id);
    }
  }, [member?.id, setDependants]);

  return (
    <Box {...props}>
      {!!member && (
        <>
          <ButtonGroup justifyContent="flex-end" w="full">
            {authenticatedUser.featureGroup.features.some((feature) =>
              ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(feature.key),
            ) && (
              <LinkButton
                colorScheme="green"
                icon={<Icon as={RiAddLine} fontSize="16" />}
                to={{
                  pathname: '/guests/register',
                  state: {
                    groupKey: 'DEPENDANT',
                    hostId: member.id,
                  },
                }}
              >
                Register new
              </LinkButton>
            )}
          </ButtonGroup>

          <Table colorScheme="blue">
            <Thead>
              <Tr>
                <Th>Name</Th>

                <Th>Phone</Th>

                <Th textAlign="center" w="8" />
              </Tr>
            </Thead>

            <Tbody>
              {dependants.map((dependant) => (
                <Tr key={dependant.id}>
                  <Td>
                    <Text fontWeight="bold">{dependant.name}</Text>
                  </Td>

                  <Td>{dependant.phone}</Td>

                  <Td textAlign="center">
                    <LinkButton
                      px={0}
                      to={{
                        pathname: '/members/details',
                        state: {
                          memberId: dependant.id,
                        },
                      }}
                    >
                      <Icon as={RiEyeLine} fontSize="16" />
                    </LinkButton>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  );
};
