import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IDeleteActivitySchedulesServiceProps {
  activityScheduleId: string;
  isVerified?: boolean;
}

const deleteActivitySchedulesService = async ({
  activityScheduleId,
  isVerified,
}: IDeleteActivitySchedulesServiceProps): Promise<void> => {
  await wbsBookingsApi.delete(`/activities/schedules/${activityScheduleId}`, {
    params: { isVerified },
  });
};

export default deleteActivitySchedulesService;
