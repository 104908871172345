import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { translateError } from '../../../../../utils/errors';
import { validateCpf } from '../../../../../utils/validateCPF';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { useAuth } from '../../../../../hooks/auth';
import { UserExperience } from '../../../../../models/users';
import { listUserCategoriesService } from '../../../../../services/UserCategories/ListUserCategoriesService';

type NewMemberFormData = {
  bio?: string;
  categoryId?: string;
  cpf?: string;
  email: string;
  experience: UserExperience;
  healthCheck?: Date;
  name: string;
  phone?: string;
  ventureId: string;
};

const registerMemberFormSchema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  categoryId: Yup.string().uuid().required('Categoria requerida'),
  cpf: Yup.string()
    .length(11, 'Valor inválido, Required 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail Required')
    .transform((value) => value.toLowerCase()),
  experience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 4 e 5'],
      UserExperience['Nível 5 e 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  healthCheck: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Name Required'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ventureId: Yup.string().uuid().required('Tenant required'),
});

const experienceSelectOptions = [
  {
    label: 'Nível 1 e 2',
    value: UserExperience['Nível 1 e 2'],
  },
  {
    label: 'Nível 3 e 4',
    value: UserExperience['Nível 3 e 4'],
  },
  {
    label: 'Nível 4 e 5',
    value: UserExperience['Nível 4 e 5'],
  },
  {
    label: 'Nível 5 e 6',
    value: UserExperience['Nível 5 e 6'],
  },
  {
    label: 'Nível 6 e 7',
    value: UserExperience['Nível 6 e 7'],
  },
  {
    label: 'Nível 7',
    value: UserExperience['Nível 7'],
  },
  {
    label: 'Especial',
    value: UserExperience.Especial,
  },
];

export const MemberRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const { user } = useAuth();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [userCategoriesSelectOptions, setUserCategoriesSelectOptions] =
    useState<SelectOption[]>([]);

  const { register, handleSubmit, formState, control, setValue, watch } =
    useForm({
      resolver: yupResolver(registerMemberFormSchema),
    });

  const { errors } = formState;

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();

      setVenturesSelectOptions(
        ventures.map((venture) => ({
          label: venture.name,
          value: venture.id,
        })),
      );
    }

    if (!user.ventureId) {
      loadVentures();
    } else {
      setValue('ventureId', user.ventureId);
    }
  }, [setValue, user.ventureId]);

  useEffect(() => {
    setValue('experience', UserExperience['Nível 1 e 2']);
  }, [setValue]);

  const selectedVentureId = watch('ventureId');

  useEffect(() => {
    async function loadUserCategories(ventureId: string): Promise<void> {
      const userCategories = await listUserCategoriesService(ventureId);

      setUserCategoriesSelectOptions(
        userCategories.map((userCategory) => ({
          label: userCategory.title,
          value: userCategory.id,
        })),
      );
    }

    if (selectedVentureId) {
      loadUserCategories(selectedVentureId);
    }
  }, [selectedVentureId]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);

    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);

    setAvatarUrl(undefined);
  }, []);

  const handleNewMember: SubmitHandler<NewMemberFormData> = useCallback(
    async ({
      bio,
      categoryId,
      cpf,
      email,
      experience,
      healthCheck,
      name,
      phone,
      ventureId,
    }) => {
      try {
        const member = await createUsersService({
          bio,
          categoryId,
          cpf,
          email,
          experience,
          healthCheck,
          name,
          phone,
          featureGroupKey: 'MEMBER',
          ventureId,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateUserAvatarsService({
            avatarData: formData,
            userId: member.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O Member foi cadastrado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/members/details', { memberId: member.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o Member, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewMember)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar Member
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Full name"
            error={errors.name}
            {...register('name')}
          />

          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
              'repeat(12, 1fr)',
              'repeat(12, 1fr)',
            ]}
            columnGap="4"
            rowGap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 6, 6, 6, 4]}>
              <MaskedInput
                label="Document"
                mask="cpf"
                error={errors.cpf}
                {...register('cpf')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6, 6, 6, 4]}>
              <InternationalPhoneInput
                label="Phone"
                name="phone"
                control={control}
                error={errors.phone}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6, 6, 6, 4]}>
              <MaskedInput
                label="E-mail"
                type="email"
                textTransform="lowercase"
                error={errors.email}
                {...register('email')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6, 6, 6, !user.ventureId ? 2 : 4]}>
              <DatePicker
                label="Exame médico"
                maxDate={new Date()}
                control={control}
                error={errors.healthCheck}
                {...register('healthCheck')}
              />
            </GridItem>

            {!user.ventureId && (
              <GridItem colSpan={[12, 12, 12, 12, 12, 5]}>
                <ReactSelect
                  label="Tenant"
                  name="ventureId"
                  options={venturesSelectOptions}
                  control={control}
                  error={errors.ventureId}
                />
              </GridItem>
            )}

            <GridItem colSpan={[12, 12, 6, 6, 6, !user.ventureId ? 2 : 4]}>
              <ReactSelect
                label="Nível de experiência"
                name="experience"
                options={experienceSelectOptions}
                control={control}
                error={errors.experience}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6, 6, 6, !user.ventureId ? 3 : 4]}>
              <ReactSelect
                label="Categoria"
                emptyOptionsMessage={!selectedVentureId && 'Select a tenant'}
                name="categoryId"
                options={userCategoriesSelectOptions}
                control={control}
                error={errors.categoryId}
              />
            </GridItem>
          </Grid>

          <MaskedInput
            label="Biography"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
