import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IDeleteSpotModalitiesProps {
  modalityId: string;
  isVerified?: boolean;
}

const deleteSpotModalitiesService = async ({
  modalityId,
  isVerified,
}: IDeleteSpotModalitiesProps): Promise<void> => {
  await wbsBookingsApi.delete(`/spots/modalities/${modalityId}`, {
    params: { isVerified },
  });
};

export default deleteSpotModalitiesService;
