import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showUsersService } from '../../../../../services/Users/ShowUsersService';
import { translateError } from '../../../../../utils/errors';
import UserDetailsData from './components/UserDetailsData';
import { UserDetailsInfo } from './components/UserDetailsInfo';
import { useUser } from '../../../../../hooks/user';

interface ILocationState {
  userId: string;
}

export const UserDetails = (): JSX.Element => {
  const toast = useToast();
  const { handleUser, user } = useUser();

  const {
    state: { userId },
  } = useLocation<ILocationState>();

  useEffect(() => {
    async function loadUser(id: string): Promise<void> {
      try {
        const userData = await showUsersService(id);

        handleUser(userData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Failed to load data',
            description:
              translateError({ message: err.response?.data.message }) ||
              'An error occurred while loading the employee details, please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadUser(userId);

    return () => handleUser(undefined);
  }, [userId, toast, handleUser]);

  return (
    <DefaultLayout>
      {user && (
        <Flex direction="column" w="100%">
          <UserDetailsInfo minW="510px" />

          <UserDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
