import {
  IActivityBase,
  IActivityItemBase,
  IActivityScheduleBase,
} from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { ISpotBase, ISpotModalityBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IDetailedBookingQueueActivity extends IActivityBase {
  spot: ISpotBase;
}

export interface IDetailedActivitySchedule extends IActivityScheduleBase {
  modality?: ISpotModalityBase;
}

export interface IDetailedBookingQueue extends IBookingQueueBase {
  activity: IDetailedBookingQueueActivity;
  activitySchedule?: IDetailedActivitySchedule;
  user: IUserBase;
  items: IActivityItemBase[];
  modalities: ISpotModalityBase[];
}

export const showBookingQueuesService = async (
  bookingQueueId: string,
): Promise<IDetailedBookingQueue> => {
  const { data } = await wbsBookingsApi.get<IDetailedBookingQueue>(
    `/booking-queues/${bookingQueueId}`,
  );

  return data;
};
