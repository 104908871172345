import wbsBookingsApi from '../apis/wbsBookingsApi';
import { IDetailedUser } from '../Users/ShowUsersService';

interface IResetPasswordProps {
  password: string;
  passwordConfirmation: string;
  token: string;
}

const resetPasswordsService = async ({
  password,
  passwordConfirmation,
  token,
}: IResetPasswordProps): Promise<IDetailedUser> => {
  const { data } = await wbsBookingsApi.post<IDetailedUser>(
    '/passwords/reset',
    {
      password,
      passwordConfirmation,
      token,
    },
  );

  return data;
};

export default resetPasswordsService;
