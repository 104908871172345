import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateFeatureGroupProps {
  featureGroupId: string;
  authLevel: number;
  key: string;
  features: IFeatureBase[];
  name: string;
}

export type IUpdatedFeatureGroup = IFeatureGroupBase;

export const updateFeatureGroupsService = async ({
  featureGroupId,
  authLevel,
  key,
  features,
  name,
}: IUpdateFeatureGroupProps): Promise<IUpdatedFeatureGroup> => {
  const { data } = await wbsBookingsApi.put<IUpdatedFeatureGroup>(
    `/feature-groups/${featureGroupId}`,
    {
      authLevel,
      key,
      features,
      name,
    },
  );

  return data;
};
