import { IActivityBase, IActivityScheduleBase } from '../../models/activities';
import { IBookingBase } from '../../models/bookings';
import { ISpotBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IProcessBookingPaymentActivity extends IActivityBase {
  spot: ISpotBase;
}

export interface IProcessBookingPaymentActivitySchedule
  extends IActivityScheduleBase {
  activity: IProcessBookingPaymentActivity;
}

export interface IProcessBookingPayment extends IBookingBase {
  activitySchedule: IProcessBookingPaymentActivitySchedule;
  user: IUserBase;
}

export const processBookingPaymentsService = async (
  bookingId: string,
): Promise<IProcessBookingPayment> => {
  const { data } = await wbsBookingsApi.post<IProcessBookingPayment>(
    '/bookings/payments',
    {
      bookingId,
    },
  );

  return data;
};
