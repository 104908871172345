import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  ButtonGroup,
  IconButton,
  HStack,
  Switch,
  VStack,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import {
  RiEyeLine,
  RiLoginCircleLine,
  RiLogoutCircleRLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';
import { IBookingBase } from '../../models/bookings';
import { UserExperience } from '../../models/users';
import { IBookingListItem } from '../../services/Bookings/ListBookingsService';
import { ConfirmationModal } from '../ConfirmationModal';
import { HandleCheckinModal } from '../HandleCheckinModal';
import { LinkButton } from '../LinkButton';
import { Pagination } from '../Pagination';
import { TableFilters } from '../TableFilters';

type SelectOption = {
  label: string;
  value: string;
};

interface IOnToggleCheckinProps {
  bookingId: string;
  checkinAt?: Date;
  checkoutAt?: Date;
  identifyBraceletNumber?: string;
}

export interface IBookingTableItem extends IBookingListItem {
  formattedBookedDate: string;
  formattedCreatedAt: string;
  formattedCheckinAt?: string;
  formattedCheckoutAt?: string;
  formattedEndTime: string;
  formattedStartTime: string;
}

interface IBookingTableProps extends BoxProps {
  bookings: IBookingTableItem[];
  currentPage?: number;
  filterBy?: string;
  filterByOptions?: SelectOption[];
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onPay: (bookingId: string) => void;
  onSort?: (sort: 'bookedDate' | 'createdAt') => void;
  onShowPending: () => void;
  onToggleBookingsTypeFilter?: (
    bookingsType: 'memberBookings' | 'guestsBookings',
  ) => void;
  onToggleCheckin: (data: IOnToggleCheckinProps) => void;
  order?: 'ASC' | 'DESC';
  showActivityColumn?: boolean;
  showMemberColumns?: boolean;
  showPending: boolean;
  sortBy?: string;
  sortByOptions?: SelectOption[];
  totalPages?: number;
}

export const BookingTable = ({
  bookings,
  currentPage,
  filterBy,
  filterByOptions = [
    {
      label: 'Calendar Name',
      value: 'activityName',
    },
    {
      label: 'Resource Name',
      value: 'spotName',
    },
    {
      label: 'Member Name',
      value: 'userName',
    },
    {
      label: 'Document do Member',
      value: 'userDocument',
    },
    {
      label: 'Booking date',
      value: 'bookedDate',
    },
  ],
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onPay,
  onSort,
  onShowPending,
  onToggleBookingsTypeFilter,
  onToggleCheckin,
  order,
  showActivityColumn = true,
  showMemberColumns = true,
  showPending,
  sortBy,
  sortByOptions = [
    {
      label: 'Booking date',
      value: 'bookedDate',
    },
    {
      label: 'Registration date',
      value: 'createdAt',
    },
  ],
  totalPages,
  ...rest
}: IBookingTableProps): JSX.Element => {
  const [handlingBooking, setHandlingBooking] = useState<IBookingTableItem>();
  const [bookingsTypeFilter, setBookingsTypeFilter] = useState<
    'memberBookings' | 'guestsBookings'
  >('memberBookings');
  const [isHandleCheckinModalVisible, setIsHandleCheckinModalVisible] =
    useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

  const handleToggleHandleCheckinModal = useCallback(() => {
    setIsHandleCheckinModalVisible((prevState) => !prevState);
  }, []);

  const handleTogglePaymentModal = useCallback(() => {
    setIsPaymentModalVisible((prevState) => !prevState);
  }, []);

  const handlePayment = useCallback(
    (booking: IBookingTableItem) => {
      setHandlingBooking(booking);

      handleTogglePaymentModal();
    },
    [handleTogglePaymentModal],
  );

  const handleCheckin = useCallback(
    (booking: IBookingTableItem) => {
      setHandlingBooking(booking);

      handleToggleHandleCheckinModal();
    },
    [handleToggleHandleCheckinModal],
  );

  const handleToggleBookingsTypeFilter = useCallback(
    (value: 'memberBookings' | 'guestsBookings') => {
      setBookingsTypeFilter(value);

      if (onToggleBookingsTypeFilter) {
        onToggleBookingsTypeFilter(value);
      }
    },
    [onToggleBookingsTypeFilter],
  );

  const handleFinishCheckin = useCallback(
    (booking: IBookingBase) => {
      if (booking.identifyBraceletNumber) {
        onToggleCheckin({
          bookingId: booking.id,
          checkinAt: !booking.checkinAt
            ? new Date()
            : new Date(booking.checkinAt),
          identifyBraceletNumber: booking.identifyBraceletNumber,
        });
      }
    },
    [onToggleCheckin],
  );

  const handleCheckout = useCallback(
    (booking: IBookingTableItem) => {
      if (booking.identifyBraceletNumber) {
        onToggleCheckin({
          bookingId: booking.id,
          checkoutAt: booking.checkinAt ? new Date() : undefined,
        });
      }
    },
    [onToggleCheckin],
  );

  return (
    <Box {...rest}>
      {handlingBooking && (
        <HandleCheckinModal
          booking={handlingBooking}
          isOpen={isHandleCheckinModalVisible}
          onClose={() => {
            setHandlingBooking(undefined);
            handleToggleHandleCheckinModal();
          }}
          onSave={(booking) => {
            handleToggleHandleCheckinModal();
            handleFinishCheckin(booking);
          }}
        />
      )}

      {!!handlingBooking && (
        <ConfirmationModal
          isOpen={isPaymentModalVisible}
          onClose={() => {
            setHandlingBooking(undefined);
            handleTogglePaymentModal();
          }}
          onConfirm={() => {
            onPay(handlingBooking.id);
            setHandlingBooking(undefined);
            handleTogglePaymentModal();
          }}
          title="Confirmar pagamento"
          message="O valor será debitado do saldo disponível na carteira do Member."
        />
      )}

      <HStack align="flex-end" spacing="4">
        <TableFilters
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          filterInputType={filterBy === 'bookedDate' ? 'datepicker' : 'input'}
          onChangeFilterBy={onChangeFilterBy}
          onFilter={onFilter}
          onOrder={onOrder}
          onSort={(sort) =>
            !!onSort && onSort(sort as 'bookedDate' | 'createdAt')
          }
          order={order}
          sortBy={sortBy}
          sortByOptions={sortByOptions}
        />

        <HStack align="flex-end" justifyContent="space-between" flex={1}>
          <HStack>
            <Switch
              name="isActive"
              id="isActive"
              isChecked={showPending}
              onChange={onShowPending}
            />
            <Text htmlFor="isActive">Pending bookings</Text>
          </HStack>

          {!!onToggleBookingsTypeFilter && (
            <VStack>
              <Text>Bookings</Text>

              <RadioGroup
                onChange={handleToggleBookingsTypeFilter}
                value={bookingsTypeFilter}
              >
                <HStack>
                  <Radio defaultChecked value="memberBookings">
                    Member
                  </Radio>
                  <Radio value="guestsBookings">Convidados</Radio>
                </HStack>
              </RadioGroup>
            </VStack>
          )}
        </HStack>
      </HStack>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            {!!showActivityColumn && <Th>Calendar</Th>}

            {/* {!!showMemberColumns && (
              <>
                <Th>Member</Th>

                <Th>Nível de experiência</Th>
              </>
            )} */}

            <Th>Resource</Th>
            <Th textAlign="center">Reservation Date/Time</Th>
            <Th textAlign="center">Booked On</Th>
            <Th textAlign="center">Wristband</Th>
            <Th textAlign="center">Check-in At</Th>
            <Th textAlign="center">Check-out At</Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {bookings.map((booking) => (
            <Tr key={booking.id}>
              {!!showActivityColumn && (
                <Td fontWeight="bold">
                  {booking.activitySchedule.activity.name}
                </Td>
              )}

              {!!showMemberColumns && (
                <>
                  <Td>{booking.user.name}</Td>

                  <Td>{UserExperience[booking.user.experience]}</Td>
                </>
              )}

              <Td>{booking.activitySchedule.activity.spot.name}</Td>

              <Td textAlign="center">
                <Box>
                  <Text>{booking.formattedBookedDate}</Text>
                  <Text>{`${booking.formattedStartTime} - ${booking.formattedEndTime}`}</Text>
                </Box>
              </Td>

              <Td textAlign="center">
                <Text>{booking.formattedCreatedAt.split('-')[0]}</Text>
                <Text>{booking.formattedCreatedAt.split('-')[1]}</Text>
              </Td>

              <Td textAlign="center">
                {booking.identifyBraceletNumber || '-'}
              </Td>

              <Td textAlign="center">
                <Text>{booking.formattedCheckinAt?.split('-')[0] || '-'}</Text>
                <Text>{booking.formattedCheckinAt?.split('-')[1]}</Text>
              </Td>

              <Td textAlign="center">
                <Text>{booking.formattedCheckoutAt?.split('-')[0] || '-'}</Text>
                <Text>{booking.formattedCheckoutAt?.split('-')[1]}</Text>
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  {!!booking.paymentPending && !booking.isExpired && (
                    <IconButton
                      aria-label="Payment"
                      size="sm"
                      icon={<Icon as={RiMoneyDollarCircleLine} fontSize="18" />}
                      fontSize="16"
                      colorScheme="red"
                      onClick={() => handlePayment(booking)}
                    />
                  )}

                  {!booking.paymentPending &&
                    !booking.formattedCheckinAt &&
                    !booking.isExpired && (
                      <IconButton
                        aria-label="Checkin"
                        size="sm"
                        icon={<Icon as={RiLoginCircleLine} fontSize="16" />}
                        fontSize="16"
                        colorScheme="green"
                        onClick={() => handleCheckin(booking)}
                      />
                    )}

                  {booking.formattedCheckinAt &&
                    !booking.formattedCheckoutAt && (
                      <IconButton
                        aria-label="Checkout"
                        size="sm"
                        icon={<Icon as={RiLogoutCircleRLine} fontSize="16" />}
                        fontSize="16"
                        colorScheme="red"
                        onClick={() => handleCheckout(booking)}
                      />
                    )}

                  <LinkButton
                    px={0}
                    to={{
                      pathname: '/bookings/details',
                      state: {
                        bookingId: booking.id,
                      },
                    }}
                  >
                    <Icon as={RiEyeLine} fontSize="16" />
                  </LinkButton>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
