import { IWalletBase } from '../../models/wallets';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface INewWallet extends IWalletBase {
  transactions: [];
}

export const createWalletsService = async (
  userId: string,
): Promise<INewWallet> => {
  const { data } = await wbsBookingsApi.post<INewWallet>('/wallets', {
    userId,
  });

  return data;
};
