import { IWalletBase, IWalletTransactionBase } from '../../models/wallets';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IUpdatedWallet extends IWalletBase {
  transactions: IWalletTransactionBase[];
}

interface ICreateWalletTransactionProps {
  amount: number;
  bookingId?: string;
  type: 'INCOME' | 'OUTCOME';
  walletId: string;
}

export const createWalletTransactionsService = async ({
  amount,
  bookingId,
  type,
  walletId,
}: ICreateWalletTransactionProps): Promise<IUpdatedWallet> => {
  const { data } = await wbsBookingsApi.post<IUpdatedWallet>(
    `/wallets/${walletId}/transactions`,
    {
      amount,
      bookingId,
      type,
    },
  );

  return data;
};
