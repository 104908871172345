import {
  Box,
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useBookingQueue } from '../../../../../../../../../hooks/bookingQueue';
import { BookingQueueItemTable } from './components/BookingQueueItemTable';

export const BookingQueueDetailsTabs = (props: BoxProps): JSX.Element => {
  const { bookingQueue } = useBookingQueue();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return bookingQueue ? (
    <Box {...props}>
      <Tabs
        index={tabIndex}
        onChange={handleTabChanges}
        variant="enclosed-colored"
      >
        <TabList>
          <Tab>Itens</Tab>
        </TabList>

        <TabPanels mt="4">
          <TabPanel>
            <BookingQueueItemTable bookingQueueItems={bookingQueue.items} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  ) : (
    <></>
  ); // loading view
};
