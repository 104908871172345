import { IActivityBase, IActivityScheduleBase } from '../../models/activities';
import { IBookingBase } from '../../models/bookings';
import { ISpotBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IListBookingProps {
  activityId?: string;
  activityName?: string;
  bookedDate?: string;
  hostId?: string;
  spotName?: string;
  titularUserId?: string;
  userDocument?: string;
  userName?: string;
  userId?: string;
  showPendingBookingsOnly?: boolean;
  sort?: 'activityName' | 'spotName' | 'userName' | 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface IBookingListActivity extends IActivityBase {
  spot: ISpotBase;
}

export interface IBookingListItemActivitySchedule
  extends IActivityScheduleBase {
  activity: IBookingListActivity;
}

export interface IBookingListItem extends IBookingBase {
  activitySchedule: IBookingListItemActivitySchedule;
  user: IUserBase;
}

export interface IBookingList {
  items: IBookingListItem[];
  pages: number;
  total: number;
}

export const listBookingsService = async (
  filters?: IListBookingProps,
): Promise<IBookingList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          activityId: filters.activityId || undefined,
          activityName: filters.activityName || undefined,
          bookedDate: filters.bookedDate || undefined,
          hostId: filters.hostId || undefined,
          spotName: filters.spotName || undefined,
          titularUserId: filters.titularUserId || undefined,
          userDocument: filters.userDocument || undefined,
          userName: filters.userName || undefined,
          userId: filters.userId || undefined,
          showPendingBookingsOnly: filters.showPendingBookingsOnly,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await wbsBookingsApi.get<IBookingList>('/bookings', {
    params,
  });

  return data;
};
