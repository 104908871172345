import { IActivityBase } from '../../models/activities';
import { ISpotBase } from '../../models/spots';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateActivityAvatarProps {
  activityId: string;
  avatarData: FormData;
}

export interface IUpdatedActivityAvatar extends IActivityBase {
  spot: ISpotBase;
}

export const updateActivityAvatarsService = async ({
  avatarData,
  activityId,
}: IUpdateActivityAvatarProps): Promise<IUpdatedActivityAvatar> => {
  const { data } = await wbsBookingsApi.patch<IUpdatedActivityAvatar>(
    `/avatars/activities/${activityId}`,
    avatarData,
  );

  return data;
};
