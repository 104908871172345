import React, { createContext, useCallback, useState, useContext } from 'react';
import {
  IActivityScheduleBase,
  IActivityScheduleBlockBase,
} from '../models/activities';
import { IBookingBase } from '../models/bookings';
import { ISpotModalityBase } from '../models/spots';
import { IDetailedActivity } from '../services/Activities/ShowActivitiesService';
import { maskDate } from '../utils/formatters/handleMask';

interface IActivityContextSchedule extends IActivityScheduleBase {
  bookings: IBookingBase[];
  formattedEndDate: string;
  formattedEndTime: string;
  formattedStartDate: string;
  formattedStartTime: string;
  modality?: ISpotModalityBase;
  scheduleBlocks: IActivityScheduleBlockBase[];
}

export interface IActivityContextState extends IDetailedActivity {
  formattedCreatedAt: string;
  schedules: IActivityContextSchedule[];
}

interface IActivityContextData {
  activity?: IActivityContextState;
  handleActivity: (activity?: IDetailedActivity) => void;
}

const ActivityContext = createContext<IActivityContextData>(
  {} as IActivityContextData,
);

const ActivityProvider: React.FC = ({ children }) => {
  const [activity, setActivity] = useState<IActivityContextState>();

  const handleActivity = useCallback((activityData?: IDetailedActivity) => {
    if (activityData) {
      const parsedActivitySchedules = activityData.schedules.map(
        (activitySchedule) => {
          const [startDateYear, startDateMonth, startDateDay] =
            activitySchedule.startDate.split('-');
          const [endDateYear, endDateMonth, endDateDay] =
            activitySchedule.endDate?.split('-') || [];

          return {
            ...activitySchedule,
            formattedEndDate: activitySchedule.endDate
              ? `${endDateDay}/${endDateMonth}/${endDateYear}`
              : '-',
            formattedEndTime: activitySchedule.endTime.slice(0, 5),
            formattedStartDate: `${startDateDay}/${startDateMonth}/${startDateYear}`,
            formattedStartTime: activitySchedule.startTime.slice(0, 5),
          };
        },
      );

      const parsedActivity = {
        ...activityData,
        formattedCreatedAt: maskDate(activityData.createdAt),
        schedules: parsedActivitySchedules,
      };

      setActivity(parsedActivity);
    } else {
      setActivity(undefined);
    }
  }, []);

  return (
    <ActivityContext.Provider
      value={{
        activity,
        handleActivity,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

const useActivity = (): IActivityContextData => useContext(ActivityContext);

export { ActivityProvider, useActivity };
