import { IEntityBase } from './entityBase';

export enum WalletTransactionType {
  INCOME = 'Crédito',
  OUTCOME = 'Débito',
}

export interface IWalletTransactionBase extends IEntityBase {
  amount: number;
  bookingId?: string;
  bookingDateTime?: string;
  title: string;
  type: 'INCOME' | 'OUTCOME';
}

export interface IWalletBase extends IEntityBase {
  balance?: number;
}
