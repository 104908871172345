import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  ButtonGroup,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IFeatureBase } from '../../../../../../../models/features';
import {
  IFeatureListItem,
  listFeaturesService,
} from '../../../../../../../services/Features/ListFeaturesService';

interface IHandleSelectFeatureProps {
  feature: IFeatureBase;
  checked: boolean;
  index: number;
}

interface IFeaturesAssignModalProps {
  groupFeatures?: IFeatureBase[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (features: IFeatureBase[]) => void;
}

export const FeaturesAssignModal = ({
  groupFeatures = [],
  isOpen,
  onClose,
  onSubmit,
}: IFeaturesAssignModalProps): JSX.Element => {
  const [featuresList, setFeaturesList] = useState<IFeatureListItem[]>([]);
  const [searchParam, setSearchParam] = useState<string>();
  const [selectedFeatures, setSelectedFeatures] = useState(groupFeatures);

  const allChecked = useMemo(
    () => selectedFeatures.length === featuresList.length,
    [featuresList.length, selectedFeatures.length],
  );
  const isIndeterminate = useMemo(
    () =>
      !!selectedFeatures.length &&
      selectedFeatures.length !== featuresList.length,
    [featuresList.length, selectedFeatures.length],
  );

  useEffect(() => {
    async function loadFeatures(search?: string): Promise<void> {
      const { items: features } = await listFeaturesService({ search });

      setFeaturesList(features);
    }

    loadFeatures();
  }, []);

  const featuresFound = useMemo(() => {
    if (searchParam) {
      return featuresList.filter((feature) =>
        feature.name.match(new RegExp(searchParam, 'gi')),
      );
    }

    return featuresList;
  }, [featuresList, searchParam]);

  const handleFeatureSearch = useCallback((search: string) => {
    setSearchParam(search);
  }, []);

  const handleToggleSelectAllFeatures = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        setSelectedFeatures([]);
      } else {
        setSelectedFeatures(featuresFound);
      }
    },
    [featuresFound],
  );

  const handleSelectFeature = useCallback(
    ({ feature, checked }: IHandleSelectFeatureProps) => {
      if (!checked) {
        setSelectedFeatures((prevState) =>
          prevState.filter(
            (selectedFeature) => selectedFeature.id !== feature.id,
          ),
        );
      } else {
        setSelectedFeatures((prevState) => [...prevState, feature]);
      }
    },
    [],
  );

  const handleClose = useCallback(() => {
    setSelectedFeatures(groupFeatures);
    setSearchParam(undefined);
    onClose();
  }, [groupFeatures, onClose]);

  const handleSubmit = useCallback(async () => {
    setSearchParam(undefined);
    onSubmit(selectedFeatures);
  }, [onSubmit, selectedFeatures]);

  return (
    <Modal
      size="5xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Gerenciar permissões
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <TableFilters searchLabel="Buscar" onSearch={handleFeatureSearch} />

          <Table mt="4" colorScheme="blue">
            <Thead>
              <Tr>
                <Th px="6" w="8">
                  <Checkbox
                    colorScheme="blue"
                    borderColor="gray.300"
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={handleToggleSelectAllFeatures}
                  />
                </Th>
                <Th>Name</Th>
                <Th>Chave</Th>
              </Tr>
            </Thead>

            <Tbody>
              {featuresFound.map((feature, index) => (
                <Tr key={feature.id}>
                  <Td px="6">
                    <Checkbox
                      colorScheme="blue"
                      isChecked={
                        !!selectedFeatures.find(
                          (feat) => feat.id === feature.id,
                        )
                      }
                      borderColor="gray.300"
                      onChange={(event) =>
                        handleSelectFeature({
                          feature,
                          checked: event.target.checked,
                          index,
                        })
                      }
                    />
                  </Td>

                  <Td>
                    <Text fontWeight="bold">{feature.name}</Text>
                  </Td>

                  <Td>{feature.key}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>

            <Button colorScheme="green" onClick={handleSubmit}>
              Vincular
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
