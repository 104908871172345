import {
  Box,
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useBooking } from '../../../../../../../../../hooks/booking';
import { BookingItemTable } from './components/BookingItemTable';
import { BookingTransactionTable } from './components/BookingTransactionTable';

export const BookingDetailsTabs = (props: BoxProps): JSX.Element => {
  const { booking } = useBooking();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return booking ? (
    <Box {...props}>
      <Tabs
        index={tabIndex}
        onChange={handleTabChanges}
        variant="enclosed-colored"
      >
        <TabList>
          <Tab>Itens</Tab>

          {!!booking.bookingPrice && <Tab>Pagamentos</Tab>}
        </TabList>

        <TabPanels mt="4">
          <TabPanel>
            <BookingItemTable bookingItems={booking.items} />
          </TabPanel>

          {!!booking.bookingPrice && (
            <TabPanel>
              <BookingTransactionTable transactions={booking.transactions} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  ) : (
    <></>
  ); // loading view
};
