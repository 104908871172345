import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  Switch,
  HStack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IActivityScheduleBase } from '../../../../../../../models/activities';
import { UserExperience } from '../../../../../../../models/users';
import { IBookingQueueListItem } from '../../../../../../../services/BookingQueues/ListBookingQueuesService';

interface IBookingQueueTableItemActivitySchedule extends IActivityScheduleBase {
  formattedEndTime: string;
  formattedStartTime: string;
}

export interface IBookingQueueTableItem extends IBookingQueueListItem {
  activitySchedule?: IBookingQueueTableItemActivitySchedule;
  formattedBookedDate: string;
  formattedCreatedAt: string;
  // formattedCheckinAt?: string;
  // formattedCheckoutAt?: string;
}

interface IBookingQueueTableProps extends BoxProps {
  bookingQueues: IBookingQueueTableItem[];
  currentPage: number;
  filterBy: string;
  loadAsyncOptions: (inputValue: string) => void;
  onChangeFilterBy: (value: string) => void;
  onFilter: (value: string) => void;
  onFilterAsync: (value: string) => void;
  onOrder: (order: 'ASC' | 'DESC') => void;
  onPageChange: (page: number) => void;
  onShowQueued: () => void;
  onSort: (sort: 'bookedDate' | 'createdAt') => void;
  order: 'ASC' | 'DESC';
  showQueued: boolean;
  sortBy: string;
  totalPages?: number;
}

export const BookingQueueTable = ({
  bookingQueues,
  currentPage,
  filterBy,
  loadAsyncOptions,
  onChangeFilterBy,
  onFilter,
  onFilterAsync,
  onOrder,
  onPageChange,
  onShowQueued,
  onSort,
  order,
  showQueued,
  sortBy,
  totalPages,
  ...rest
}: IBookingQueueTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Calendar Name',
        value: 'activityId',
      },
      {
        label: 'Resource Name',
        value: 'spotName',
      },
      {
        label: 'Member Name',
        value: 'userName',
      },
      {
        label: 'Member Document',
        value: 'userDocument',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Reserved Date',
        value: 'bookedAt',
      },
      {
        label: 'Registration Date',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <HStack align="flex-end" spacing="4">
        <TableFilters
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          filterInputType={filterBy === 'activityId' ? 'asyncSelect' : 'input'}
          loadAsyncOptions={loadAsyncOptions}
          onChangeFilterBy={onChangeFilterBy}
          onFilter={onFilter}
          onFilterAsync={onFilterAsync}
          onOrder={onOrder}
          onSort={(sort) =>
            !!onSort && onSort(sort as 'bookedDate' | 'createdAt')
          }
          order={order}
          sortBy={sortBy}
          sortByOptions={sortByOptions}
        />

        <HStack>
          <Switch
            name="isActive"
            id="isActive"
            isChecked={showQueued}
            onChange={onShowQueued}
          />
          <Text htmlFor="isActive">Pending bookings</Text>
        </HStack>
      </HStack>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Calendar</Th>

            <Th>Member</Th>

            <Th>Nível de experiência</Th>

            <Th>Resource</Th>

            <Th textAlign="center">Booking date/time</Th>

            <Th textAlign="center">Booked at</Th>

            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {bookingQueues.map((bookingQueue) => (
            <Tr key={bookingQueue.id}>
              <Td fontWeight="bold">{bookingQueue.activity.name}</Td>

              <Td>{bookingQueue.user.name}</Td>

              <Td>{UserExperience[bookingQueue.user.experience]}</Td>

              <Td>{bookingQueue.activity.spot.name}</Td>

              <Td textAlign="center">
                <Box>
                  <Text>{bookingQueue.formattedBookedDate}</Text>
                  {bookingQueue.activitySchedule ? (
                    <Text>{`${bookingQueue.activitySchedule.formattedStartTime} - ${bookingQueue.activitySchedule.formattedEndTime}`}</Text>
                  ) : (
                    <Text color="red.500">Sem horário</Text>
                  )}
                </Box>
              </Td>

              <Td textAlign="center">
                <Text>{bookingQueue.formattedCreatedAt.split('-')[0]}</Text>
                <Text>{bookingQueue.formattedCreatedAt.split('-')[1]}</Text>
              </Td>
              <Td textAlign="right">
                <LinkButton
                  px={0}
                  to={{
                    pathname: '/booking-queues/details',
                    state: {
                      bookingQueueId: bookingQueue.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
