import { IUserCategoryBase } from '../../models/ventures';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateUserCategoriesProps {
  categoryId: string;
  guestBookingPrice?: number;
  maxDayBookings?: number;
  maxGuestDayBookings?: number;
  title: string;
}

export type IUpdatedUserCategory = IUserCategoryBase;

export const updateUserCategoriesService = async ({
  categoryId,
  guestBookingPrice,
  maxDayBookings,
  maxGuestDayBookings,
  title,
}: IUpdateUserCategoriesProps): Promise<IUpdatedUserCategory> => {
  const { data } = await wbsBookingsApi.put<IUpdatedUserCategory>(
    `/user-categories/${categoryId}`,
    {
      guestBookingPrice,
      maxDayBookings,
      maxGuestDayBookings,
      title,
    },
  );

  return data;
};
