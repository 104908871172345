import wbsBookingsApi from '../apis/wbsBookingsApi';
import { IActivityItemBase } from '../../models/activities';

interface ICreateActivityItemProps {
  activityId: string;
  description?: string;
  isActive: boolean;
  name: string;
  // quantityAvailable: number;
}

export type INewActivityItem = IActivityItemBase;

export const createActivityItemsService = async ({
  activityId,
  description,
  isActive,
  name,
}: // quantityAvailable,
ICreateActivityItemProps): Promise<INewActivityItem> => {
  const { data } = await wbsBookingsApi.post<INewActivityItem>(
    `/activities/${activityId}/items`,
    {
      description,
      isActive,
      name,
      // quantityAvailable,
    },
  );

  return data;
};
