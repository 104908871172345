import { Box, Heading, useToast, Icon, HStack } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  maskCpf,
  maskShortDate,
} from '../../../../../utils/formatters/handleMask';
import { IUser, UserTable } from './components/UserTable';
import { listUsersService } from '../../../../../services/Users/ListUsersService';
import { translateError } from '../../../../../utils/errors';
import { useUser } from '../../../../../hooks/user';
import { useAuth } from '../../../../../hooks/auth';

interface ILoadUsersProps {
  cpf?: string;
  email?: string;
  name?: string;
  phone?: string;
  featureGroups?: Array<string>;
  ventureId?: string;
  sort?: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const UserList = (): JSX.Element => {
  const toast = useToast();

  const { handleUser } = useUser();

  const { user: authenticatedUser } = useAuth();

  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<
    'cpf' | 'name' | 'phone' | 'email' | 'createdAt'
  >('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadUsers = useCallback(
    async ({ cpf, email, name, phone, page, sort, order }: ILoadUsersProps) => {
      try {
        const users = await listUsersService({
          cpf: cpf || undefined,
          email: email || undefined,
          name: name || undefined,
          phone: phone || undefined,
          ignoreFeatureGroups: ['MEMBER', 'GUEST', 'DEPENDANT'],
          limit: 20,
          page,
          sort,
          order,
        });

        const parsedUsers = users.items.map((user) => ({
          ...user,
          formattedDate: maskShortDate(user.createdAt),
          formattedDocument: user.cpf ? maskCpf(user.cpf) : undefined,
        }));

        setTotalPages(users.pages);
        setUsersList(parsedUsers);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados dos Tenant users, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadUsers({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [
    currentFilters,
    currentOrdering,
    currentPage,
    handleUser,
    loadUsers,
    sortBy,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <HStack justify="space-between">
          <Heading size="lg" fontWeight="normal">
            Tenant users
          </Heading>

          {authenticatedUser.featureGroup.features.some((feature) =>
            ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(feature.key),
          ) && (
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to="/users/register"
            >
              Register new
            </LinkButton>
          )}
        </HStack>

        <UserTable
          mt="4"
          users={usersList}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          order={currentOrdering}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
