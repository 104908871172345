import { Box, Heading, useToast, Icon, Button, HStack } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { translateError } from '../../../../../utils/errors';
import { listFeaturesService } from '../../../../../services/Features/ListFeaturesService';
import { FeatureTable, IFeature } from './components/FeatureTable';
import { HandleFeatureModal } from './components/HandleFeatureModal';
import { INewFeature } from '../../../../../services/Features/CreateFeaturesService';
import { IUpdatedFeature } from '../../../../../services/Features/UpdateFeaturesService';
import deleteFeaturesService from '../../../../../services/Features/DeleteFeaturesService';
import { useAuth } from '../../../../../hooks/auth';

interface ILoadFeaturesProps {
  search?: string;
  page?: number;
}

export const FeatureList = (): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [featuresList, setFeaturesList] = useState<IFeature[]>([]);
  const [isHandleFeatureModalVisible, setIsHandleFeatureModalVisible] =
    useState(false);
  const [totalPages, setTotalPages] = useState<number>();

  const loadFeatures = useCallback(
    async ({ search, page }: ILoadFeaturesProps) => {
      try {
        const { items: features, pages } = await listFeaturesService({
          search: search || undefined,
          limit: 20,
          page,
        });

        setTotalPages(pages);
        setFeaturesList(features);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados dos grupos de permissões, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadFeatures({
      page: currentPage,
    });
  }, [currentPage, loadFeatures]);

  const debounceLoadFeatures = debounce(loadFeatures, 1000);

  const handleToggleHandleFeatureModal = useCallback(() => {
    setIsHandleFeatureModalVisible((prevState) => !prevState);
  }, []);

  const handleCreateFeature = useCallback(
    (newFeature: INewFeature) => {
      setFeaturesList((prevState) => [newFeature, ...prevState]);

      handleToggleHandleFeatureModal();
    },
    [handleToggleHandleFeatureModal],
  );

  const handleUpdateFeature = useCallback((updatedFeature: IUpdatedFeature) => {
    setFeaturesList((prevState) =>
      prevState.map((feature) =>
        feature.id === updatedFeature.id
          ? { ...feature, ...updatedFeature }
          : feature,
      ),
    );
  }, []);

  const handleDeleteFeature = useCallback(
    async (featureId: string) => {
      try {
        await deleteFeaturesService(featureId);

        setFeaturesList((prevState) =>
          prevState.filter((feature) => feature.id !== featureId),
        );

        toast({
          title: 'Excluído com sucesso',
          description: 'Permissão excluída corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao Delete',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao Delete a permissão, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <HStack justify="space-between">
          <HandleFeatureModal
            isOpen={isHandleFeatureModalVisible}
            onClose={handleToggleHandleFeatureModal}
            onSave={handleCreateFeature}
          />

          <Heading size="lg" fontWeight="normal">
            Permissões
          </Heading>

          {authenticatedUser.featureGroup.features.some((feature) =>
            ['FEATURE_FULL_ACCESS', 'FEATURE_WRITE_ACCESS'].includes(
              feature.key,
            ),
          ) && (
            <Button
              size="sm"
              colorScheme="green"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              onClick={handleToggleHandleFeatureModal}
            >
              Register new
            </Button>
          )}
        </HStack>

        <FeatureTable
          mt="4"
          currentPage={currentPage}
          features={featuresList}
          onDelete={handleDeleteFeature}
          onPageChange={handlePageChange}
          onSave={handleUpdateFeature}
          onSearch={(value) => debounceLoadFeatures({ search: value })}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
