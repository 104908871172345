import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmarkLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { translateError } from '../../../../../../../utils/errors';
import deleteSpotsService from '../../../../../../../services/Spots/DeleteSpotsService';
import { useSpot } from '../../../../../../../hooks/spot';
import { useAuth } from '../../../../../../../hooks/auth';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';

export const SpotDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { push } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { spot } = useSpot();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteSpot = useCallback(async () => {
    if (spot?.id) {
      try {
        await deleteSpotsService({ isVerified, spotId: spot.id });

        toast({
          title: 'Successfully deleted',
          description: 'The location was successfully deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        setIsVerified(false);
        setIsVerifyConfirmationModalVisible(false);

        push('/spots');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          if (err.response?.data.message === 'has-bookings-pending') {
            handleToggleDeleteConfirmationModal();
            handleToggleVerifyConfirmationModal();

            return;
          }

          toast({
            title: 'Deletion failed',
            description:
              translateError({ message: err.response?.data.message }) ||
              'An error occurred while deleting the location, please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [
    spot?.id,
    isVerified,
    toast,
    push,
    handleToggleDeleteConfirmationModal,
    handleToggleVerifyConfirmationModal,
  ]);

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      handleDeleteSpot();
    }
  }, [handleDeleteSpot, isVerified]);

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteSpot}
        title="Confirm deletion"
        message="Do you really want to delete?"
      />

      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="There are pending reservations, confirm the action?"
      />

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Resource details
          </Heading>
        </Flex>

        {spot && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8">
                  <Flex alignItems="flex-start">
                    <Avatar size="2xl" name={spot.name} src={spot.avatarUrl} />

                    <VStack flex="1" alignItems="flex-start" ml="8">
                      <Text fontSize="4xl" fontWeight="bold">
                        {spot.name}
                      </Text>

                      <HStack>
                        <Text>Tenant:</Text>
                        <Text color="gray.500">{spot.venture.name}</Text>
                      </HStack>

                      <HStack>
                        <Text>Active:</Text>
                        {spot.isActive ? (
                          <Icon
                            fontSize="3xl"
                            as={RiCheckboxCircleLine}
                            color="green"
                          />
                        ) : (
                          <Icon
                            fontSize="3xl"
                            as={RiCheckboxBlankCircleLine}
                            color="gray.400"
                          />
                        )}
                      </HStack>

                      <HStack>
                        <Text>Registration date:</Text>
                        <Text color="gray.500">{spot.formattedCreatedAt}</Text>
                      </HStack>
                    </VStack>
                  </Flex>

                  <ButtonGroup mt="8">
                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['SPOT_FULL_ACCESS', 'SPOT_WRITE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <LinkButton
                        colorScheme="yellow"
                        color="white"
                        icon={<Icon as={RiEditLine} fontSize="16" />}
                        to={{
                          pathname: '/spots/update',
                          state: {
                            spotId: spot.id,
                          },
                        }}
                      >
                        Edit
                      </LinkButton>
                    )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['SPOT_FULL_ACCESS', 'SPOT_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleToggleDeleteConfirmationModal}
                        leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      >
                        Delete
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              {spot.description && (
                <GridItem colSpan={[12, 12, 12, 12, 4]}>
                  <Flex height="100%">
                    <Divider
                      hidden={dividerOrientation === 'horizontal'}
                      px="2"
                      height="80%"
                      borderColor="gray.300"
                      orientation="vertical"
                    />

                    <Flex flexDirection="column" w="100%">
                      <Divider
                        hidden={dividerOrientation === 'vertical'}
                        borderColor="gray.300"
                        mt="4"
                        mb="8"
                      />
                      <Flex
                        flexDirection="column"
                        px={[8, 8, 8, 8, 0]}
                        w="100%"
                      >
                        <HStack mt={[0, 0, 0, 0, 2]}>
                          <Icon as={RiBookmarkLine} fontSize="20" />
                          <Text>Description</Text>
                        </HStack>

                        <Text
                          fontSize="sm"
                          color="gray.500"
                          mt="4"
                          px={[4, 4, 4, 4, 0]}
                        >
                          {spot.description}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </GridItem>
              )}
            </Grid>
          </Flex>
        )}
      </Flex>
    </>
  );
};
