import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  SimpleGrid,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { createActivitiesService } from '../../../../../services/Activities/CreateActivitiesService';
import { Switch } from '../../../../../components/Form/Switch';
import { translateError } from '../../../../../utils/errors';
import { updateActivityAvatarsService } from '../../../../../services/Activities/UpdateActivityAvatarsService';
import { listSpotsService } from '../../../../../services/Spots/ListSpotsService';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { unmaskNumber } from '../../../../../utils/formatters/handleMask';

interface ILocationState {
  spotId?: string;
}

type NewActivityFormData = {
  bookingPrice?: number;
  description?: string;
  isActive: boolean;
  name: string;
  spotId: string;
};

const registerActivityFormSchema = Yup.object().shape({
  bookingPrice: Yup.number()
    .integer('Valores inteiros')
    .positive('Valores maiores que zero')
    .nullable()
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  isActive: Yup.boolean().required('Required'),
  name: Yup.string().required('Required'),
  spotId: Yup.string().uuid().required('Required'),
});

export const ActivityRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const { state } = useLocation<ILocationState | undefined>();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [spotsSelectOptions, setSpotsSelectOptions] = useState<SelectOption[]>(
    [],
  );
  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(registerActivityFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    async function loadSpots(): Promise<void> {
      const { items: spots } = await listSpotsService({ isActive: true });

      setSpotsSelectOptions(
        spots.map((spot) => ({
          label: spot.name,
          value: spot.id,
        })),
      );
    }

    loadSpots();
  }, []);

  useEffect(() => {
    if (!!state?.spotId && spotsSelectOptions.length) {
      setValue('spotId', state?.spotId);
    }
  }, [setValue, spotsSelectOptions.length, state?.spotId]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewActivity: SubmitHandler<NewActivityFormData> = useCallback(
    async ({ bookingPrice, description, isActive, name, spotId }) => {
      try {
        const activity = await createActivitiesService({
          bookingPrice,
          description,
          isActive,
          name,
          spotId,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateActivityAvatarsService({
            avatarData: formData,
            activityId: activity.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'A atividade foi cadastrada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/activities/details', {
          activityId: activity.id,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar a atividade, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewActivity)}
      >
        <Heading size="lg" fontWeight="normal">
          Register calendar
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Title"
            error={errors.name}
            {...register('name')}
          />

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <ReactSelect
              label="Resource"
              name="spotId"
              options={spotsSelectOptions}
              control={control}
              error={errors.spotId}
            />

            <MaskedInput
              label="Booking price"
              mask="money"
              error={errors.guestBookingPrice}
              {...register('guestBookingPrice')}
            />
          </SimpleGrid>

          <MaskedInput
            label="Description"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.description}
            {...register('description')}
          />

          <Box mx="auto">
            <Switch
              label="Active"
              error={errors.isActive}
              defaultChecked
              {...register('isActive')}
            />
          </Box>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Save
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
