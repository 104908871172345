import { Box, BoxProps, useToast } from '@chakra-ui/react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useState, useCallback, useEffect } from 'react';
import {
  BookingTable,
  IBookingTableItem,
} from '../../../../../components/BookingTable';
import { useUser } from '../../../../../hooks/user';
import { listBookingsService } from '../../../../../services/Bookings/ListBookingsService';
import { processBookingPaymentsService } from '../../../../../services/Bookings/ProcessBookingPaymentsService';
import { updateBookingsCheckinService } from '../../../../../services/Bookings/UpdateBookingsCheckinService';
import { translateError } from '../../../../../utils/errors';
import { maskDateTime } from '../../../../../utils/formatters/handleMask';

interface IHandleToggleCheckinProps {
  bookingId: string;
  checkinAt?: Date;
  checkoutAt?: Date;
  identifyBraceletNumber?: string;
}

interface ILoadBookingsProps {
  hostId?: string;
  spotName?: string;
  showPendingBookingsOnly?: boolean;
  userDocument?: string;
  userName?: string;
  userId?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

type IMemberBookingTableProps = BoxProps;

export const MemberBookingTable = (
  props: IMemberBookingTableProps,
): JSX.Element => {
  const toast = useToast();

  const { user: member } = useUser();

  const [bookingsList, setBookingsList] = useState<IBookingTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('bookedDate');
  const [showPending, setShowPending] = useState(true);
  const [sortBy, setSortBy] = useState<'bookedDate' | 'createdAt'>(
    'bookedDate',
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadBookings = useCallback(
    async ({
      hostId,
      spotName,
      userDocument,
      userName,
      showPendingBookingsOnly,
      userId,
      order,
      page,
      sort = 'bookedDate',
    }: ILoadBookingsProps) => {
      try {
        const bookings = await listBookingsService({
          hostId,
          spotName,
          userDocument: userDocument?.replace(/\D/g, ''),
          userName,
          userId,
          showPendingBookingsOnly,
          limit: 10,
          page,
          sort,
          order,
        });

        const parsedBookings = bookings.items.map((booking) => {
          const [bookedYear, bookedMonth, bookedDay] =
            booking.bookedDate.split('-');

          return {
            ...booking,
            formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
            formattedCreatedAt: maskDateTime(booking.createdAt),
            formattedCheckinAt: booking.checkinAt
              ? maskDateTime(booking.checkinAt)
              : undefined,
            formattedCheckoutAt: booking.checkoutAt
              ? maskDateTime(booking.checkoutAt)
              : undefined,
            formattedEndTime: booking.endTime
              ? booking.endTime.slice(11, 16)
              : booking.activitySchedule.endTime.slice(0, 5),
            formattedStartTime: booking.startTime
              ? booking.startTime.slice(11, 16)
              : booking.activitySchedule.startTime.slice(0, 5),
          };
        });

        setTotalPages(bookings.pages);
        setBookingsList(parsedBookings);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das reservas, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    if (member?.id) {
      loadBookings({
        ...currentFilters,
        showPendingBookingsOnly: showPending,
        userId: member.id,
        order: currentOrdering,
        page: currentPage,
        sort: 'bookedDate',
      });
    }
  }, [
    currentPage,
    loadBookings,
    currentOrdering,
    currentFilters,
    showPending,
    member?.id,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );
  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'bookedDate' | 'createdAt') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handlePayment = useCallback(async (bookingId: string) => {
    const paydBooking = await processBookingPaymentsService(bookingId);

    setBookingsList((prevState) =>
      prevState.map((booking) =>
        booking.id === paydBooking.id
          ? { ...booking, ...paydBooking }
          : booking,
      ),
    );
  }, []);

  const handleToggleBookingsTypeFilter = useCallback(
    (value: 'memberBookings' | 'guestsBookings') => {
      if (member?.id) {
        loadBookings({
          ...currentFilters,
          showPendingBookingsOnly: showPending,
          hostId: value === 'guestsBookings' ? member.id : undefined,
          userId: value === 'memberBookings' ? member.id : undefined,
          order: currentOrdering,
          page: currentPage,
          sort: 'bookedDate',
        });
      }
    },
    [
      currentFilters,
      currentOrdering,
      currentPage,
      loadBookings,
      member?.id,
      showPending,
    ],
  );

  const handleToggleCheckin = useCallback(
    async ({
      bookingId,
      checkinAt,
      checkoutAt,
      identifyBraceletNumber,
    }: IHandleToggleCheckinProps) => {
      const updatedBooking = await updateBookingsCheckinService({
        bookingId,
        checkinAt,
        checkoutAt,
        identifyBraceletNumber,
      });

      setBookingsList((prevState) =>
        prevState.map((booking) =>
          booking.id === bookingId
            ? {
                ...booking,
                ...updatedBooking,
                formattedCheckinAt: updatedBooking.checkinAt
                  ? maskDateTime(updatedBooking.checkinAt)
                  : undefined,
                formattedCheckoutAt: updatedBooking.checkoutAt
                  ? maskDateTime(updatedBooking.checkoutAt)
                  : undefined,
              }
            : booking,
        ),
      );
    },
    [],
  );

  return (
    <Box {...props}>
      <BookingTable
        mt="4"
        bookings={bookingsList}
        currentPage={currentPage}
        filterBy={filterBy}
        filterByOptions={[
          {
            label: 'Member Name',
            value: 'userName',
          },
          {
            label: 'Document',
            value: 'userDocument',
          },
          {
            label: 'Booking Date',
            value: 'bookedDate',
          },
        ]}
        onChangeFilterBy={handleFilterBy}
        onFilter={debounceHandleFilter}
        onOrder={handleOrderList}
        onPageChange={handlePageChange}
        onPay={handlePayment}
        onSort={handleSortList}
        onToggleBookingsTypeFilter={handleToggleBookingsTypeFilter}
        onToggleCheckin={handleToggleCheckin}
        onShowPending={() => setShowPending((prevState) => !prevState)}
        order={currentOrdering}
        showMemberColumns={false}
        showPending={showPending}
        sortBy={sortBy}
        totalPages={totalPages}
      />
    </Box>
  );
};
