import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
import { ICalendarEvent } from '../..';
import { IActivityEventsSchedule } from '../../../../../../../../../../../../../models/activities';
import { UserExperience } from '../../../../../../../../../../../../../models/users';

type Props = {
  event: ICalendarEvent | null;
  isOpen: boolean;
  onClose: () => void;
};

export default function BookingsModal({
  event,
  isOpen,
  onClose,
}: Props): JSX.Element {
  const formatTime = (time = ''): string => time?.slice(0, 5) || time;

  const experienceGroupedActivitySchedules = event?.schedules.reduce(
    (experienceGroups, activityScheduleItem) => ({
      ...experienceGroups,
      [UserExperience[activityScheduleItem.experience]]: [
        ...(experienceGroups[UserExperience[activityScheduleItem.experience]] ||
          []),
        activityScheduleItem,
      ],
    }),
    {} as Record<string, IActivityEventsSchedule[]>,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          {`Reservas de ${formatTime(
            event?.schedules[0].startTime,
          )} à ${formatTime(event?.schedules[0].endTime)}`}
        </ModalHeader>

        <ModalCloseButton />
        <SimpleGrid columns={2} spacing="2" w="100%">
          {!!experienceGroupedActivitySchedules &&
            Object.keys(experienceGroupedActivitySchedules).map((key) => (
              <ModalBody key={key}>
                <p>
                  <strong>{key}</strong>
                </p>
                {experienceGroupedActivitySchedules[key].map(
                  (e: IActivityEventsSchedule) => (
                    <ModalBody key={e.activityScheduleId}>
                      <p>
                        <strong>
                          {e.modalityTitle && `${e.modalityTitle}: `}
                        </strong>
                      </p>
                      <p>
                        <strong>{e.booked}</strong>
                        <span> reserva(s) | </span>
                        <strong>{e.vacancies - e.booked}</strong>
                        <span> Slot(s) restante(s).</span>
                      </p>
                    </ModalBody>
                  ),
                )}
              </ModalBody>
            ))}
        </SimpleGrid>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
