import {
  Box,
  Button,
  CircularProgress,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import React from 'react';
import { ToolbarProps } from 'react-big-calendar';

type Props = ToolbarProps & {
  loading?: boolean;
};

export const ToolbarComponent: React.FC<Props> = ({
  label,
  onNavigate,
  loading,
}: Props) => (
  <Box
    display="flex"
    flexDir="row"
    justifyContent="space-between"
    alignItems="center"
    p={4}
  >
    <Heading
      as="h4"
      size="md"
      display="flex"
      alignItems="center"
      flex={1}
      textTransform="capitalize"
    >
      <CalendarIcon mr={2} />
      {label}
    </Heading>
    {loading && (
      <CircularProgress isIndeterminate color="teal" mr={2} size="35px" />
    )}
    <IconButton
      aria-label="Previous"
      icon={<ChevronLeftIcon />}
      onClick={() => onNavigate('PREV')}
    />

    <Button size="md" ml={4} mr={4} onClick={() => onNavigate('TODAY')}>
      Today
    </Button>

    <IconButton
      aria-label="Next"
      icon={<ChevronRightIcon />}
      onClick={() => onNavigate('NEXT')}
    />
  </Box>
);
