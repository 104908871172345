import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import { IVentureBase } from '../../models/ventures';
import { IUserBase } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface ICreateSessionProps {
  email: string;
  password: string;
}

export interface ISessionDetailedFeatureGroup extends IFeatureGroupBase {
  features: IFeatureBase[];
}

export interface ISessionUser extends IUserBase {
  venture?: IVentureBase;
  featureGroup?: ISessionDetailedFeatureGroup;
}

export interface INewSession {
  user: ISessionUser;
  token: string;
  refreshToken: string;
}

export const authenticateSessionsService = async ({
  email,
  password,
}: ICreateSessionProps): Promise<INewSession> => {
  const { data } = await wbsBookingsApi.post<INewSession>('/sessions', {
    email,
    password,
  });

  return data;
};
