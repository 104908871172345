import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

import axios from 'axios';

import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  IDetailedSpot,
  showSpotsService,
} from '../../../../../services/Spots/ShowSpotsService';
import { updateSpotsService } from '../../../../../services/Spots/UpdateSpotsService';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { translateError } from '../../../../../utils/errors';
import deleteSpotAvatarsService from '../../../../../services/Spots/DeleteSpotAvatarsService';
import { updateSpotAvatarsService } from '../../../../../services/Spots/UpdateSpotAvatarsService';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { useAuth } from '../../../../../hooks/auth';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';

type UpdateSpotFormData = {
  description?: string;
  name: string;
  ventureId: string;
};

interface ILocationState {
  spotId: string;
}

const spotUpdateFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Required'),
  ventureId: Yup.string().uuid().required('Required'),
});

export const SpotUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();

  const { user } = useAuth();

  const { state } = useLocation<ILocationState>();

  const [updatingSpot, setUpdatingSpot] = useState<IDetailedSpot>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [isActive, setIsActive] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(spotUpdateFormSchema),
  });

  const { errors } = formState;
  const { spotId } = state;

  useEffect(() => {
    async function loadSpot(): Promise<void> {
      try {
        const spotData = await showSpotsService(spotId);
        setAvatarUrl(spotData.avatarUrl || undefined);

        setIsActive(spotData.isActive);

        setUpdatingSpot(spotData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados da local, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadSpot();
  }, [spotId, reset, toast]);

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();

      setVenturesSelectOptions(
        ventures.map((venture) => ({
          label: venture.name,
          value: venture.id,
        })),
      );
    }

    loadVentures();
  }, []);

  useEffect(() => {
    if (updatingSpot) {
      reset({
        description: updatingSpot.description,
        name: updatingSpot.name,
        ventureId: updatingSpot.ventureId,
      });
    }
  }, [reset, updatingSpot]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteSpotAvatarsService(spotId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();
  }, [spotId, handleToggleDeleteConfirmationModal]);

  const handleUpdateSpot: SubmitHandler<UpdateSpotFormData> = useCallback(
    async ({ description, name, ventureId }) => {
      try {
        await updateSpotsService({
          spotId,
          description,
          isActive,
          name,
          ventureId,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateSpotAvatarsService({
            spotId,
            avatarData: formData,
          });
        }

        toast({
          title: 'Resouce sucefully updated',
          description: 'Resouce succesfuly updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Issue updating',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Error updating resource, try it again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [spotId, isActive, avatar, toast, goBack],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente Delete?"
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateSpot)}
      >
        <Heading size="lg" fontWeight="normal">
          Edit Resource
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Título"
            error={errors.name}
            {...register('name')}
          />

          {!user.ventureId && (
            <ReactSelect
              label="Tenant"
              name="ventureId"
              options={venturesSelectOptions}
              control={control}
              error={errors.ventureId}
            />
          )}

          <MaskedInput
            label="Description"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.description}
            {...register('description')}
          />

          <Box>
            <FormLabel htmlFor="isActive">Active</FormLabel>
            <Switch
              name="isActive"
              id="isActive"
              isChecked={isActive}
              onChange={handleToggleIsActive}
            />
          </Box>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
