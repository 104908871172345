import { IActivityBase } from '../../models/activities';
import { ISpotBase } from '../../models/spots';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateSpotProps {
  spotId: string;
  description?: string;
  isActive: boolean;
  name: string;
  ventureId: string;
}

export interface IUpdatedSpot extends ISpotBase {
  activities: IActivityBase[];
}

export const updateSpotsService = async ({
  spotId,
  description,
  isActive,
  name,
  ventureId,
}: IUpdateSpotProps): Promise<IUpdatedSpot> => {
  const { data } = await wbsBookingsApi.put<IUpdatedSpot>(`/spots/${spotId}`, {
    description,
    isActive,
    name,
    ventureId,
  });

  return data;
};
