import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Link as ChakraLink,
  Text,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmarkLine,
  RiDeleteBinLine,
  RiEditLine,
  RiMailSendLine,
  RiWhatsappLine,
  RiListCheck3,
  RiLockPasswordLine,
} from 'react-icons/ri';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { translateError } from '../../../../../../../utils/errors';
import { useUser } from '../../../../../../../hooks/user';
import { UserExperience } from '../../../../../../../models/users';
import createProfileConfirmationsService from '../../../../../../../services/Users/CreateProfileConfirmationsService';
import deleteUsersService from '../../../../../../../services/Users/DeleteUsersService';
import { useAuth } from '../../../../../../../hooks/auth';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import HostInfo from '../../../../components/HostInfo';
import resetUsersOnboardingService from '../../../../../../../services/Users/ResetUsersOnboardingService';
import setUsersDefaultPasswordService from '../../../../../../../services/Users/SetUsersDefaultPasswordService';

export const MemberDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { push } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { user: member, handleUser: handleMember } = useUser();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isSendProfileConfirmationModalVisible,
    setIsSendProfileConfirmationModalVisible,
  ] = useState(false);
  const [isResetOnboardingModalVisible, setIsResetOnboardingModalVisible] =
    useState(false);
  const [isDefaultPasswordModalVisible, setIsDefaultPasswordModalVisible] =
    useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const handleToggleProfileConfirmationModal = useCallback(() => {
    setIsSendProfileConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleResetOnboardingModal = useCallback(() => {
    setIsResetOnboardingModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDefaultPasswordModal = useCallback(() => {
    setIsDefaultPasswordModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleProfileConfirmation = useCallback(async () => {
    try {
      if (member) {
        await createProfileConfirmationsService(member.id);
      }

      toast({
        title: 'Notificado com sucesso',
        description: 'O Member foi notificado corretamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      handleToggleProfileConfirmationModal();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao notificar',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao enviar a notificação, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [member, handleToggleProfileConfirmationModal, toast]);

  const handleDefaultPassword = useCallback(async () => {
    if (!member) {
      return;
    }
    try {
      await setUsersDefaultPasswordService(member.id);

      handleMember({ ...member, onboardingPending: false });

      handleToggleDefaultPasswordModal();

      toast({
        title: 'Redefinida com sucesso',
        description: 'A Password do Member foi redefinida corretamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao redifinir Password',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao redifinir a Password do Member, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [handleMember, handleToggleDefaultPasswordModal, member, toast]);

  const handleResetOnboarding = useCallback(async () => {
    if (!member) {
      return;
    }
    try {
      await resetUsersOnboardingService(member.id);

      handleMember({ ...member, onboardingPending: true });

      handleToggleResetOnboardingModal();

      toast({
        title: 'Reiniciado com sucesso',
        description: 'O onboarding do Member foi reiniciado corretamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao reiniciar onboarding',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao reiniciar o onboarding do Member, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [handleMember, handleToggleResetOnboardingModal, member, toast]);

  const handleDeleteMember = useCallback(async () => {
    if (member?.id) {
      try {
        await deleteUsersService(member.id);

        toast({
          title: 'Excluído com sucesso',
          description: 'O Member foi excluído corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/members');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao Delete',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao Delete o Member, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [member?.id, push, toast]);

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteMember}
        title="Confirmar exclusão"
        message="Deseja realmente Delete?"
      />

      <ConfirmationModal
        isOpen={isSendProfileConfirmationModalVisible}
        onClose={handleToggleProfileConfirmationModal}
        onConfirm={handleProfileConfirmation}
        title="Enviar notificação"
        message="Deseja reenviar a notificação?"
      />

      <ConfirmationModal
        isOpen={isDefaultPasswordModalVisible}
        onClose={handleToggleDefaultPasswordModal}
        onConfirm={handleDefaultPassword}
        title="Definir Password padrão"
        message="ATENÇÃO! Deseja redefinir a Password desse Member para a Password padrão 123123?"
      />

      <ConfirmationModal
        isOpen={isResetOnboardingModalVisible}
        onClose={handleToggleResetOnboardingModal}
        onConfirm={handleResetOnboarding}
        title="Reiniciar onboarding"
        message="Deseja reiniciar o onboarding desse Member?"
      />

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do Member
          </Heading>
        </Flex>

        {member && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8">
                  <Flex alignItems="flex-start">
                    <Avatar
                      size="2xl"
                      name={member.name}
                      src={member.avatarUrl}
                    />

                    <VStack flex="1" alignItems="flex-start" ml="8">
                      <Text fontSize="4xl" fontWeight="bold">
                        {member.name}
                      </Text>

                      <HStack>
                        <Text>Nível de experiência:</Text>
                        <Text color="gray.500">
                          {UserExperience[member.experience]}
                        </Text>
                      </HStack>

                      <HStack>
                        <Text>Phone:</Text>
                        {member.phone && (
                          <ChakraLink
                            href={`https://wa.me/${member.phone?.replace(
                              /\D/g,
                              '',
                            )}`}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={RiWhatsappLine} mx="2" fontSize="24" />
                            <Text as="span" color="blue.500">
                              {member.phone}
                            </Text>
                          </ChakraLink>
                        )}
                      </HStack>

                      <HStack>
                        <Text>E-mail:</Text>
                        <Text color="gray.500">{member.email}</Text>
                      </HStack>

                      <HStack>
                        <Text>Document:</Text>
                        <Text color="gray.500">{member.cpf}</Text>
                      </HStack>

                      <HStack>
                        <Text>Tenant:</Text>
                        <Text color="gray.500">{member.venture?.name}</Text>
                      </HStack>

                      <HStack>
                        <Text>Categoria:</Text>
                        <Text color="gray.500">
                          {member.category
                            ? member.category.title
                            : member.host?.category.title}
                        </Text>
                      </HStack>

                      <HStack>
                        <Text>Registration date:</Text>
                        <Text color="gray.500">
                          {member.formattedCreatedAt}
                        </Text>
                      </HStack>

                      {!!member.formattedHealthCheckDate && (
                        <HStack>
                          <Text>Exame médico:</Text>
                          <Text color="gray.500">
                            {member.formattedHealthCheckDate}
                          </Text>
                        </HStack>
                      )}
                    </VStack>
                  </Flex>

                  <ButtonGroup mt="8">
                    {member?.confirmPending &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'USER_FULL_ACCESS',
                          'USER_PROFILE_CONFIRMATION_WRITE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="blue"
                          onClick={handleToggleProfileConfirmationModal}
                          leftIcon={<Icon as={RiMailSendLine} fontSize="20" />}
                        >
                          Reenviar confirmação
                        </Button>
                      )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      [
                        'USER_FULL_ACCESS',
                        'USER_PASSWORD_WRITE_ACCESS',
                      ].includes(feature.key),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="blue"
                        onClick={handleToggleDefaultPasswordModal}
                        leftIcon={
                          <Icon as={RiLockPasswordLine} fontSize="20" />
                        }
                      >
                        Redefinir Password
                      </Button>
                    )}

                    {!member?.onboardingPending &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(
                          feature.key,
                        ),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="blue"
                          onClick={handleToggleResetOnboardingModal}
                          leftIcon={<Icon as={RiListCheck3} fontSize="20" />}
                        >
                          Resetar onboarding
                        </Button>
                      )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <LinkButton
                        colorScheme="yellow"
                        color="white"
                        icon={<Icon as={RiEditLine} fontSize="16" />}
                        to={{
                          pathname: '/members/update',
                          state: {
                            memberId: member.id,
                          },
                        }}
                      >
                        Edit
                      </LinkButton>
                    )}
                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['USER_FULL_ACCESS', 'USER_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleToggleDeleteConfirmationModal}
                        leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      >
                        Delete
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              {member.bio && (
                <GridItem colSpan={[12, 12, 12, 12, 4]}>
                  <Flex height="100%">
                    <Divider
                      hidden={dividerOrientation === 'horizontal'}
                      px="2"
                      height="80%"
                      borderColor="gray.300"
                      orientation="vertical"
                    />

                    <Flex flexDirection="column" w="100%">
                      <Divider
                        hidden={dividerOrientation === 'vertical'}
                        borderColor="gray.300"
                        mt="4"
                        mb="8"
                      />
                      <Flex
                        flexDirection="column"
                        px={[8, 8, 8, 8, 0]}
                        w="100%"
                      >
                        <HStack mt={[0, 0, 0, 0, 2]}>
                          <Icon as={RiBookmarkLine} fontSize="20" />
                          <Text>Biography</Text>
                        </HStack>

                        <Text
                          fontSize="sm"
                          color="gray.500"
                          mt="4"
                          px={[4, 4, 4, 4, 0]}
                        >
                          {member.bio}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </GridItem>
              )}
            </Grid>

            {member.host && (
              <>
                <Divider borderColor="gray.300" my="8" />

                <HostInfo host={member.host} />
              </>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};
