import { IActivityEventsSchedule } from '../../models/activities';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IListActivityScheduleProps {
  activityId: string;
  activityScheduleId?: string;
  addDay?: number;
  selectedMonth?: number;
  startDate?: string;
  userId?: string;
}

export const listActivitiesSchedulesService = async ({
  activityId,
  activityScheduleId,
  addDay,
  selectedMonth,
  startDate,
  userId,
}: IListActivityScheduleProps): Promise<IActivityEventsSchedule[]> => {
  const { data } = await wbsBookingsApi.get(
    `/activities/${activityId}/schedules`,
    {
      params: {
        activityScheduleId,
        addDay,
        selectedMonth,
        startDate: startDate || undefined,
        userId,
      },
    },
  );

  return data;
};
