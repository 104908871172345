import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  ButtonGroup,
} from '@chakra-ui/react';
import { RiAddLine, RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../../../../../components/LinkButton';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { useSpot } from '../../../../../../../../../../../hooks/spot';

export const SpotActivityTable = (props: BoxProps): JSX.Element => {
  const { user: authenticatedUser } = useAuth();

  const { spot } = useSpot();

  return spot ? (
    <Box {...props}>
      {authenticatedUser.featureGroup.features.some((feature) =>
        ['SPOT_FULL_ACCESS', 'SPOT_WRITE_ACCESS'].includes(feature.key),
      ) && (
        <ButtonGroup width="100%" justifyContent="flex-end">
          <LinkButton
            colorScheme="green"
            icon={<Icon as={RiAddLine} fontSize="16" />}
            to={{
              pathname: '/activities/register',
              state: {
                spotId: spot.id,
              },
            }}
          >
            Register new
          </LinkButton>
        </ButtonGroup>
      )}

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Name</Th>

            <Th textAlign="center" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {spot.activities.map((activity) => (
            <Tr key={activity.id}>
              <Td>
                <Text fontWeight="bold">{activity.name}</Text>
              </Td>

              <Td textAlign="center">
                <LinkButton
                  px={0}
                  to={{
                    pathname: '/activities/details',
                    state: {
                      activityId: activity.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ) : (
    <></> // loading view
  );
};
