import {
  Flex,
  Button,
  Icon,
  Link as ChakraLink,
  Text,
  ButtonGroup,
} from '@chakra-ui/react';
import { RiAppStoreLine, RiGooglePlayLine } from 'react-icons/ri';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface ILocationState {
  appstoreId?: string;
  loginPermissions: string[];
  playstoreId?: string;
}

export const SignupConfirm = (): JSX.Element => {
  const { state } = useLocation<ILocationState | undefined>();

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        maxWidth={360}
        minH={400}
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
      >
        <Text fontSize="1.75rem" fontWeight="bold" letterSpacing="tight">
          WE
          <Text as="span" ml="1" color="blue.500">
            BOOK
          </Text>
          SPACE
        </Text>

        <Flex
          mt="8"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
        >
          <Text fontSize="lg">Seu cadastro foi concluído!</Text>

          {state?.loginPermissions.some((loginPermission) =>
            ['APP_CONCIERGE_ACCESS', 'APP_MEMBERS_ACCESS'].includes(
              loginPermission,
            ),
          ) && (
            <>
              <Text>
                Para desfrutar dos nossos serviços, faça o download do
                aplicActive na loja de aplicActives do seu smartphone e efetue o
                login.
              </Text>

              <ButtonGroup justifyContent="space-between">
                <ChakraLink
                  href={`https://play.google.com/store/apps/details?id=${state.playstoreId}`}
                  isExternal
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  <Button
                    size="md"
                    fontSize="md"
                    fontWeight="normal"
                    colorScheme="gray"
                    leftIcon={<Icon as={RiGooglePlayLine} fontSize="24" />}
                  >
                    Playstore
                  </Button>
                </ChakraLink>

                <ChakraLink
                  href={`https://apps.apple.com/br/app/${state.appstoreId}`}
                  isExternal
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  <Button
                    size="md"
                    fontSize="md"
                    fontWeight="normal"
                    colorScheme="gray"
                    leftIcon={<Icon as={RiAppStoreLine} fontSize="24" />}
                  >
                    Applestore
                  </Button>
                </ChakraLink>
              </ButtonGroup>
            </>
          )}

          {state?.loginPermissions.some(
            (loginPermission) => loginPermission === 'WEB_ACCESS',
          ) && (
            <ChakraLink as={Link} to="/" mt="2" mx="auto">
              Back to login
            </ChakraLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
