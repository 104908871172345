import wbsBookingsApi from '../apis/wbsBookingsApi';
import { ISpotModalityBase } from '../../models/spots';

interface ICreateSpotModalityProps {
  spotId: string;
  description?: string;
  isActive: boolean;
  title: string;
}

export type INewSpotModality = ISpotModalityBase;

export const createSpotModalitiesService = async ({
  spotId,
  description,
  isActive,
  title,
}: ICreateSpotModalityProps): Promise<INewSpotModality> => {
  const { data } = await wbsBookingsApi.post<INewSpotModality>(
    `/spots/${spotId}/modalities`,
    {
      description,
      isActive,
      title,
    },
  );

  return data;
};
