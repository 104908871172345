import { Box, Heading, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskDateTime } from '../../../../../utils/formatters/handleMask';
import { listBookingsService } from '../../../../../services/Bookings/ListBookingsService';
import { translateError } from '../../../../../utils/errors';
import { updateBookingsCheckinService } from '../../../../../services/Bookings/UpdateBookingsCheckinService';
import {
  IBookingTableItem,
  BookingTable,
} from '../../../../../components/BookingTable';
import { processBookingPaymentsService } from '../../../../../services/Bookings/ProcessBookingPaymentsService';

interface IHandleToggleCheckinProps {
  bookingId: string;
  checkinAt?: Date;
  checkoutAt?: Date;
  identifyBraceletNumber?: string;
}

interface ILoadBookingsProps {
  activityName?: string;
  bookedDate?: string;
  spotName?: string;
  showPendingBookingsOnly?: boolean;
  userDocument?: string;
  userName?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const BookingList = (): JSX.Element => {
  const toast = useToast();

  const [bookingsList, setBookingsList] = useState<IBookingTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('activityName');
  const [showPending, setShowPending] = useState(true);
  const [sortBy, setSortBy] = useState<'bookedDate' | 'createdAt'>(
    'bookedDate',
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadBookings = useCallback(
    async ({
      activityName,
      bookedDate,
      spotName,
      userDocument,
      userName,
      showPendingBookingsOnly,
      order,
      page,
      sort = 'bookedDate',
    }: ILoadBookingsProps) => {
      try {
        const bookings = await listBookingsService({
          activityName,
          bookedDate,
          spotName,
          userDocument: userDocument?.replace(/\D/g, ''),
          userName,
          showPendingBookingsOnly,
          limit: 20,
          page,
          sort,
          order,
        });

        const parsedBookings = bookings.items.map((booking) => {
          const [bookedYear, bookedMonth, bookedDay] =
            booking.bookedDate.split('-');

          return {
            ...booking,
            formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
            formattedCreatedAt: maskDateTime(booking.createdAt),
            formattedCheckinAt: booking.checkinAt
              ? maskDateTime(booking.checkinAt)
              : undefined,
            formattedCheckoutAt: booking.checkoutAt
              ? maskDateTime(booking.checkoutAt)
              : undefined,
            formattedEndTime: booking.endTime
              ? booking.endTime.slice(11, 16)
              : booking.activitySchedule.endTime.slice(0, 5),
            formattedStartTime: booking.startTime
              ? booking.startTime.slice(11, 16)
              : booking.activitySchedule.startTime.slice(0, 5),
          };
        });

        setTotalPages(bookings.pages);
        setBookingsList(parsedBookings);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das reservas, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadBookings({
      ...currentFilters,
      showPendingBookingsOnly: showPending,
      order: currentOrdering,
      page: currentPage,
      sort: 'bookedDate',
    });
  }, [currentPage, loadBookings, currentOrdering, currentFilters, showPending]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );
  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'bookedDate' | 'createdAt') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handlePayment = useCallback(
    async (bookingId: string) => {
      try {
        const paydBooking = await processBookingPaymentsService(bookingId);

        setBookingsList((prevState) =>
          prevState.map((booking) =>
            booking.id === paydBooking.id
              ? { ...booking, ...paydBooking }
              : booking,
          ),
        );

        toast({
          title: 'Transação concluída com sucesso',
          description: 'A transação foi efetuada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha na transação',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao efetuar a transação, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleToggleCheckin = useCallback(
    async ({
      bookingId,
      checkinAt,
      checkoutAt,
      identifyBraceletNumber,
    }: IHandleToggleCheckinProps) => {
      const updatedBooking = await updateBookingsCheckinService({
        bookingId,
        checkinAt,
        checkoutAt,
        identifyBraceletNumber,
      });

      setBookingsList((prevState) =>
        prevState.map((booking) =>
          booking.id === bookingId
            ? {
                ...booking,
                ...updatedBooking,
                formattedCheckinAt: updatedBooking.checkinAt
                  ? maskDateTime(updatedBooking.checkinAt)
                  : undefined,
                formattedCheckoutAt: updatedBooking.checkoutAt
                  ? maskDateTime(updatedBooking.checkoutAt)
                  : undefined,
              }
            : booking,
        ),
      );
    },
    [],
  );

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Bookings
        </Heading>

        <BookingTable
          mt="4"
          bookings={bookingsList}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onPay={handlePayment}
          onSort={handleSortList}
          onToggleCheckin={handleToggleCheckin}
          onShowPending={() => setShowPending((prevState) => !prevState)}
          order={currentOrdering}
          showPending={showPending}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
