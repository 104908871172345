import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
  VStack,
  Switch,
  Box,
  FormLabel,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { MaskedInput } from '../../../../../../../../../../../../../components/Form/MaskedInput';
import { useActivity } from '../../../../../../../../../../../../../hooks/activity';
import { IActivityItemBase } from '../../../../../../../../../../../../../models/activities';
import { createActivityItemsService } from '../../../../../../../../../../../../../services/Activities/CreateActivityItemsService';
import { updateActivityItemsService } from '../../../../../../../../../../../../../services/Activities/UpdateActivityItemsService';
import { translateError } from '../../../../../../../../../../../../../utils/errors';
import { ConfirmationModal } from '../../../../../../../../../../../../../components/ConfirmationModal';

interface IHandleActivityItemModalFormData {
  description?: string;
  name: string;
  // quantityAvailable: number;
}

interface IHandleActivityItemModalProps {
  activityItem?: IActivityItemBase;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const activityItemFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Required'),
  // quantityAvailable: Yup.number()
  //   .positive()
  //   .integer()
  //   .nullable()
  //   .required('Required')
  //   .transform((value, originalValue) =>
  //     originalValue === '' ? null : unmaskNumber(value),
  //   ),
});

export const HandleActivityItemModal = ({
  activityItem,
  isOpen,
  onClose,
  onSave,
}: IHandleActivityItemModalProps): JSX.Element => {
  const toast = useToast();
  const { activity, handleActivity } = useActivity();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);

  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(activityItemFormSchema),
  });

  const { errors } = formState;

  const [isActive, setIsActive] = useState(true);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    reset({
      description: activityItem?.description,
      name: activityItem?.name,
      // quantityAvailable: activityItem?.quantityAvailable,
    });

    setIsActive(activityItem ? activityItem.isActive : true);
  }, [activityItem, reset]);

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleActivityItemSubmit: SubmitHandler<IHandleActivityItemModalFormData> =
    useCallback(
      async ({ description, name }) => {
        if (activity) {
          if (!activityItem) {
            try {
              const newActivityItem = await createActivityItemsService({
                activityId: activity.id,
                description,
                isActive,
                name,
                // quantityAvailable,
              });

              const items = [...activity.items, newActivityItem];

              handleActivity({
                ...activity,
                items,
              });

              reset({});
              setIsActive(true);

              onSave();

              toast({
                title: 'Cadastrado com sucesso',
                description: 'Item cadastrado corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                toast({
                  title: 'Falha ao cadastrar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao cadastrar o item, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          } else {
            try {
              const updatedActivityItem = await updateActivityItemsService({
                activityItemId: activityItem.id,
                description,
                isActive,
                isVerified,
                name,
                // quantityAvailable,
              });

              handleActivity({
                ...activity,
                items: activity.items.map((item) =>
                  item.id === updatedActivityItem.id
                    ? { ...item, ...updatedActivityItem }
                    : item,
                ),
              });

              reset({});
              setIsActive(true);
              setIsVerified(false);
              setIsVerifyConfirmationModalVisible(false);

              onSave();

              toast({
                title: 'Atualizado com sucesso',
                description: 'Item atualizado corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                if (err.response?.data.message === 'has-bookings-pending') {
                  handleToggleVerifyConfirmationModal();

                  return;
                }

                toast({
                  title: 'Falha ao atualizar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao atualizar o item, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          }
        }
      },
      [
        activity,
        activityItem,
        handleActivity,
        handleToggleVerifyConfirmationModal,
        isActive,
        isVerified,
        onSave,
        reset,
        toast,
      ],
    );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleCloseModal = useCallback(() => {
    reset({});
    setIsActive(true);
    setIsVerified(false);

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <ModalOverlay />
      <ModalContent
        ref={formRef}
        as="form"
        onSubmit={handleSubmit(handleActivityItemSubmit)}
      >
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Item
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            {/* <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={8}>
                <MaskedInput
                  label="Name"
                  error={errors.name}
                  {...register('name')}
                />
              </GridItem>

              <GridItem colSpan={4}>
                <MaskedInput
                  label="Quantidade disponível"
                  error={errors.quantityAvailable}
                  {...register('quantityAvailable')}
                />
              </GridItem>
            </Grid> */}

            <MaskedInput
              label="Name"
              error={errors.name}
              {...register('name')}
            />

            <MaskedInput
              label="Description"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.description}
              {...register('description')}
            />

            <Box>
              <FormLabel htmlFor="isActive">Active</FormLabel>
              <Switch
                name="isActive"
                id="isActive"
                isChecked={isActive}
                onChange={handleToggleIsActive}
              />
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
