import { Box, Heading, useToast, Icon, HStack } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { translateError } from '../../../../../utils/errors';
import { listFeatureGroupsService } from '../../../../../services/FeatureGroups/ListFeatureGroupsService';
import {
  FeatureGroupTable,
  IFeatureGroup,
} from './components/FeatureGroupTable';
import { useAuth } from '../../../../../hooks/auth';

interface ILoadFeatureGroupsProps {
  search?: string;
  page?: number;
}

export const FeatureGroupList = (): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [featureGroupsList, setFeatureGroupsList] = useState<IFeatureGroup[]>(
    [],
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadFeatureGroups = useCallback(
    async ({ search, page }: ILoadFeatureGroupsProps) => {
      try {
        const { items: featureGroups, pages } = await listFeatureGroupsService({
          search: search || undefined,
          limit: 20,
          page,
        });

        setTotalPages(pages);
        setFeatureGroupsList(featureGroups);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados dos grupos de permissões, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadFeatureGroups({
      page: currentPage,
    });
  }, [currentPage, loadFeatureGroups]);

  const debounceLoadFeatureGroups = debounce(loadFeatureGroups, 1000);
  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);
  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <HStack justify="space-between">
          <Heading size="lg" fontWeight="normal">
            Grupos de permissões
          </Heading>

          {authenticatedUser.featureGroup.features.some((feature) =>
            [
              'FEATURE_GROUP_FULL_ACCESS',
              'FEATURE_GROUP_WRITE_ACCESS',
            ].includes(feature.key),
          ) && (
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to="/feature-groups/management"
            >
              Register new
            </LinkButton>
          )}
        </HStack>

        <FeatureGroupTable
          mt="4"
          currentPage={currentPage}
          featureGroups={featureGroupsList}
          onPageChange={handlePageChange}
          onSearch={(value) => debounceLoadFeatureGroups({ search: value })}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
