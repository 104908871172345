import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
} from '@chakra-ui/react';
import { IActivityItemBase } from '../../../../../../../../../../../models/activities';

export type IBookingQueueItem = IActivityItemBase;

interface IBookingQueueItemProps extends BoxProps {
  bookingQueueItems: IBookingQueueItem[];
}

export const BookingQueueItemTable = ({
  bookingQueueItems,
  ...rest
}: IBookingQueueItemProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Name</Th>

          <Th>Description</Th>
        </Tr>
      </Thead>

      <Tbody>
        {bookingQueueItems.map((bookingQueueItem) => (
          <Tr key={bookingQueueItem.id}>
            <Td>{bookingQueueItem.name}</Td>

            <Td>{bookingQueueItem.description}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
