import { IFeatureBase } from '../../models/features';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateFeatureProps {
  featureId: string;
  key: string;
  name: string;
}

export interface IUpdatedFeature extends IFeatureBase {
  featureGroupsCount: number;
}

export const updateFeaturesService = async ({
  featureId,
  key,
  name,
}: IUpdateFeatureProps): Promise<IUpdatedFeature> => {
  const { data } = await wbsBookingsApi.put<IUpdatedFeature>(
    `/features/${featureId}`,
    {
      key,
      name,
    },
  );

  return data;
};
