import { IFeatureGroupBase } from '../../models/features';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IListFeatureGroupsProps {
  search?: string;
  limit?: number;
  page?: number;
}

export interface IFeatureGroupListItem extends IFeatureGroupBase {
  featuresCount: number;
  usersCount: number;
}

export interface IFeatureGroupList {
  items: IFeatureGroupListItem[];
  pages: number;
  total: number;
}

export const listFeatureGroupsService = async (
  filters: IListFeatureGroupsProps,
): Promise<IFeatureGroupList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          search: filters.search || undefined,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await wbsBookingsApi.get<IFeatureGroupList>(
    '/feature-groups',
    {
      params,
    },
  );

  return data;
};
