import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
  Box,
  FormLabel,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
import { isSameDay } from 'date-fns';
import { DatePicker } from '../../../../../../../../../../../../../components/Form/DatePicker';
import { MaskedInput } from '../../../../../../../../../../../../../components/Form/MaskedInput';
import { useActivity } from '../../../../../../../../../../../../../hooks/activity';
import { createActivitySchedulesService } from '../../../../../../../../../../../../../services/Activities/CreateActivitySchedulesService';
import { updateActivitySchedulesService } from '../../../../../../../../../../../../../services/Activities/UpdateActivitySchedulesService';
import { translateError } from '../../../../../../../../../../../../../utils/errors';
import { UserExperience } from '../../../../../../../../../../../../../models/users';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../../../../../../../../../components/Form/ReactSelect';
import { IDetailedActivitySchedule } from '../../../../../../../../../../../../../services/Activities/ShowActivitiesService';
import { Switch } from '../../../../../../../../../../../../../components/Form/Switch';
import {
  maskMoney,
  unmaskNumber,
} from '../../../../../../../../../../../../../utils/formatters/handleMask';
import { ConfirmationModal } from '../../../../../../../../../../../../../components/ConfirmationModal';
import { WaveSideEnum } from '../../../../../../../../../../../../../models/activities';
import { DatePickerMultiple } from '../../../../../../../../../../../../../components/Form/DatePickerMultiple';

interface IHandleActivityScheduleModalFormData {
  allowLinkBookings?: boolean;
  allowPartialTimeBookings?: boolean;
  bookingPrice?: number;
  endDate?: string;
  endTime: string;
  isActive: boolean;
  minUserExperience: UserExperience;
  modalityId?: string | null;
  waveSide?: WaveSideEnum;
  startDate: string;
  startTime: string;
  totalVacancies: number;
}

interface IHandleActivityScheduleModalProps {
  activitySchedule?: IDetailedActivitySchedule;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const activityScheduleFormSchema = Yup.object().shape({
  allowLinkBookings: Yup.boolean().default(false),
  allowPartialTimeBookings: Yup.boolean().default(false),
  bookingPrice: Yup.number()
    .integer('Valores inteiros')
    .positive('Valores maiores que zero')
    .nullable()
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
  endDate: Yup.date()
    .nullable()
    .min(Yup.ref('startDate'), 'Data inválida')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  endTime: Yup.string()
    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
    .required('Required')
    .nullable()
    .test(
      'is-greater',
      'Horário final deve ser maior que o inicial',
      function isSameOrAfter(value) {
        return moment(value, 'HH:mm').isSameOrAfter(
          moment(this.parent.startTime, 'HH:mm'),
        );
      },
    )
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  isActive: Yup.boolean().default(true),
  minUserExperience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 4 e 5'],
      UserExperience['Nível 5 e 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  modalityId: Yup.string()
    .uuid()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  startDate: Yup.date()
    .required('Required')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  startTime: Yup.string()
    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
    .required('Required')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  totalVacancies: Yup.number().when('allowPartialTimeBookings', {
    is: true,
    then: Yup.number()
      .nullable()
      .integer('Valores inteiros')
      .positive('Valores maiores que zero')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.number()
      .nullable()
      .integer('Valores inteiros')
      .positive('Valores maiores que zero')
      .required('Required')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  waveSide: Yup.string().oneOf(['COMBO', 'LEFT', 'RIGHT', null]).nullable(),
});

export const HandleActivityScheduleModal = ({
  activitySchedule,
  isOpen,
  onClose,
  onSave,
}: IHandleActivityScheduleModalProps): JSX.Element => {
  const toast = useToast();
  const { activity, handleActivity } = useActivity();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);
  const { register, formState, handleSubmit, reset, control, watch, setValue } =
    useForm({
      resolver: yupResolver(activityScheduleFormSchema),
    });

  const { errors } = formState;

  const selectedStartDate: Date | undefined = watch('startDate');

  const currentAllowPartialTimeBookings = watch('allowPartialTimeBookings');

  const [scheduleBlocks, setScheduleBlocks] = useState<Date[]>([]);
  const [selectedWeekDays, setSelectedWeekDays] = useState([
    0, 1, 2, 3, 4, 5, 6,
  ]);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [modalitiesSelectOptions, setModalitiesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const weekDays = useMemo(
    () => [
      {
        label: 'D',
        value: 0,
      },
      {
        label: 'M',
        value: 1,
      },
      {
        label: 'T',
        value: 2,
      },
      {
        label: 'W',
        value: 3,
      },
      {
        label: 'T',
        value: 4,
      },
      {
        label: 'F',
        value: 5,
      },
      {
        label: 'S',
        value: 6,
      },
    ],
    [],
  );

  const experienceSelectOptions = useMemo(
    () => [
      {
        label: 'Nível 1 e 2',
        value: UserExperience['Nível 1 e 2'],
      },
      {
        label: 'Nível 3 e 4',
        value: UserExperience['Nível 3 e 4'],
      },
      {
        label: 'Nível 4 e 5',
        value: UserExperience['Nível 4 e 5'],
      },
      {
        label: 'Nível 5 e 6',
        value: UserExperience['Nível 5 e 6'],
      },
      {
        label: 'Nível 6 e 7',
        value: UserExperience['Nível 6 e 7'],
      },
      {
        label: 'Nível 7',
        value: UserExperience['Nível 7'],
      },
      {
        label: 'Especial',
        value: UserExperience.Especial,
      },
    ],
    [],
  );

  const waveSideSelectOptions = useMemo(
    () => [
      {
        label: 'Indefinido',
        value: null,
      },
      {
        label: 'Combo',
        value: 'COMBO',
      },
      {
        label: 'Esquerda',
        value: 'LEFT',
      },
      {
        label: 'Direita',
        value: 'RIGHT',
      },
    ],
    [],
  );

  useEffect(() => {
    if (activity) {
      setModalitiesSelectOptions([
        {
          label: 'Todas',
          value: '',
        },
        ...activity.spot.modalities
          .filter((modality) => modality.isActive)
          .map((modality) => ({
            label: modality.title,
            value: modality.id,
          })),
      ]);
    }
  }, [activity]);

  useEffect(() => {
    if (activitySchedule) {
      const [startDateYear, startDateMonth, startDateDay] =
        activitySchedule.startDate.split('-');
      const [endDateYear, endDateMonth, endDateDay] =
        activitySchedule.endDate?.split('-') || [];

      reset({
        allowLinkBookings: activitySchedule.allowLinkBookings,
        allowPartialTimeBookings: activitySchedule.allowPartialTimeBookings,
        bookingPrice: activitySchedule.bookingPrice
          ? maskMoney(activitySchedule.bookingPrice)
          : undefined,
        endDate: activitySchedule.endDate
          ? new Date(
              Number(endDateYear),
              Number(endDateMonth) - 1,
              Number(endDateDay),
            )
          : undefined,
        endTime: activitySchedule.endTime.slice(0, 5),
        isActive: activitySchedule.isActive,
        minUserExperience: activitySchedule.minUserExperience,
        modalityId: activitySchedule.modalityId || '',
        startDate: new Date(
          Number(startDateYear),
          Number(startDateMonth) - 1,
          Number(startDateDay),
        ),
        startTime: activitySchedule.startTime.slice(0, 5),
        totalVacancies: activitySchedule.totalVacancies,
        waveSide: activitySchedule.waveSide || null,
      });

      setScheduleBlocks(
        activitySchedule.scheduleBlocks.map(
          (scheduleBlock) => new Date(`${scheduleBlock.blockedDate}T00:00:00`),
        ),
      );

      setSelectedWeekDays(activitySchedule.daysOfWeek);
    } else {
      reset({
        allowLinkBookings: false,
        allowPartialTimeBookings: false,
        endDate: null,
        endTime: null,
        isActive: true,
        minUserExperience: UserExperience['Nível 1 e 2'],
        modalityId: '',
        startDate: null,
        startTime: null,
        totalVacancies: null,
        waveSide: null,
      });

      setScheduleBlocks([]);
      setSelectedWeekDays([0, 1, 2, 3, 4, 5, 6]);
    }
  }, [activitySchedule, reset]);

  useEffect(() => {
    setValue('totalVacancies', null);
  }, [currentAllowPartialTimeBookings, setValue]);

  const handleTogleSelectWeekDays = useCallback((weekDayValue: number) => {
    setSelectedWeekDays((prevState) =>
      prevState.includes(weekDayValue)
        ? prevState.filter((selectedDay) => selectedDay !== weekDayValue)
        : [...prevState, weekDayValue],
    );
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleScheduleBlocks = (value: Date): void => {
    setScheduleBlocks((prevState) => {
      const blockExists = prevState.find((scheduleBlock) =>
        isSameDay(scheduleBlock, value),
      );

      if (blockExists) {
        return prevState.filter(
          (scheduleBlock) => scheduleBlock !== blockExists,
        );
      }

      return [...prevState, value];
    });
  };

  const handleActivityScheduleSubmit: SubmitHandler<IHandleActivityScheduleModalFormData> =
    useCallback(
      async ({
        allowLinkBookings,
        allowPartialTimeBookings,
        bookingPrice,
        endDate,
        endTime,
        isActive,
        minUserExperience,
        modalityId,
        startDate,
        startTime,
        totalVacancies,
        waveSide,
      }) => {
        if (activity) {
          const parsedBlocks = scheduleBlocks.map((scheduleBlock) => ({
            blockedDate: scheduleBlock.toLocaleDateString('fr-CA'),
          }));

          if (!activitySchedule) {
            try {
              const newActivitySchedule = await createActivitySchedulesService({
                allowLinkBookings,
                allowPartialTimeBookings,
                activityId: activity.id,
                bookingPrice,
                daysOfWeek: selectedWeekDays,
                endDate: endDate
                  ? new Date(endDate).toISOString().split('T')[0]
                  : undefined,
                endTime: endTime.padEnd(8, ':00'),
                isActive,
                minUserExperience,
                modalityId,
                scheduleBlocks: parsedBlocks,
                startDate: new Date(startDate).toISOString().split('T')[0],
                startTime: startTime.padEnd(8, ':00'),
                totalVacancies,
                waveSide,
              });
              const schedules = [
                ...activity.schedules,
                { ...newActivitySchedule, bookings: [] },
              ];

              handleActivity({
                ...activity,
                schedules,
              });

              reset({
                allowLinkBookings: false,
                allowPartialTimeBookings: false,
                bookingPrice: null,
                endDate: null,
                endTime: null,
                isActive: true,
                minUserExperience: UserExperience['Nível 1 e 2'],
                modalityId: '',
                startDate: null,
                startTime: null,
                totalVacancies: null,
                waveSide: null,
              });

              setScheduleBlocks([]);
              setSelectedWeekDays([0, 1, 2, 3, 4, 5, 6]);
              onSave();

              toast({
                title: 'Cadastrado com sucesso',
                description: 'Evento cadastrado corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                toast({
                  title: 'Falha ao cadastrar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao cadastrar o evento, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          } else {
            try {
              const updatedActivitySchedule =
                await updateActivitySchedulesService({
                  activityScheduleId: activitySchedule.id,
                  allowLinkBookings,
                  allowPartialTimeBookings,
                  bookingPrice,
                  daysOfWeek: selectedWeekDays,
                  endDate: endDate
                    ? new Date(endDate).toISOString().split('T')[0]
                    : null,
                  endTime: endTime.padEnd(8, ':00'),
                  isActive,
                  isVerified,
                  minUserExperience,
                  modalityId,
                  scheduleBlocks: parsedBlocks,
                  startDate: new Date(startDate).toISOString().split('T')[0],
                  startTime: startTime.padEnd(8, ':00'),
                  totalVacancies,
                  waveSide,
                });

              handleActivity({
                ...activity,
                schedules: activity.schedules.map((schedule) =>
                  schedule.id === updatedActivitySchedule.id
                    ? { ...schedule, ...updatedActivitySchedule }
                    : schedule,
                ),
              });

              reset({
                allowLinkBookings: false,
                allowPartialTimeBookings: false,
                bookingPrice: null,
                endDate: null,
                endTime: null,
                isActive: true,
                minUserExperience: UserExperience['Nível 1 e 2'],
                modalityId: '',
                startDate: null,
                startTime: null,
                totalVacancies: null,
                waveSide: null,
              });

              setScheduleBlocks([]);
              setSelectedWeekDays([0, 1, 2, 3, 4, 5, 6]);
              setIsVerified(false);
              setIsVerifyConfirmationModalVisible(false);

              onSave();

              toast({
                title: 'Atualizado com sucesso',
                description: 'Evento atualizado corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                if (err.response?.data.message === 'has-bookings-pending') {
                  setIsVerifyConfirmationModalVisible(true);
                  return;
                }

                toast({
                  title: 'Falha ao atualizar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao atualizar o evento, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          }
        }
      },
      [
        activity,
        activitySchedule,
        handleActivity,
        isVerified,
        onSave,
        reset,
        // scheduleBlocks,
        selectedWeekDays,
        toast,
      ],
    );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleCloseModal = useCallback(() => {
    reset({
      allowLinkBookings: false,
      allowPartialTimeBookings: false,
      bookingPrice: null,
      endDate: null,
      endTime: null,
      isActive: true,
      minUserExperience: UserExperience['Nível 1 e 2'],
      modalityId: '',
      startDate: null,
      startTime: null,
      totalVacancies: null,
      waveSide: null,
    });

    setScheduleBlocks([]);
    setSelectedWeekDays([0, 1, 2, 3, 4, 5, 6]);

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <ModalOverlay />

      <ModalContent
        ref={formRef}
        as="form"
        onSubmit={handleSubmit(handleActivityScheduleSubmit)}
      >
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Calendar spot
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <DatePicker
              label="Start date"
              isClearable={false}
              minDate={new Date()}
              control={control}
              error={errors.startDate}
              {...register('startDate')}
            />

            <DatePicker
              label="End date"
              control={control}
              minDate={selectedStartDate || new Date()}
              error={errors.endDate}
              {...register('endDate')}
            />

            <MaskedInput
              label="Start time"
              mask="time"
              error={errors.startTime}
              {...register('startTime')}
            />

            <MaskedInput
              label="End time"
              mask="time"
              error={errors.endTime}
              {...register('endTime')}
            />

            {!currentAllowPartialTimeBookings && (
              <MaskedInput
                label="Number of slots"
                error={errors.totalVacancies}
                {...register('totalVacancies')}
              />
            )}

            <MaskedInput
              label={
                !currentAllowPartialTimeBookings
                  ? 'Slot price'
                  : 'Slot price (per hour)'
              }
              mask="money"
              error={errors.bookingPrice}
              {...register('bookingPrice')}
            />

            {/* <ReactSelect
              label="Modalidade"
              name="modalityId"
              options={modalitiesSelectOptions}
              control={control}
              error={errors.modalityId}
            />

            <ReactSelect
              label="Lado da onda"
              name="waveSide"
              options={waveSideSelectOptions}
              control={control}
              error={errors.waveSide}
            /> */}

            {/* <ReactSelect
              label="Nível de experiência mínimo"
              name="minUserExperience"
              options={experienceSelectOptions}
              control={control}
              error={errors.minUserExperience}
            /> */}

            {/* <DatePickerMultiple
              label="Blocked dates"
              name="scheduleBlocks"
              minDate={selectedStartDate || new Date()}
              onChange={handleScheduleBlocks}
              selectedDates={scheduleBlocks}
            /> */}

            <Box>
              <FormLabel>Days of the week</FormLabel>
              <ButtonGroup>
                {weekDays.map((weekDay) => (
                  <Button
                    key={String(weekDay.value)}
                    size="sm"
                    colorScheme={
                      selectedWeekDays.includes(weekDay.value) ? 'blue' : 'gray'
                    }
                    onClick={() => handleTogleSelectWeekDays(weekDay.value)}
                  >
                    {weekDay.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>

            <Flex alignItems="flex-end">
              <Switch
                label="Active"
                error={errors.isActive}
                {...register('isActive')}
              />

              <Switch
                label="Partial bookings"
                error={errors.allowPartialTimeBookings}
                {...register('allowPartialTimeBookings')}
              />

              <Switch
                label="Bookings via the link"
                error={errors.allowLinkBookings}
                {...register('allowLinkBookings')}
              />
            </Flex>
          </SimpleGrid>
        </ModalBody>

        <ModalFooter mt={currentAllowPartialTimeBookings ? '8' : 0}>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancel
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
