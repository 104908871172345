import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../../../../../../../../../../../../../components/Form/MaskedInput';
import { unmaskNumber } from '../../../../../../../../../../../../../utils/formatters/handleMask';

interface IWalletTransactionModalFormData {
  amount: number;
}

interface IWalletTransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (amount: number) => void;
}

const checkinFormSchema = Yup.object().shape({
  amount: Yup.number()
    .integer('Valores inteiros')
    .positive('Valores maiores que zero')
    .nullable()
    .required('Required')
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
});

export const WalletTransactionModal = ({
  isOpen,
  onClose,
  onSave,
}: IWalletTransactionModalProps): JSX.Element => {
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(checkinFormSchema),
  });

  const { errors } = formState;

  const handleTransactionSubmit: SubmitHandler<IWalletTransactionModalFormData> =
    useCallback(
      async ({ amount }) => {
        reset({});

        onSave(amount);
      },
      [onSave, reset],
    );

  const handleCloseModal = useCallback(() => {
    reset({});

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleTransactionSubmit)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Transação
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <MaskedInput
            label="Valor"
            mask="money"
            error={errors.amount}
            {...register('amount')}
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
