import { IFeatureBase } from '../../models/features';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface ICreateFeatureProps {
  key: string;
  name: string;
}

export interface INewFeature extends IFeatureBase {
  featureGroupsCount: number;
}

export const createFeaturesService = async ({
  key,
  name,
}: ICreateFeatureProps): Promise<INewFeature> => {
  const { data } = await wbsBookingsApi.post<INewFeature>('/features', {
    key,
    name,
  });

  return data;
};
