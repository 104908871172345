import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
} from '@chakra-ui/react';
import { IFeatureBase } from '../../../../../../../../../../../models/features';

interface IUserFeaturesTableProps extends BoxProps {
  features: IFeatureBase[];
}

export const UserFeaturesTable = ({
  features,
  ...rest
}: IUserFeaturesTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Name</Th>
        </Tr>
      </Thead>

      <Tbody>
        {features.map((featureGroup) => (
          <Tr key={featureGroup.id}>
            <Td>
              <Text fontWeight="bold">{featureGroup.name}</Text>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
