import {
  IActivityBase,
  IActivityItemBase,
  IActivityScheduleBase,
} from '../../models/activities';
import { IBookingBase } from '../../models/bookings';
import { IFeatureGroupBase } from '../../models/features';
import { ISpotBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import { IWalletTransactionBase } from '../../models/wallets';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IDetailedBookingActivity extends IActivityBase {
  spot: ISpotBase;
}

export interface IDetailedBookingActivitySchedule
  extends IActivityScheduleBase {
  activity: IDetailedBookingActivity;
}

export interface IDetailedBookingUser extends IUserBase {
  featureGroup: IFeatureGroupBase;
}

export interface IDetailedBooking extends IBookingBase {
  activitySchedule: IDetailedBookingActivitySchedule;
  user: IDetailedBookingUser;
  items: IActivityItemBase[];
  transactions: IWalletTransactionBase[];
}

export const showBookingsService = async (
  bookingId: string,
): Promise<IDetailedBooking> => {
  const { data } = await wbsBookingsApi.get<IDetailedBooking>(
    `/bookings/${bookingId}`,
  );

  return data;
};
