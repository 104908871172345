import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { RiDeleteBinLine, RiEditLine } from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IFeatureBase } from '../../../../../../../models/features';
import { IFeatureListItem } from '../../../../../../../services/Features/ListFeaturesService';
import { IUpdatedFeature } from '../../../../../../../services/Features/UpdateFeaturesService';
import { HandleFeatureModal } from '../HandleFeatureModal';

export type IFeature = IFeatureListItem;

interface IFeatureTableProps extends BoxProps {
  currentPage?: number;
  features: IFeature[];
  onDelete: (featureId: string) => void;
  onPageChange?: (page: number) => void;
  onSave: (updatedFeature: IUpdatedFeature) => void;
  onSearch: (search: string) => void;
  totalPages?: number;
}

export const FeatureTable = ({
  currentPage,
  features,
  onDelete,
  onPageChange,
  onSave,
  onSearch,
  totalPages,
  ...rest
}: IFeatureTableProps): JSX.Element => {
  const [handlingFeature, setHandlingFeature] = useState<IFeatureBase>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [isHandleFeatureModalVisible, setIsHandleFeatureModalVisible] =
    useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleFeatureModal = useCallback(() => {
    setIsHandleFeatureModalVisible((prevState) => !prevState);
  }, []);

  const handleUpdateFeature = useCallback(
    (feature: IFeatureBase) => {
      setHandlingFeature(feature);

      handleToggleHandleFeatureModal();
    },
    [handleToggleHandleFeatureModal],
  );

  const handleDeleteFeatureModal = useCallback(
    (feature: IFeatureBase) => {
      setHandlingFeature(feature);

      handleToggleDeleteConfirmationModal();
    },
    [handleToggleDeleteConfirmationModal],
  );

  const handleDeleteFeature = useCallback(() => {
    if (handlingFeature) {
      onDelete(handlingFeature.id);

      setHandlingFeature(undefined);
    }
  }, [handlingFeature, onDelete]);

  const handleSaveFeature = useCallback(
    (updatedFeature: IUpdatedFeature) => {
      onSave(updatedFeature);

      setHandlingFeature(undefined);

      handleToggleHandleFeatureModal();
    },
    [handleToggleHandleFeatureModal, onSave],
  );

  return (
    <Box {...rest}>
      {handlingFeature && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={handleToggleDeleteConfirmationModal}
          onConfirm={handleDeleteFeature}
          title="Confirmar exclusão"
          message="Deseja realmente Delete?"
        />
      )}

      <HandleFeatureModal
        feature={handlingFeature}
        isOpen={isHandleFeatureModalVisible}
        onClose={handleToggleHandleFeatureModal}
        onSave={handleSaveFeature}
      />

      <TableFilters searchLabel="Buscar" onSearch={onSearch} />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Name da permissão</Th>
            <Th>Chave</Th>
            <Th textAlign="center"># Grupos</Th>
            <Th textAlign="center" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {features.map((feature) => (
            <Tr key={feature.id}>
              <Td>
                <Text fontWeight="bold">{feature.name}</Text>
              </Td>

              <Td>{feature.key}</Td>

              <Td textAlign="center">{feature.featureGroupsCount}</Td>

              <Td textAlign="center">
                <ButtonGroup>
                  <IconButton
                    aria-label="Edit"
                    size="sm"
                    icon={<Icon as={RiEditLine} />}
                    fontSize="16"
                    color="white"
                    colorScheme="yellow"
                    onClick={() => handleUpdateFeature(feature)}
                  />

                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<Icon as={RiDeleteBinLine} />}
                    fontSize="16"
                    colorScheme="red"
                    onClick={() => handleDeleteFeatureModal(feature)}
                  />
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
