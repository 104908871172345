import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import { IUserBase, UserGender } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateUserProfileProps {
  bio?: string;
  birthDate?: Date;
  cpf?: string;
  email: string;
  gender?: UserGender;
  name: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string;
}

export interface IUpdateProfileDetailedFeatureGroup extends IFeatureGroupBase {
  features: IFeatureBase[];
}

export interface IUpdatedProfile extends IUserBase {
  featureGroup: IUpdateProfileDetailedFeatureGroup;
}

export const updateUsersProfileService = async ({
  bio,
  birthDate,
  cpf,
  email,
  gender,
  name,
  password,
  passwordConfirmation,
  phone,
}: IUpdateUserProfileProps): Promise<IUpdatedProfile> => {
  const { data } = await wbsBookingsApi.put<IUpdatedProfile>('/users/profile', {
    bio,
    birthDate,
    cpf,
    email,
    gender,
    name,
    password,
    passwordConfirmation,
    phone,
  });

  return data;
};
