import { IActivityItemBase } from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { ISpotModalityBase } from '../../models/spots';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface ICreateBookingQueueProps {
  activityId: string;
  activityScheduleId?: string;
  bookedDate: Date;
  description?: string;
  items?: IActivityItemBase[];
  modalities?: ISpotModalityBase[];
  userId: string;
}

export type INewBookingQueue = IBookingQueueBase;

export const createBookingQueuesService = async ({
  activityId,
  activityScheduleId,
  bookedDate,
  description,
  items,
  modalities,
  userId,
}: ICreateBookingQueueProps): Promise<INewBookingQueue> => {
  const { data } = await wbsBookingsApi.post<INewBookingQueue>(
    '/booking-queues',
    {
      activityId,
      activityScheduleId,
      bookedDate,
      description,
      items,
      modalities,
      userId,
    },
  );

  return data;
};
