import wbsBookingsApi from '../apis/wbsBookingsApi';
import {
  IActivityScheduleBase,
  IActivityScheduleBlockBase,
  WaveSideEnum,
} from '../../models/activities';
import { UserExperience } from '../../models/users';

interface ICreateActivityScheduleBlocks {
  blockedDate: string;
}

interface ICreateActivityScheduleProps {
  activityId: string;
  allowLinkBookings?: boolean;
  allowPartialTimeBookings?: boolean;
  bookingPrice?: number;
  daysOfWeek: Array<number>;
  endDate?: string;
  endTime: string;
  isActive: boolean;
  minUserExperience: UserExperience;
  modalityId?: string | null;
  scheduleBlocks: ICreateActivityScheduleBlocks[];
  startDate: string;
  startTime: string;
  totalVacancies: number;
  waveSide?: WaveSideEnum;
}

export interface INewActivitySchedule extends IActivityScheduleBase {
  scheduleBlocks: IActivityScheduleBlockBase[];
}

export const createActivitySchedulesService = async ({
  activityId,
  allowLinkBookings,
  allowPartialTimeBookings,
  bookingPrice,
  daysOfWeek,
  endDate,
  endTime,
  isActive,
  minUserExperience,
  modalityId,
  // scheduleBlocks,
  startDate,
  startTime,
  totalVacancies,
  waveSide,
}: ICreateActivityScheduleProps): Promise<INewActivitySchedule> => {
  const { data } = await wbsBookingsApi.post<INewActivitySchedule>(
    `/activities/${activityId}/schedules`,
    {
      allowLinkBookings,
      allowPartialTimeBookings,
      bookingPrice,
      daysOfWeek,
      endDate,
      endTime,
      isActive,
      minUserExperience,
      modalityId,
      // scheduleBlocks,
      startDate,
      startTime,
      totalVacancies,
      waveSide,
    },
  );

  return data;
};
