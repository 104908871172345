import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmarkLine,
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiDeleteBinLine,
  RiEyeLine,
  RiLoginCircleLine,
  RiLogoutCircleRLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { translateError } from '../../../../../../../utils/errors';
import {
  IBookingContextState,
  useBooking,
} from '../../../../../../../hooks/booking';
import deleteBookingsService from '../../../../../../../services/Bookings/DeleteBookingsService';
import BookingDetailsUserInfo from './components/BookingDetailsUserInfo';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { useAuth } from '../../../../../../../hooks/auth';
import { updateBookingsCheckinService } from '../../../../../../../services/Bookings/UpdateBookingsCheckinService';
import { HandleCheckinModal } from '../../../../../../../components/HandleCheckinModal';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import { processBookingPaymentsService } from '../../../../../../../services/Bookings/ProcessBookingPaymentsService';

interface IHandleToggleCheckinProps {
  bookingId: string;
  checkinAt?: Date;
  checkoutAt?: Date;
  identifyBraceletNumber?: string;
}

export const BookingDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { push } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { booking, handleBooking } = useBooking();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [isHandleCheckinModalVisible, setIsHandleCheckinModalVisible] =
    useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

  const handleTogglePaymentModal = useCallback(() => {
    setIsPaymentModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleCheckinModal = useCallback(() => {
    setIsHandleCheckinModalVisible((prevState) => !prevState);
  }, []);

  const handlePayment = useCallback(
    async (bookingState: IBookingContextState) => {
      try {
        const paydBooking = await processBookingPaymentsService(
          bookingState.id,
        );

        handleBooking({
          ...bookingState,
          ...paydBooking,
          user: bookingState.user,
        });

        toast({
          title: 'Transação concluída com sucesso',
          description: 'A transação foi efetuada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha na transação',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao efetuar a transação, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        handleTogglePaymentModal();
      }
    },
    [handleBooking, handleTogglePaymentModal, toast],
  );

  const handleDeleteBooking = useCallback(async () => {
    if (booking?.id) {
      try {
        await deleteBookingsService(booking.id);

        toast({
          title: 'Excluída com sucesso',
          description: 'A reserva foi excluída corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/bookings');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao Delete',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao Delete a reserva, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [booking?.id, push, toast]);

  const handleToggleCheckin = useCallback(
    async ({
      bookingId,
      checkinAt,
      checkoutAt,
      identifyBraceletNumber,
    }: IHandleToggleCheckinProps) => {
      if (booking) {
        const updatedBooking = await updateBookingsCheckinService({
          bookingId,
          checkinAt,
          checkoutAt,
          identifyBraceletNumber,
        });

        handleBooking({ ...booking, ...updatedBooking });
      }
    },
    [booking, handleBooking],
  );

  const handleCheckin = useCallback(
    (identifyBraceletNumber?: string) => {
      if (booking && identifyBraceletNumber) {
        handleToggleCheckin({
          bookingId: booking.id,
          checkinAt: !booking.checkinAt
            ? new Date()
            : new Date(booking.checkinAt),
          identifyBraceletNumber,
        });
      }

      handleToggleHandleCheckinModal();
    },
    [booking, handleToggleCheckin, handleToggleHandleCheckinModal],
  );

  const handleCheckout = useCallback(() => {
    if (booking?.identifyBraceletNumber) {
      handleToggleCheckin({
        bookingId: booking.id,
        checkoutAt: booking.checkinAt ? new Date() : undefined,
      });
    }
  }, [
    booking?.checkinAt,
    booking?.id,
    booking?.identifyBraceletNumber,
    handleToggleCheckin,
  ]);

  return (
    <>
      {!!booking && (
        <HandleCheckinModal
          booking={booking}
          isOpen={isHandleCheckinModalVisible}
          onClose={handleToggleHandleCheckinModal}
          onSave={(bookingData) =>
            handleCheckin(bookingData.identifyBraceletNumber)
          }
        />
      )}

      {!!booking && (
        <ConfirmationModal
          isOpen={isPaymentModalVisible}
          onClose={handleTogglePaymentModal}
          onConfirm={() => handlePayment(booking)}
          title="Confirmar pagamento"
          message="O valor será debitado do saldo disponível na carteira do Member."
        />
      )}

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteBooking}
        title="Confirmar exclusão"
        message="Deseja realmente Delete?"
      />

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes da reserva
          </Heading>
        </Flex>

        {booking && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8">
                  <VStack flex="1" alignItems="flex-start" pl="8">
                    <Text fontSize="4xl" fontWeight="bold">
                      {booking.activitySchedule.activity.name}
                    </Text>

                    <HStack>
                      <Text>Resource:</Text>
                      <Text color="gray.500">
                        {booking.activitySchedule.activity.spot.name}
                      </Text>
                    </HStack>

                    <HStack>
                      <Text>Booking date/time:</Text>
                      <Text color="gray.500">{`${booking.formattedBookedDate} ${booking.formattedStartTime} - ${booking.formattedEndTime}`}</Text>
                    </HStack>

                    <HStack>
                      <Text>Booked at:</Text>
                      <Text color="gray.500">{booking.formattedCreatedAt}</Text>
                    </HStack>

                    {!!booking.bookingPrice && (
                      <>
                        <HStack>
                          <Text>Booking price:</Text>
                          <Text color="gray.500">
                            {booking.formattedBookingPrice}
                          </Text>
                        </HStack>

                        {!!booking.paymentPendingAmount && (
                          <HStack>
                            <Text>Pending price:</Text>
                            <Text color="gray.500">
                              {booking.formattedPaymentPendingAmount}
                            </Text>
                          </HStack>
                        )}

                        <HStack>
                          <Text>Payment status:</Text>

                          {booking.paymentPending ? (
                            <HStack>
                              <Icon
                                fontSize="2xl"
                                as={RiCloseCircleLine}
                                color="red.500"
                              />
                              <Text color="red.500">Pending</Text>
                            </HStack>
                          ) : (
                            <HStack>
                              <Icon
                                fontSize="2xl"
                                as={RiCheckboxCircleLine}
                                color="green"
                              />
                              <Text color="green.500">Paid</Text>
                            </HStack>
                          )}
                        </HStack>
                      </>
                    )}

                    {!!booking.formattedCheckinAt && (
                      <HStack>
                        <Text>Checkin em:</Text>
                        <Text color="gray.500">
                          {booking.formattedCheckinAt}
                        </Text>
                      </HStack>
                    )}

                    {!!booking.formattedCheckoutAt && (
                      <HStack>
                        <Text>Checkout em:</Text>
                        <Text color="gray.500">
                          {booking.formattedCheckoutAt}
                        </Text>
                      </HStack>
                    )}
                  </VStack>

                  <ButtonGroup mt="8" pl="8">
                    <LinkButton
                      icon={<Icon as={RiEyeLine} fontSize="20" />}
                      to={{
                        pathname: '/activities/details',
                        state: {
                          activityId: booking.activitySchedule.activityId,
                        },
                      }}
                    >
                      Calendar
                    </LinkButton>

                    <LinkButton
                      icon={<Icon as={RiEyeLine} fontSize="20" />}
                      to={{
                        pathname: '/spots/details',
                        state: {
                          spotId: booking.activitySchedule.activity.spotId,
                        },
                      }}
                    >
                      Resource
                    </LinkButton>

                    {/* <LinkButton
                      disabled
                      colorScheme="yellow"
                      color="white"
                      icon={<Icon as={RiEditLine} fontSize="16" />}
                      to={{
                        pathname: '/bookings/update',
                        state: {
                          bookingId: booking.id,
                        },
                      }}
                    >
                      Edit
                    </LinkButton> */}

                    {!!booking.paymentPending && !booking.isExpired && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleTogglePaymentModal}
                        leftIcon={
                          <Icon as={RiMoneyDollarCircleLine} fontSize="20" />
                        }
                      >
                        Payment
                      </Button>
                    )}

                    {!booking.paymentPending &&
                      !booking.checkinAt &&
                      !booking.isExpired && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="green"
                          onClick={handleToggleHandleCheckinModal}
                          leftIcon={
                            <Icon as={RiLoginCircleLine} fontSize="20" />
                          }
                        >
                          Checkin
                        </Button>
                      )}

                    {booking.checkinAt && !booking.checkoutAt && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleCheckout}
                        leftIcon={
                          <Icon as={RiLogoutCircleRLine} fontSize="20" />
                        }
                      >
                        Checkout
                      </Button>
                    )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['BOOKING_FULL_ACCESS', 'BOOKING_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleToggleDeleteConfirmationModal}
                        leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      >
                        Delete
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              {booking.description && (
                <GridItem colSpan={[12, 12, 12, 12, 4]}>
                  <Flex height="100%">
                    <Divider
                      hidden={dividerOrientation === 'horizontal'}
                      px="2"
                      height="80%"
                      borderColor="gray.300"
                      orientation="vertical"
                    />

                    <Flex flexDirection="column" w="100%">
                      <Divider
                        hidden={dividerOrientation === 'vertical'}
                        borderColor="gray.300"
                        mt="4"
                        mb="8"
                      />
                      <Flex
                        flexDirection="column"
                        px={[8, 8, 8, 8, 0]}
                        w="100%"
                      >
                        <HStack mt={[0, 0, 0, 0, 2]}>
                          <Icon as={RiBookmarkLine} fontSize="20" />
                          <Text>Biography</Text>
                        </HStack>

                        <Text
                          fontSize="sm"
                          color="gray.500"
                          mt="4"
                          px={[4, 4, 4, 4, 0]}
                        >
                          {booking.description}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </GridItem>
              )}
            </Grid>

            <Divider borderColor="gray.300" my="8" />

            <BookingDetailsUserInfo user={booking.user} />
          </Flex>
        )}
      </Flex>
    </>
  );
};
