import {
  IActivityItemBase,
  IActivityScheduleBase,
} from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { ISpotModalityBase } from '../../models/spots';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateBookingQueueProps {
  bookingQueueId: string;
  activityScheduleId?: string;
  bookedDate: Date;
  description?: string;
  items?: IActivityItemBase[];
  modalities?: ISpotModalityBase[];
}

export interface IUpdatedBookingQueue extends IBookingQueueBase {
  activitySchedule?: IActivityScheduleBase;
  items: IActivityItemBase[];
  modalities: ISpotModalityBase[];
}

export const updateBookingQueuesService = async ({
  bookingQueueId,
  activityScheduleId,
  bookedDate,
  description,
  items,
  modalities,
}: IUpdateBookingQueueProps): Promise<IUpdatedBookingQueue> => {
  const { data } = await wbsBookingsApi.put<IUpdatedBookingQueue>(
    `/booking-queues/${bookingQueueId}`,
    {
      activityScheduleId,
      bookedDate,
      description,
      items,
      modalities,
    },
  );

  return data;
};
