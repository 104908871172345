import { Box, Heading, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { maskDateTime } from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import {
  BookingQueueTable,
  IBookingQueueTableItem,
} from './components/BookingQueueTable';
import { listBookingQueuesService } from '../../../../../services/BookingQueues/ListBookingQueuesService';
import { AsyncSelectOption } from '../../../../../components/Form/AsyncSelect';
import { listActivitiesService } from '../../../../../services/Activities/ListActivitiesService';

interface ILoadBookingQueuesProps {
  activityId?: string;
  activityName?: string;
  showQueuedBookingsOnly?: boolean;
  spotName?: string;
  userDocument?: string;
  userName?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const BookingQueueList = (): JSX.Element => {
  const toast = useToast();

  const [bookingQueuesList, setBookingQueuesList] = useState<
    IBookingQueueTableItem[]
  >([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('activityId');
  const [showQueued, setShowQueued] = useState(true);
  const [sortBy, setSortBy] = useState<'bookedDate' | 'createdAt'>(
    'bookedDate',
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadBookingQueues = useCallback(
    async ({
      activityId,
      activityName,
      showQueuedBookingsOnly,
      spotName,
      userDocument,
      userName,
      order,
      page,
      sort,
    }: ILoadBookingQueuesProps) => {
      try {
        const bookingQueues = await listBookingQueuesService({
          activityId,
          activityName,
          showQueuedBookingsOnly,
          spotName,
          userDocument: userDocument?.replace(/\D/g, ''),
          userName,
          limit: 20,
          page,
          sort,
          order,
        });

        const parsedBookingQueues = bookingQueues.items.map((bookingQueue) => {
          const [bookedYear, bookedMonth, bookedDay] =
            bookingQueue.bookedDate.split('-');

          return {
            ...bookingQueue,
            formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
            formattedCreatedAt: maskDateTime(bookingQueue.createdAt),
            activitySchedule: bookingQueue.activitySchedule
              ? {
                  ...bookingQueue.activitySchedule,
                  formattedEndTime: bookingQueue.activitySchedule.endTime.slice(
                    0,
                    5,
                  ),
                  formattedStartTime:
                    bookingQueue.activitySchedule.startTime.slice(0, 5),
                }
              : undefined,
          };
        });

        setTotalPages(bookingQueues.pages);
        setBookingQueuesList(parsedBookingQueues);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das reservas, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadBookingQueues({
      ...currentFilters,
      showQueuedBookingsOnly: showQueued,
      order: currentOrdering,
      page: currentPage,
      sort: 'bookedDate',
    });
  }, [
    currentPage,
    loadBookingQueues,
    currentOrdering,
    currentFilters,
    showQueued,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleLoadActivitiesOptions = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      const { items: activities } = await listActivitiesService({
        name,
        limit: 4,
      });

      const parsedActivitiesSelectOptions: AsyncSelectOption[] = [
        ...activities.map((activity) => ({
          label: activity.name,
          value: activity.id,
        })),
      ];

      return parsedActivitiesSelectOptions;
    },
    [],
  );

  const handleSortList = useCallback((sort: 'bookedDate' | 'createdAt') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Fila de Reservas
        </Heading>

        <BookingQueueTable
          mt="4"
          bookingQueues={bookingQueuesList}
          currentPage={currentPage}
          filterBy={filterBy}
          loadAsyncOptions={handleLoadActivitiesOptions}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onFilterAsync={handleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onShowQueued={() => setShowQueued((prevState) => !prevState)}
          onSort={handleSortList}
          order={currentOrdering}
          showQueued={showQueued}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
