import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { showVenturesService } from '../../../../../services/Ventures/ShowVenturesService';
import { updateVenturesService } from '../../../../../services/Ventures/UpdateVenturesService';
import { updateVentureAvatarsService } from '../../../../../services/Ventures/UpdateVentureAvatarsService';
import deleteVentureAvatarsService from '../../../../../services/Ventures/DeleteVentureAvatarsService';
import { maskCnpj } from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import { useAuth } from '../../../../../hooks/auth';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';

type UpdateVentureFormData = {
  appstoreId?: string;
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
  playstoreId?: string;
  ref: string;
};

interface ILocationState {
  ventureId: string;
}

const updateVentureFormSchema = Yup.object().shape({
  appstoreId: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cnpj: Yup.string()
    .required('Document is required')
    .length(14, 'Invalid value, 14 digits required')
    .transform((_, originalValue) => originalValue.replace(/\D/g, '')),
  email: Yup.string()
    .email('E-mail invalid')
    .required('E-mail required')
    .transform((value) => value.toLowerCase()),
  name: Yup.string().required('Name required'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  playstoreId: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ref: Yup.string().required('Name required'),
});

export const VentureUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();

  const { state } = useLocation<ILocationState>();

  const { user } = useAuth();

  const [updatingVenture, setUpdatingVenture] =
    useState<UpdateVentureFormData>();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(updateVentureFormSchema),
  });

  const { errors } = formState;
  const { ventureId } = state;

  useEffect(() => {
    async function loadVenture(): Promise<void> {
      try {
        const ventureData = await showVenturesService(ventureId);
        setAvatarUrl(ventureData.avatarUrl || undefined);

        setUpdatingVenture(ventureData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Failed to load data',
            description:
              translateError({ message: err.response?.data.message }) ||
              'An error occurred while loading tenant data, please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadVenture();
  }, [ventureId, reset, toast]);

  useEffect(() => {
    if (updatingVenture) {
      reset({
        appstoreId: updatingVenture.appstoreId,
        cnpj: maskCnpj(updatingVenture.cnpj).toString(),
        email: updatingVenture.email,
        name: updatingVenture.name,
        bio: updatingVenture.bio,
        phone: updatingVenture.phone,
        playstoreId: updatingVenture.playstoreId,
        ref: updatingVenture.ref,
      });
    }
  }, [reset, updatingVenture]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteVentureAvatarsService(ventureId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();
  }, [ventureId, handleToggleDeleteConfirmationModal]);

  const handleUpdateVenture: SubmitHandler<UpdateVentureFormData> = useCallback(
    async (ventureData) => {
      try {
        await updateVenturesService({
          ventureId,
          ...ventureData,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateVentureAvatarsService({
            ventureId,
            avatarData: formData,
          });
        }

        toast({
          title: 'Successfully edited',
          description: 'The tenant was edited successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Failed to edit',
            description:
              translateError({ message: err.response?.data.message }) ||
              'An error occurred while editing the tenant, please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, ventureId, goBack, toast],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirm deletion"
        message="Do you really want to delete?"
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateVenture)}
      >
        <Heading size="lg" fontWeight="normal">
          Edit tenant
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          {user.featureGroup.key === 'MASTER' ? (
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="8"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 8]}>
                <MaskedInput
                  label="Name"
                  error={errors.name}
                  {...register('name')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 4]}>
                <MaskedInput
                  label="Ref"
                  textTransform="uppercase"
                  error={errors.ref}
                  {...register('ref')}
                />
              </GridItem>
            </Grid>
          ) : (
            <MaskedInput
              label="Name"
              error={errors.name}
              {...register('name')}
            />
          )}

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="E-mail"
              type="email"
              textTransform="lowercase"
              error={errors.email}
              {...register('email')}
            />

            <InternationalPhoneInput
              label="Phone"
              name="phone"
              control={control}
              error={errors.phone}
            />

            <MaskedInput
              label="Document"
              mask="cnpj"
              error={errors.cnpj}
              {...register('cnpj')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <MaskedInput
              label="Appstore Id"
              error={errors.appstoreId}
              {...register('appstoreId')}
            />

            <MaskedInput
              label="Playstore Id"
              error={errors.playstoreId}
              {...register('playstoreId')}
            />
          </SimpleGrid>

          <MaskedInput
            label="Biography"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Save
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
