import { IActivityBase } from '../../models/activities';
import { ISpotBase, ISpotModalityBase } from '../../models/spots';
import { IVentureBase } from '../../models/ventures';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IDetailedSpot extends ISpotBase {
  activities: IActivityBase[];
  modalities: ISpotModalityBase[];
  venture: IVentureBase;
}

export const showSpotsService = async (
  spotId: string,
): Promise<IDetailedSpot> => {
  const { data } = await wbsBookingsApi.get<IDetailedSpot>(`/spots/${spotId}`);

  return data;
};
