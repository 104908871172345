import { Box, Heading, useToast, Icon, HStack } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { ISpot, SpotTable } from './components/SpotTable';
import { listSpotsService } from '../../../../../services/Spots/ListSpotsService';
import { translateError } from '../../../../../utils/errors';
import { useAuth } from '../../../../../hooks/auth';

interface ILoadSpotsProps {
  isActive?: boolean;
  name?: string;
  sort?: 'name' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const SpotList = (): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [spotsList, setSpotsList] = useState<ISpot[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<'name' | 'createdAt'>('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadSpots = useCallback(
    async ({ isActive, name, order, page, sort }: ILoadSpotsProps) => {
      try {
        const spots = await listSpotsService({
          isActive,
          name: name || undefined,
          limit: 20,
          page,
          sort,
          order,
        });

        const parsedSpots = spots.items.map((spot) => ({
          ...spot,
          formattedCreatedAt: maskShortDate(spot.createdAt),
        }));

        setTotalPages(spots.pages);
        setSpotsList(parsedSpots);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados dos spots, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadSpots({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentPage, sortBy, loadSpots, currentOrdering]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'name' | 'createdAt') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <HStack justify="space-between">
          <Heading size="lg" fontWeight="normal">
            Resources
          </Heading>

          {authenticatedUser.featureGroup.features.some((feature) =>
            ['SPOT_FULL_ACCESS', 'SPOT_WRITE_ACCESS'].includes(feature.key),
          ) && (
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to="/spots/register"
            >
              Register new
            </LinkButton>
          )}
        </HStack>

        <SpotTable
          mt="4"
          spots={spotsList}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          order={currentOrdering}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
