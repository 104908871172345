import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
} from '@chakra-ui/react';
import { IWalletTransactionBase } from '../../../../../../../../../../../models/wallets';

export interface IBookingTransactionTableItem extends IWalletTransactionBase {
  formattedAmount: string;
  formattedCreatedAt: string;
}

interface IBookingTransactionTableProps extends BoxProps {
  transactions: IBookingTransactionTableItem[];
}

export const BookingTransactionTable = ({
  transactions,
  ...rest
}: IBookingTransactionTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Price</Th>

          <Th textAlign="center">Date</Th>
        </Tr>
      </Thead>

      {transactions.map((transaction) => (
        <Tbody key={transaction.id}>
          <Tr>
            <Td>
              <Text
                color={transaction.type === 'INCOME' ? 'green.500' : 'red.500'}
              >
                {transaction.formattedAmount}
              </Text>
            </Td>

            <Td textAlign="center">
              <Text>{transaction.formattedCreatedAt}</Text>
            </Td>
          </Tr>
        </Tbody>
      ))}
    </Table>
  </Box>
);
