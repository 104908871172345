import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IUserListItem } from '../../../../../../../services/Users/ListUsersService';

export interface IMember extends IUserListItem {
  formattedDate: string;
}

interface IMemberTableProps extends BoxProps {
  members: IMember[];
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt') => void;
  order?: 'ASC' | 'DESC';
  sortBy?: string;
  totalPages?: number;
}

export const MemberTable = ({
  members,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  order,
  sortBy,
  totalPages,
  ...rest
}: IMemberTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'Phone',
        value: 'phone',
      },
      {
        label: 'Document',
        value: 'cpf',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Phone',
        value: 'phone',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'CPF',
        value: 'cpf',
      },
      {
        label: 'Registration date',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={(sort) =>
          !!onSort &&
          onSort(sort as 'cpf' | 'name' | 'phone' | 'email' | 'createdAt')
        }
        order={order}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Member</Th>

            <Th>Tenant</Th>

            <Th>Phone</Th>

            <Th>E-mail</Th>

            <Th>Document</Th>

            <Th textAlign="center">Registration date</Th>

            <Th textAlign="center" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {members.map((member) => (
            <Tr key={member.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{member.name}</Text>
                  <Text textColor="gray.400">{member.featureGroup?.name}</Text>
                </Box>
              </Td>

              <Td>{member.venture?.name || '-'}</Td>

              <Td>{member.phone}</Td>

              <Td>{member.email}</Td>

              <Td>{member.cpf}</Td>

              <Td textAlign="center">{member.formattedDate}</Td>

              <Td textAlign="center">
                <LinkButton
                  px={0}
                  to={{
                    pathname: '/members/details',
                    state: {
                      memberId: member.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
