import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
  VStack,
  useToast,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router';
import { parseISO } from 'date-fns';
import StateManager, { GroupTypeBase, OptionTypeBase } from 'react-select';
import Select from 'react-select/src/Select';
import {
  IActivityItemBase,
  IActivityEventsSchedule,
} from '../../models/activities';
import { ISpotModalityBase } from '../../models/spots';
import { listActivitiesSchedulesService } from '../../services/Activities/ListActivitySchedulesService';
import { createBookingQueuesService } from '../../services/BookingQueues/CreateBookingQueuesService';
import { listUsersService } from '../../services/Users/ListUsersService';
import { translateError } from '../../utils/errors';
import { AsyncSelectOption, AsyncSelect } from '../Form/AsyncSelect';
import { DatePicker } from '../Form/DatePicker';
import { MaskedInput } from '../Form/MaskedInput';
import { ReactMultiSelect } from '../Form/ReactMultiSelect';
import { SelectOption, ReactSelect } from '../Form/ReactSelect';
import {
  IBookingQueueContextState,
  useBookingQueue,
} from '../../hooks/bookingQueue';
import { IActivityContextState } from '../../hooks/activity';
import { updateBookingQueuesService } from '../../services/BookingQueues/UpdateBookingQueuesService';
import { createBookingsService } from '../../services/Bookings/CreateBookingsService';
import { ConfirmationModal } from '../ConfirmationModal';

type NewBookingQueueFormData = {
  activityScheduleId?: string;
  bookedDate: Date;
  description?: string;
  itemsId?: string[];
  modalitiesId?: string[];
  selectedUser: SelectOption;
};

export interface INewBookingQueueData extends NewBookingQueueFormData {
  items?: IActivityItemBase[];
  modalities?: ISpotModalityBase[];
}

interface IHandleBookingQueueModalProps {
  activity: IActivityContextState;
  bookingQueue?: IBookingQueueContextState;
  isBookSubmiting?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const registerBookingFormSchema = Yup.object().shape({
  activityScheduleId: Yup.string().uuid().nullable(),
  bookedDate: Yup.date()
    .required('Required')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  itemsId: Yup.array()
    .nullable()
    .transform((_, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  modalitiesId: Yup.array()
    .nullable()
    .transform((_, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  selectedUser: Yup.object()
    .shape({
      label: Yup.string().required('Required'),
      value: Yup.string().uuid().nullable().required('Required'),
    })
    .nullable()
    .required('Required'),
});

export const HandleBookingQueueModal = ({
  activity,
  bookingQueue,
  isBookSubmiting = false,
  isOpen,
  onClose,
}: IHandleBookingQueueModalProps): JSX.Element => {
  const toast = useToast();

  const { bookingQueue: currentBookingQueue, handleBookingQueue } =
    useBookingQueue();
  const { push } = useHistory();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);
  const activityScheduleSelectRef =
    useRef<
      StateManager<
        OptionTypeBase,
        false,
        GroupTypeBase<OptionTypeBase>,
        Select<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>
      >
    >(null);

  const [includeDates, setIncludeDates] = useState<Date[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [activityScheduleSelectOptions, setActivityScheduleSelectOptions] =
    useState<SelectOption[]>([]);
  const [eventSchedules, setEventSchedules] = useState<
    IActivityEventsSchedule[]
  >([]);
  const [itemsSelectOptions, setItemsSelectOptions] = useState<SelectOption[]>(
    [],
  );
  const [modalitiesSelectOptions, setModalitiesSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const {
    register,
    formState,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(registerBookingFormSchema),
  });

  const { errors } = formState;

  const selectedBookedDate = watch('bookedDate');

  const selectedActivitySchedule = watch('activityScheduleId');

  const selectedModalities = watch('modalitiesId');

  const currentUser = watch('selectedUser');

  useEffect(() => {
    async function loadSchedules(activityId: string): Promise<void> {
      try {
        const eventsSchedulesData = await listActivitiesSchedulesService({
          activityId,
          selectedMonth,
          userId: currentUser.value,
        });

        const scheduleMonthDaysAvailable = eventsSchedulesData.map((event) => {
          const [year, month, day] = event.date.split('-');

          return new Date(+year, +month - 1, +day);
        });

        setEventSchedules(eventsSchedulesData);
        setIncludeDates(scheduleMonthDaysAvailable);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar as datas disponíveis, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (!!activity && !!currentUser) {
      loadSchedules(activity.id);
    }
  }, [activity, selectedYear, selectedMonth, toast, currentUser]);

  useEffect(() => {
    if (
      !!activity.schedules.length &&
      !!eventSchedules.length &&
      !!selectedBookedDate
    ) {
      const daySchedulesIds = eventSchedules
        .filter((eventSchedule) => {
          if (bookingQueue?.modalities.length && isBookSubmiting) {
            const modalitiesIds = bookingQueue.modalities.map(
              (modality) => modality.id,
            );

            return (
              eventSchedule.date ===
                new Date(selectedBookedDate).toLocaleDateString('fr-CA', {
                  timeZone: 'America/Sao_Paulo',
                }) &&
              (!eventSchedule.modalityId ||
                modalitiesIds.includes(eventSchedule.modalityId))
            );
          }

          return (
            eventSchedule.date ===
            new Date(selectedBookedDate).toLocaleDateString('fr-CA', {
              timeZone: 'America/Sao_Paulo',
            })
          );
        })
        .map((eventSchedule) => eventSchedule.activityScheduleId);

      setActivityScheduleSelectOptions(
        activity.schedules
          .filter(
            (activitySchedule) =>
              activitySchedule.isActive &&
              daySchedulesIds.includes(activitySchedule.id),
          )
          .map((activitySchedule) => ({
            label: `${activitySchedule.startTime.slice(
              0,
              5,
            )} - ${activitySchedule.endTime.slice(0, 5)} ${
              activitySchedule.modality
                ? ` - ${activitySchedule.modality.title}`
                : ''
            }`,
            value: activitySchedule.id,
          })),
      );
    }
  }, [
    activity.schedules,
    bookingQueue?.modalities,
    isBookSubmiting,
    eventSchedules,
    selectedBookedDate,
    selectedModalities,
  ]);

  useEffect(() => {
    if (activity.items.length) {
      setItemsSelectOptions(
        activity.items
          .filter((item) => item.isActive)
          .map((item) => ({
            label: item.name,
            value: item.id,
          })),
      );
    }
  }, [activity.items]);

  useEffect(() => {
    if (activity.spot.modalities.length) {
      setModalitiesSelectOptions(
        activity.spot.modalities
          .filter((modality) => modality.isActive)
          .map((modality) => ({
            label: modality.title,
            value: modality.id,
          })),
      );
    }
  }, [activity.spot.modalities]);

  useEffect(() => {
    if (selectedActivitySchedule) {
      setValue('modalitiesId', []);
    }
  }, [selectedActivitySchedule, setValue]);

  useEffect(() => {
    if (!bookingQueue) {
      setValue('activityScheduleId', null);

      activityScheduleSelectRef.current?.select.clearValue();

      setValue('bookedDate', null);

      setActivityScheduleSelectOptions([]);
    }
  }, [bookingQueue, currentUser, setValue]);

  useEffect(() => {
    if (!bookingQueue) {
      setValue('activityScheduleId', null);

      activityScheduleSelectRef.current?.select.clearValue();
    }
  }, [bookingQueue, selectedBookedDate, setValue]);

  const handleClearActivitySchedule = (): void => {
    setValue('activityScheduleId', null);

    activityScheduleSelectRef.current?.select.clearValue();
  };

  useEffect(() => {
    if (bookingQueue) {
      reset({
        activityScheduleId: bookingQueue.activityScheduleId,
        bookedDate: parseISO(bookingQueue.bookedDate),
        description: bookingQueue.description,
        itemsId: bookingQueue.items.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        modalitiesId: bookingQueue.modalities.map((modality) => ({
          label: modality.title,
          value: modality.id,
        })),
        selectedUser: {
          label: bookingQueue.user.name,
          value: bookingQueue.userId,
        },
      });
    }
  }, [bookingQueue, reset]);

  const handleLoadUserSelectOption = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      if (!activity) {
        return [];
      }

      const { items: users } = await listUsersService({
        name,
        featureGroups: ['MEMBER', 'DEPENDANT', 'GUEST'],
        ventureId: activity.spot.ventureId,
        limit: 4,
      });

      const parsedUsersSelectOption: AsyncSelectOption[] = [
        ...users.map((user) => ({
          label: user.name,
          value: user.id,
        })),
      ];

      return parsedUsersSelectOption;
    },
    [activity],
  );

  const handleToggleConfirmationModal = useCallback(() => {
    setIsConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleBookingConfirm = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleCloseModal = useCallback(() => {
    reset();

    setIncludeDates([]);

    setSelectedMonth(new Date().getMonth());

    setSelectedYear(new Date().getFullYear());

    setActivityScheduleSelectOptions([]);

    setEventSchedules([]);

    setItemsSelectOptions([]);

    setModalitiesSelectOptions([]);

    onClose();
  }, [onClose, reset]);

  const handleBookingQueueSubmit: SubmitHandler<NewBookingQueueFormData> =
    useCallback(
      async ({
        activityScheduleId,
        bookedDate,
        description,
        itemsId,
        modalitiesId,
        selectedUser,
      }) => {
        const selectedItems = activity.items.filter((item) =>
          itemsId?.includes(item.id),
        );

        const modalities = activity.spot.modalities.filter((modality) =>
          modalitiesId?.includes(modality.id),
        );

        if (!isBookSubmiting && !bookingQueue) {
          try {
            const bookingQueueData = await createBookingQueuesService({
              activityId: activity.id,
              activityScheduleId,
              bookedDate,
              description,
              items: selectedItems,
              modalities,
              userId: selectedUser.value,
            });

            toast({
              title: 'Cadastrado com sucesso',
              description: 'A reserva foi cadastrada corretamente na fila.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            push('/booking-queues/details', {
              bookingQueueId: bookingQueueData.id,
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha no cadastro',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao cadastrar a reserva na fila, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        } else if (!isBookSubmiting && !!bookingQueue) {
          try {
            const bookingQueueData = await updateBookingQueuesService({
              activityScheduleId,
              bookingQueueId: bookingQueue.id,
              bookedDate,
              description,
              items: selectedItems,
              modalities,
            });

            toast({
              title: 'Atualizado com sucesso',
              description: 'A reserva foi atualizada corretamente na fila.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            if (currentBookingQueue) {
              handleBookingQueue({
                ...currentBookingQueue,
                ...bookingQueueData,
              });
            }

            handleCloseModal();
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha no cadastro',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao atualizar a reserva na fila, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }

        if (!!isBookSubmiting && !activityScheduleId) {
          setError('activityScheduleId', {
            message: 'Required',
          });

          return;
        }

        if (!!isBookSubmiting && !!bookingQueue && !!activityScheduleId) {
          try {
            const booking = await createBookingsService({
              activityScheduleId,
              bookedDate,
              bookingQueueId: bookingQueue.id,
              description,
              items: selectedItems,
              isVerified,
              sendCreateNotification: true,
              skipLimits: true,
              userId: bookingQueue.userId,
            });

            toast({
              title: 'Cadastrado com sucesso',
              description: 'A reserva foi cadastrada corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            setIsVerified(false);
            setIsConfirmationModalVisible(false);

            push('/bookings/details', {
              bookingId: booking.id,
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              if (err.response?.data.message === 'spot-already-booked') {
                setIsConfirmationModalVisible(true);

                return;
              }

              if (err.response?.data.message === 'wallet-not-found') {
                toast({
                  title: 'Carteira faltando',
                  description:
                    'Member não possui carteira cadastrada, confira o pagamento da reserva.',
                  status: 'warning',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });

                toast({
                  title: 'Cadastrado com sucesso',
                  description: 'A reserva foi cadastrada corretamente.',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
                push('/bookings/details', {
                  bookingId: err.response?.data.metaData.bookingId,
                });

                return;
              }

              if (err.response?.data.message === 'wallet-without-credits') {
                toast({
                  title: 'Carteira sem crédito',
                  description:
                    'Carteira sem créditos, confira o pagamento da reserva.',
                  status: 'warning',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });

                toast({
                  title: 'Cadastrado com sucesso',
                  description: 'A reserva foi cadastrada corretamente.',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });

                push('/bookings/details', {
                  bookingId: err.response?.data.metaData.bookingId,
                });

                return;
              }

              toast({
                title: 'Falha no cadastro',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao cadastrar a reserva, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }
      },
      [
        activity.items,
        activity.spot.modalities,
        activity.id,
        isBookSubmiting,
        bookingQueue,
        toast,
        push,
        currentBookingQueue,
        handleCloseModal,
        handleBookingQueue,
        setError,
        isVerified,
      ],
    );

  const handleMonthChange = useCallback((date: Date) => {
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  }, []);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ConfirmationModal
        isOpen={isConfirmationModalVisible}
        onClose={handleToggleConfirmationModal}
        onConfirm={handleBookingConfirm}
        message="There are already pending bookings for this member at this location. Do you want to create a new booking?"
        title="Booking confirmation"
      />

      <ModalOverlay />

      <ModalContent
        as="form"
        ref={formRef}
        onSubmit={handleSubmit(handleBookingQueueSubmit)}
      >
        <ModalHeader>
          {!!isBookSubmiting && (
            <Heading size="lg" fontWeight="normal">
              Register booking
            </Heading>
          )}

          {!isBookSubmiting && !bookingQueue ? (
            <Heading size="lg" fontWeight="normal">
              Register waitlist booking
            </Heading>
          ) : (
            !isBookSubmiting && (
              <Heading size="lg" fontWeight="normal">
                Edit booking in waitlist
              </Heading>
            )
          )}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            <AsyncSelect
              isDisabled={!!bookingQueue}
              label="Member"
              name="selectedUser"
              loadOptions={handleLoadUserSelectOption}
              control={control}
              error={errors.selectedUser}
            />

            <DatePicker
              label="Date"
              includeDates={includeDates}
              isClearable={false}
              isDisabled={!includeDates.length || !!isBookSubmiting}
              onMonthChange={handleMonthChange}
              onFocus={handleClearActivitySchedule}
              control={control}
              error={errors.bookedDate}
              {...register('bookedDate')}
            />

            <ReactSelect
              ref={activityScheduleSelectRef}
              label="Horário"
              name="activityScheduleId"
              isClearable
              isDisabled={
                !currentUser ||
                !selectedBookedDate ||
                (!!isBookSubmiting && !!bookingQueue?.activityScheduleId)
              }
              options={activityScheduleSelectOptions}
              control={control}
              error={errors.activityScheduleId}
            />

            {activity.items.length && (
              <ReactMultiSelect
                label="Itens"
                name="itemsId"
                options={itemsSelectOptions}
                control={control}
                error={errors.itemsId}
              />
            )}

            {activity.spot.modalities.length && !selectedActivitySchedule && (
              <ReactMultiSelect
                label="Modalidades"
                name="modalitiesId"
                isDisabled={!!isBookSubmiting}
                options={modalitiesSelectOptions}
                control={control}
                error={errors.modalitiesId}
              />
            )}

            <MaskedInput
              label="Description"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.description}
              {...register('description')}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
