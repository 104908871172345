import { IUserBase } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

const setUsersDefaultPasswordService = async (
  userId: string,
): Promise<IUserBase> => {
  const { data } = await wbsBookingsApi.post<IUserBase>(
    `/users/${userId}/default-password`,
  );

  return data;
};

export default setUsersDefaultPasswordService;
