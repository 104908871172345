import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Flex,
  Button,
  Text,
  useToast,
  VStack,
  ButtonGroup,
  Icon,
  Link as ChakraLink,
} from '@chakra-ui/react';
import qs from 'query-string';

import axios from 'axios';
import { RiGooglePlayLine, RiAppStoreLine } from 'react-icons/ri';
import { MaskedInput } from '../../../components/Form/MaskedInput';
import { translateError } from '../../../utils/errors';
import confirmUsersSignupService from '../../../services/Users/ConfirmUsersSignupService';

interface IAppData {
  appstoreId: string;
  playstoreId: string;
}

type SignupFormData = {
  password: string;
  passwordConfirmation: string;
};

const resetPasswordFormSchema = Yup.object().shape({
  password: Yup.string().required('Password requerida'),
  passwordConfirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'Passwords não coincidem',
  ),
});

export const Signup = (): JSX.Element => {
  const { search } = useLocation();
  const toast = useToast();
  const { push } = useHistory();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const [appData, setAppData] = useState<IAppData>();

  const token = useMemo(() => qs.parse(search).token?.toString(), [search]);

  const loginPermissions = useMemo(
    () => qs.parse(search).features?.toString().split(',') || [],
    [search],
  );
  // const ventureRef = useMemo(
  //   () => qs.parse(search).ventureref?.toString(),
  //   [search],
  // );

  const { errors } = formState;

  // useEffect(() => {
  //   if (
  //     isMobile &&
  //     ventureRef &&
  //     groupKey &&
  //     token &&
  //     ['MEMBER', 'GUEST'].includes(groupKey)
  //   ) {
  //     window.location.replace(
  //       `${ventureRef.toLowerCase()}://${ventureRef.toLowerCase()}/reset-password?token=${token}`,
  //     );
  //   }
  // }, [groupKey, token, ventureRef]);

  const handleSignup: SubmitHandler<SignupFormData> = useCallback(
    async ({ password, passwordConfirmation }) => {
      if (token) {
        try {
          const user = await confirmUsersSignupService({
            password,
            passwordConfirmation,
            token,
          });

          toast({
            title: 'Password definida com sucesso',
            description: 'Sua Password foi definida corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          if (
            user.venture?.ref &&
            user.featureGroup?.features.some((feature) =>
              ['APP_CONCIERGE_ACCESS', 'APP_MEMBERS_ACCESS'].includes(
                feature.key,
              ),
            )
          ) {
            push({
              pathname: '/signup/confirm',
              state: {
                appstoreId: user.venture.appstoreId,
                playstoreId: user.venture.playstoreId,
                loginPermissions,
              },
            });
          } else {
            // if user isnt an app user redirect to login
            push('/');
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            if (
              err.response?.data.message === 'user-account-already-confirmed'
            ) {
              setAppData(err.response?.data.metaData);

              return;
            }

            toast({
              title: 'Falha ao confirmar Password',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao tentar confirmar a Password, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [loginPermissions, push, toast, token],
  );

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={360}
        minH={400}
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleSignup)}
      >
        <Text fontSize="1.75rem" fontWeight="bold" letterSpacing="tight">
          WE
          <Text as="span" ml="1" color="blue.500">
            BOOK
          </Text>
          SPACE
        </Text>

        {!appData ? (
          <>
            <VStack spacing="8" mt="10">
              <MaskedInput
                id="password"
                type="password"
                label="Password"
                error={errors.password}
                {...register('password')}
              />

              <MaskedInput
                id="passwordConfirmation"
                type="password"
                label="Confirmação de Password"
                error={errors.passwordConfirmation}
                {...register('passwordConfirmation')}
              />
            </VStack>

            <Button
              type="submit"
              isLoading={formState.isSubmitting}
              mt="8"
              colorScheme="green"
              size="lg"
            >
              Send
            </Button>
          </>
        ) : (
          <Flex
            mt="8"
            flexDirection="column"
            justifyContent="space-between"
            flex={1}
          >
            <Text fontSize="lg">Convite já utilizado.</Text>

            <Text>
              A conta desse convite já foi confirmada, faça login ou redefina a
              Password pelo app.
            </Text>

            <ButtonGroup mt="4" justifyContent="space-between">
              <ChakraLink
                href={`https://play.google.com/store/apps/details?id=${appData.playstoreId}`}
                isExternal
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  size="md"
                  fontSize="md"
                  fontWeight="normal"
                  colorScheme="gray"
                  leftIcon={<Icon as={RiGooglePlayLine} fontSize="24" />}
                >
                  Playstore
                </Button>
              </ChakraLink>

              <ChakraLink
                href={`https://apps.apple.com/br/app/${appData.appstoreId}`}
                isExternal
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  size="md"
                  fontSize="md"
                  fontWeight="normal"
                  colorScheme="gray"
                  leftIcon={<Icon as={RiAppStoreLine} fontSize="24" />}
                >
                  Applestore
                </Button>
              </ChakraLink>
            </ButtonGroup>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
