import wbsBookingsApi from '../apis/wbsBookingsApi';
import { IActivityItemBase } from '../../models/activities';

interface IUpdateActivityItemProps {
  activityItemId: string;
  description?: string;
  isActive?: boolean;
  isVerified?: boolean;
  name: string;
  // quantityAvailable: number;
}

export type IUpdatedActivityItem = IActivityItemBase;

export const updateActivityItemsService = async ({
  activityItemId,
  description,
  isActive,
  isVerified,
  name,
}: // quantityAvailable,
IUpdateActivityItemProps): Promise<IUpdatedActivityItem> => {
  const { data } = await wbsBookingsApi.put<IUpdatedActivityItem>(
    `/activities/items/${activityItemId}`,
    {
      description,
      isActive,
      name,
      // quantityAvailable,
    },
    { params: { isVerified } },
  );

  return data;
};
