import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Flex,
  Button,
  Text,
  Link as ChakraLink,
  useToast,
  Box,
} from '@chakra-ui/react';

import axios from 'axios';
import { MaskedInput } from '../../../components/Form/MaskedInput';
import forgotPasswordsService from '../../../services/Auth/ForgotPasswordsService';
import { translateError } from '../../../utils/errors';

type ForgotPasswordFormData = {
  email: string;
};

const forgotPasswordFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail Required')
    .transform((value) => value.toLowerCase()),
});

export const ForgotPassword = (): JSX.Element => {
  const toast = useToast();
  const { push } = useHistory();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const { errors } = formState;

  const handleForgotPassword: SubmitHandler<ForgotPasswordFormData> =
    useCallback(
      async ({ email }) => {
        try {
          await forgotPasswordsService(email);

          toast({
            title: 'Recuperação de Password solicitada com sucesso',
            description:
              'Foi requisitada uma recuperação de Password, verifique sua caixa de e-mail.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          push('/');
        } catch (err) {
          if (axios.isAxiosError(err)) {
            toast({
              title: 'Falha ao recuperar Password',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao enviar e-mail de recuperação de Password, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [push, toast],
    );

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={360}
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleForgotPassword)}
      >
        <Text fontSize="1.75rem" fontWeight="bold" letterSpacing="tight">
          WE
          <Text as="span" ml="1" color="blue.500">
            BOOK
          </Text>
          SPACE
        </Text>

        <Box mt="10">
          <MaskedInput
            label="E-mail"
            type="email"
            textTransform="lowercase"
            error={errors.email}
            {...register('email')}
          />
        </Box>

        <Button
          type="submit"
          isLoading={formState.isSubmitting}
          mt="8"
          colorScheme="green"
          size="lg"
        >
          Send
        </Button>

        <ChakraLink as={Link} to="/" mt="2" mx="auto">
          Back to login
        </ChakraLink>
      </Flex>
    </Flex>
  );
};
