import { IFeatureBase } from '../../models/features';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IFeatureListItem extends IFeatureBase {
  featureGroupsCount: number;
}

export interface IFeatureList {
  items: IFeatureListItem[];
  pages: number;
  total: number;
}

interface IListFeaturesProps {
  search?: string;
  limit?: number;
  page?: number;
}

export const listFeaturesService = async (
  filters: IListFeaturesProps,
): Promise<IFeatureList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          search: filters.search || undefined,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await wbsBookingsApi.get<IFeatureList>('/features', {
    params,
  });

  return data;
};
