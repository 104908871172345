import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
  VStack,
  Switch,
  Box,
  FormLabel,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { MaskedInput } from '../../../../../../../../../../../../../components/Form/MaskedInput';
import { translateError } from '../../../../../../../../../../../../../utils/errors';
import { useSpot } from '../../../../../../../../../../../../../hooks/spot';
import { ISpotModalityBase } from '../../../../../../../../../../../../../models/spots';
import { createSpotModalitiesService } from '../../../../../../../../../../../../../services/Spots/CreateSpotModalitiesService';
import { updateSpotModalitiesService } from '../../../../../../../../../../../../../services/Spots/UpdateSpotModalitiesService';
import { ConfirmationModal } from '../../../../../../../../../../../../../components/ConfirmationModal';

interface IHandleSpotModalityModalFormData {
  description?: string;
  title: string;
}

interface IHandleSpotModalityModalProps {
  modality?: ISpotModalityBase;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const spotModalityFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  title: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Required'),
});

export const HandleSpotModalityModal = ({
  modality,
  isOpen,
  onClose,
  onSave,
}: IHandleSpotModalityModalProps): JSX.Element => {
  const toast = useToast();
  const { spot, handleSpot } = useSpot();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);

  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(spotModalityFormSchema),
  });

  const { errors } = formState;

  const [isActive, setIsActive] = useState(true);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    reset({
      description: modality?.description,
      title: modality?.title,
    });

    setIsActive(modality ? modality.isActive : true);
  }, [modality, reset]);

  const handleToggleIsActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked);
    },
    [],
  );

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleSpotModalitySubmit: SubmitHandler<IHandleSpotModalityModalFormData> =
    useCallback(
      async ({ description, title }) => {
        if (spot) {
          if (!modality) {
            try {
              const newSpotModality = await createSpotModalitiesService({
                spotId: spot.id,
                description,
                isActive,
                title,
              });

              const modalities = [...spot.modalities, newSpotModality];

              handleSpot({
                ...spot,
                modalities,
              });

              reset({});
              setIsActive(true);

              onSave();

              toast({
                title: 'Cadastrado com sucesso',
                description: 'Modalidade cadastrada corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                toast({
                  title: 'Falha ao cadastrar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao cadastrar a modalidade, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          } else {
            try {
              const updatedModality = await updateSpotModalitiesService({
                modalityId: modality.id,
                description,
                isActive,
                isVerified,
                title,
              });

              handleSpot({
                ...spot,
                modalities: spot.modalities.map((spotModality) =>
                  spotModality.id === updatedModality.id
                    ? { ...spotModality, ...updatedModality }
                    : spotModality,
                ),
              });

              reset({});
              setIsActive(true);
              setIsVerified(false);
              setIsVerifyConfirmationModalVisible(false);

              onSave();

              toast({
                title: 'Atualizado com sucesso',
                description: 'Modalidade atualizada corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                if (err.response?.data.message === 'has-bookings-pending') {
                  handleToggleVerifyConfirmationModal();

                  return;
                }

                toast({
                  title: 'Falha ao atualizar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao atualizar a modalidade, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          }
        }
      },
      [
        handleSpot,
        handleToggleVerifyConfirmationModal,
        isActive,
        isVerified,
        modality,
        onSave,
        reset,
        spot,
        toast,
      ],
    );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleCloseModal = useCallback(() => {
    reset({});
    setIsActive(true);
    setIsVerified(false);

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <ModalOverlay />

      <ModalContent
        ref={formRef}
        as="form"
        onSubmit={handleSubmit(handleSpotModalitySubmit)}
      >
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Modalidade
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            <MaskedInput
              label="Título"
              error={errors.title}
              {...register('title')}
            />

            <MaskedInput
              label="Description"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.description}
              {...register('description')}
            />

            <Box>
              <FormLabel htmlFor="isActive">Active</FormLabel>
              <Switch
                name="isActive"
                id="isActive"
                isChecked={isActive}
                onChange={handleToggleIsActive}
              />
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
