import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
  ButtonGroup,
  Button,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { useSpot } from '../../../../../../../../../../../hooks/spot';
import { ISpotModalityBase } from '../../../../../../../../../../../models/spots';
import deleteSpotModalitiesService from '../../../../../../../../../../../services/Spots/DeleteSpotModalitiesService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { HandleSpotModalityModal } from './components/HandleSpotModalityModal';

export type ISpotModality = ISpotModalityBase;

export const SpotModalityTable = (props: BoxProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const { spot, handleSpot } = useSpot();

  const [handlingSpotModality, setHandlingSpotModality] =
    useState<ISpotModalityBase>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isHandleSpotModalityModalVisible,
    setIsHandleSpotModalityModalVisible,
  ] = useState(false);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleSpotModalityModal = useCallback(() => {
    setIsHandleSpotModalityModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleSpotModality = useCallback(
    (spotModality: ISpotModalityBase, action: 'delete' | 'update') => {
      setHandlingSpotModality(spotModality);

      switch (action) {
        case 'delete':
          handleToggleDeleteConfirmationModal();

          break;

        case 'update':
          handleToggleHandleSpotModalityModal();

          break;

        default:
          break;
      }
    },
    [handleToggleDeleteConfirmationModal, handleToggleHandleSpotModalityModal],
  );

  const handleDeleteSpotModality = useCallback(
    async (modalityId: string) => {
      if (spot) {
        try {
          await deleteSpotModalitiesService({
            modalityId,
            isVerified,
          });

          setHandlingSpotModality(undefined);
          setIsVerified(false);
          setIsVerifyConfirmationModalVisible(false);

          handleSpot({
            ...spot,
            modalities: spot.modalities.filter(
              (spotModality) => spotModality.id !== modalityId,
            ),
          });

          toast({
            title: 'Excluído com sucesso',
            description: 'A modalidade foi excluída corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (err.response?.data.message === 'has-bookings-pending') {
              handleToggleDeleteConfirmationModal();
              handleToggleVerifyConfirmationModal();

              return;
            }

            toast({
              title: 'Falha ao Delete',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao Delete a modalidade, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [
      handleSpot,
      handleToggleDeleteConfirmationModal,
      handleToggleVerifyConfirmationModal,
      isVerified,
      spot,
      toast,
    ],
  );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (handlingSpotModality && isVerified) {
      handleDeleteSpotModality(handlingSpotModality.id);
    }
  }, [handleDeleteSpotModality, handlingSpotModality, isVerified]);

  return (
    <Box {...props}>
      {handlingSpotModality && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={() => {
            setHandlingSpotModality(undefined);
            handleToggleDeleteConfirmationModal();
          }}
          onConfirm={() => handleDeleteSpotModality(handlingSpotModality.id)}
          title="Confirmar exclusão"
          message="Deseja realmente Delete?"
        />
      )}

      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={() => {
          setHandlingSpotModality(undefined);
          handleToggleVerifyConfirmationModal();
        }}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <HandleSpotModalityModal
        modality={handlingSpotModality}
        isOpen={isHandleSpotModalityModalVisible}
        onClose={() => {
          setHandlingSpotModality(undefined);
          handleToggleHandleSpotModalityModal();
        }}
        onSave={() => {
          setHandlingSpotModality(undefined);
          handleToggleHandleSpotModalityModal();
        }}
      />

      {authenticatedUser.featureGroup.features.some((feature) =>
        ['SPOT_MODALITY_FULL_ACCESS', 'SPOT_MODALITY_WRITE_ACCESS'].includes(
          feature.key,
        ),
      ) && (
        <ButtonGroup width="100%" justifyContent="flex-end">
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<Icon as={RiAddLine} fontSize="16" />}
            onClick={() => {
              setHandlingSpotModality(undefined);
              handleToggleHandleSpotModalityModal();
            }}
          >
            Nova modalidade
          </Button>
        </ButtonGroup>
      )}

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Título</Th>
            <Th>Description</Th>
            <Th textAlign="center">Active</Th>
            <Th textAlign="center" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {spot?.modalities.map((spotModality) => (
            <Tr key={spotModality.id}>
              <Td>{spotModality.title}</Td>

              <Td>{spotModality.description}</Td>

              <Td textAlign="center">
                {spotModality.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td textAlign="center">
                <ButtonGroup>
                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'SPOT_MODALITY_FULL_ACCESS',
                      'SPOT_MODALITY_WRITE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Edit"
                      size="sm"
                      icon={<Icon as={RiEditLine} />}
                      fontSize="16"
                      color="white"
                      colorScheme="yellow"
                      onClick={() => handleSpotModality(spotModality, 'update')}
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'SPOT_MODALITY_FULL_ACCESS',
                      'SPOT_MODALITY_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<Icon as={RiDeleteBinLine} />}
                      fontSize="16"
                      colorScheme="red"
                      onClick={() => handleSpotModality(spotModality, 'delete')}
                    />
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
