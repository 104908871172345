import { INotificationBase } from '../../models/notifications';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateNotificationsProps {
  isUnread?: boolean;
  notificationId: string;
}

export const updateNotifications = async ({
  isUnread,
  notificationId,
}: IUpdateNotificationsProps): Promise<INotificationBase> => {
  const { data } = await wbsBookingsApi.patch<INotificationBase>(
    `/notifications/${notificationId}`,
    {
      isUnread,
    },
  );

  return data;
};
