import {
  Box,
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { UserCategoryTable } from './components/UserCategoryTable';
import { VentureSpotTable } from './components/VentureSpotTable';

export const VentureDetailsTabs = (props: BoxProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <Box {...props}>
      <Tabs
        index={tabIndex}
        onChange={handleTabChanges}
        variant="enclosed-colored"
      >
        <TabList>
          <Tab>Resouces</Tab>

          <Tab>Categorias de usuário</Tab>
        </TabList>

        <TabPanels mt="4">
          <TabPanel>
            <VentureSpotTable />
          </TabPanel>

          <TabPanel>
            <UserCategoryTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
