import { IActivityBase } from '../../models/activities';
import { IVentureBase } from '../../models/ventures';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateVentureAvatarsProps {
  ventureId: string;
  avatarData: FormData;
}

export interface IUpdatedVentureAvatar extends IVentureBase {
  activities?: IActivityBase[];
}

export const updateVentureAvatarsService = async ({
  avatarData,
  ventureId,
}: IUpdateVentureAvatarsProps): Promise<IUpdatedVentureAvatar> => {
  const { data } = await wbsBookingsApi.patch<IUpdatedVentureAvatar>(
    `/avatars/ventures/${ventureId}`,
    avatarData,
  );

  return data;
};
