import { ISpotBase } from '../../models/spots';
import { IUserCategoryBase, IVentureBase } from '../../models/ventures';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export interface IDetailedVenture extends IVentureBase {
  spots: ISpotBase[];
  userCategories: IUserCategoryBase[];
}

export const showVenturesService = async (
  ventureId: string,
): Promise<IDetailedVenture> => {
  const { data } = await wbsBookingsApi.get<IDetailedVenture>(
    `/ventures/${ventureId}`,
  );

  return data;
};
