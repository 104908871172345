import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
  ButtonGroup,
  Button,
  HStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
  RiLink,
} from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import { HandleBookingQueueModal } from '../../../../../../../../../../../components/HandleBookingQueueModal';
import { useActivity } from '../../../../../../../../../../../hooks/activity';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { UserExperience } from '../../../../../../../../../../../models/users';
import deleteActivitySchedulesService from '../../../../../../../../../../../services/Activities/DeleteActivitySchedulesService';
import { IDetailedActivitySchedule } from '../../../../../../../../../../../services/Activities/ShowActivitiesService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { maskMoney } from '../../../../../../../../../../../utils/formatters/handleMask';
import { BookingRegisterModal } from './components/BookingRegisterModal';
import { HandleActivityScheduleModal } from './components/HandleActivityScheduleModal';

export type IActivitySchedule = IDetailedActivitySchedule;

export const ActivitySchedules = (props: BoxProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const { activity, handleActivity } = useActivity();

  const [handlingActivitySchedule, setHandlingActivitySchedule] =
    useState<IDetailedActivitySchedule>();
  const [isBookingRegisterModalVisible, setIsBookingRegisterModalVisible] =
    useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isHandleActivityScheduleModalVisible,
    setIsHandleActivityScheduleModalVisible,
  ] = useState(false);
  const [
    isHandleBookingQueueModalVisible,
    setIsHandleBookingQueueModalVisible,
  ] = useState(false);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleToggleBookingRegisterModal = useCallback(() => {
    setIsBookingRegisterModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleActivityScheduleModal = useCallback(() => {
    setIsHandleActivityScheduleModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleBookingQueueModal = useCallback(() => {
    setIsHandleBookingQueueModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleActivitySchedule = useCallback(
    (
      activitySchedule: IDetailedActivitySchedule,
      action: 'book' | 'delete' | 'update',
    ) => {
      setHandlingActivitySchedule(activitySchedule);

      switch (action) {
        case 'book':
          handleToggleBookingRegisterModal();

          break;

        case 'delete':
          handleToggleDeleteConfirmationModal();

          break;

        case 'update':
          handleToggleHandleActivityScheduleModal();

          break;

        default:
          break;
      }
    },
    [
      handleToggleBookingRegisterModal,
      handleToggleDeleteConfirmationModal,
      handleToggleHandleActivityScheduleModal,
    ],
  );

  const handleDeleteActivitySchedule = useCallback(
    async (activityScheduleId: string) => {
      if (activity) {
        try {
          await deleteActivitySchedulesService({
            activityScheduleId,
            isVerified,
          });

          setHandlingActivitySchedule(undefined);
          setIsVerified(false);
          setIsVerifyConfirmationModalVisible(false);

          handleActivity({
            ...activity,
            schedules: activity.schedules.filter(
              (schedule) => schedule.id !== activityScheduleId,
            ),
          });

          toast({
            title: 'Excluído com sucesso',
            description: 'O evento foi excluído corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (err.response?.data.message === 'has-bookings-pending') {
              handleToggleDeleteConfirmationModal();
              handleToggleVerifyConfirmationModal();

              return;
            }

            toast({
              title: 'Falha ao Delete',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao Delete o evento, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [
      activity,
      handleActivity,
      handleToggleDeleteConfirmationModal,
      handleToggleVerifyConfirmationModal,
      isVerified,
      toast,
    ],
  );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (handlingActivitySchedule && isVerified) {
      handleDeleteActivitySchedule(handlingActivitySchedule.id);
    }
  }, [handleDeleteActivitySchedule, handlingActivitySchedule, isVerified]);

  const handleCopyBookingUrl = useCallback(
    async (bookingUrl: string) => {
      try {
        await navigator.clipboard.writeText(bookingUrl);

        toast({
          title: 'Copiado com sucesso',
          description: 'O link foi copiado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao copiar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao copiar o link, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  return (
    <Box {...props}>
      {handlingActivitySchedule && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            handleToggleDeleteConfirmationModal();
          }}
          onConfirm={() =>
            handleDeleteActivitySchedule(handlingActivitySchedule.id)
          }
          title="Confirmar exclusão"
          message="Deseja realmente Delete?"
        />
      )}

      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <HandleActivityScheduleModal
        activitySchedule={handlingActivitySchedule}
        isOpen={isHandleActivityScheduleModalVisible}
        onClose={() => {
          setHandlingActivitySchedule(undefined);
          handleToggleHandleActivityScheduleModal();
        }}
        onSave={() => {
          setHandlingActivitySchedule(undefined);
          handleToggleHandleActivityScheduleModal();
        }}
      />

      {!!activity && (
        <HandleBookingQueueModal
          activity={activity}
          isOpen={isHandleBookingQueueModalVisible}
          onClose={handleToggleHandleBookingQueueModal}
        />
      )}

      {!!handlingActivitySchedule && (
        <BookingRegisterModal
          activityScheduleId={handlingActivitySchedule.id}
          isOpen={isBookingRegisterModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            handleToggleBookingRegisterModal();
          }}
        />
      )}

      <ButtonGroup justifyContent="flex-end" w="100%">
        {/* {authenticatedUser.featureGroup.features.some((feature) =>
          ['BOOKING_QUEUE_FULL_ACCESS', 'BOOKING_QUEUE_WRITE_ACCESS'].includes(
            feature.key,
          ),
        ) && (
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<Icon as={RiAddLine} fontSize="16" />}
            onClick={handleToggleHandleBookingQueueModal}
          >
            Reserva na fila
          </Button>
        )} */}

        {authenticatedUser.featureGroup.features.some((feature) =>
          [
            'ACTIVITY_SCHEDULE_FULL_ACCESS',
            'ACTIVITY_SCHEDULE_WRITE_ACCESS',
          ].includes(feature.key),
        ) && (
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<Icon as={RiAddLine} fontSize="16" />}
            onClick={handleToggleHandleActivityScheduleModal}
          >
            New Booking
          </Button>
        )}
      </ButtonGroup>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th textAlign="center">Active</Th>
            <Th>Start Date</Th>
            <Th>End Date</Th>
            <Th>Week Days</Th>
            <Th>Start Time</Th>
            <Th>End Time</Th>
            <Th textAlign="center">Total Slots</Th>
            <Th>Mode</Th>
            <Th>Min. Experience</Th>
            <Th>Booking Price</Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {activity?.schedules.map((activitySchedule) => (
            <Tr key={activitySchedule.id}>
              <Td textAlign="center">
                {activitySchedule.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td>{activitySchedule.formattedStartDate}</Td>

              <Td>{activitySchedule.formattedEndDate}</Td>

              <Td>
                <HStack>
                  {activitySchedule.daysOfWeek.map((weekDay) => (
                    <Text key={String(weekDay)}>
                      {['S', 'M', 'T', 'W', 'T', 'F', 'S'][weekDay]}
                    </Text>
                  ))}
                </HStack>
              </Td>

              <Td>{activitySchedule.formattedStartTime}</Td>

              <Td>{activitySchedule.formattedEndTime}</Td>

              <Td textAlign="center">{activitySchedule.totalVacancies}</Td>

              <Td>{activitySchedule.modality?.title || '-'}</Td>

              <Td>{UserExperience[activitySchedule.minUserExperience]}</Td>

              <Td>
                {activitySchedule.bookingPrice
                  ? maskMoney(activitySchedule.bookingPrice)
                  : '-'}
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  {authenticatedUser.featureGroup.features.some((feature) =>
                    ['BOOKING_FULL_ACCESS', 'BOOKING_WRITE_ACCESS'].includes(
                      feature.key,
                    ),
                  ) &&
                    !activitySchedule.isExpired && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="green"
                        onClick={() =>
                          handleActivitySchedule(activitySchedule, 'book')
                        }
                      >
                        Book
                      </Button>
                    )}

                  {!activitySchedule.isExpired &&
                    activitySchedule.allowLinkBookings && (
                      <IconButton
                        aria-label="Copy booking link"
                        size="sm"
                        icon={<Icon as={RiLink} />}
                        fontSize="16"
                        colorScheme="blue"
                        onClick={() =>
                          handleCopyBookingUrl(activitySchedule.bookingUrl)
                        }
                      />
                    )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_WRITE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Edit"
                      size="sm"
                      icon={<Icon as={RiEditLine} />}
                      fontSize="16"
                      color="white"
                      colorScheme="yellow"
                      onClick={() =>
                        handleActivitySchedule(activitySchedule, 'update')
                      }
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<Icon as={RiDeleteBinLine} />}
                      fontSize="16"
                      colorScheme="red"
                      onClick={() =>
                        handleActivitySchedule(activitySchedule, 'delete')
                      }
                    />
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
