import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IBookingBase } from '../../models/bookings';
import { MaskedInput } from '../Form/MaskedInput';

interface IHandleCheckinModalFormData {
  identifyBraceletNumber: string;
}

interface IHandleCheckinModalProps {
  booking: IBookingBase;
  isOpen: boolean;
  onClose: () => void;
  onSave: (booking: IBookingBase) => void;
}

const checkinFormSchema = Yup.object().shape({
  identifyBraceletNumber: Yup.string()
    .nullable()
    .required('Número da pulseira Required'),
});

export const HandleCheckinModal = ({
  booking,
  isOpen,
  onClose,
  onSave,
}: IHandleCheckinModalProps): JSX.Element => {
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(checkinFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    reset({
      identifyBraceletNumber: booking.identifyBraceletNumber,
    });
  }, [booking.identifyBraceletNumber, reset]);

  const handleCheckinSubmit: SubmitHandler<IHandleCheckinModalFormData> =
    useCallback(
      async ({ identifyBraceletNumber }) => {
        reset({});

        onSave({ ...booking, identifyBraceletNumber });
      },
      [booking, onSave, reset],
    );

  const handleCloseModal = useCallback(() => {
    reset({});

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleCheckinSubmit)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Checkin
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <MaskedInput
            label="Pulseira de identificação"
            error={errors.identifyBraceletNumber}
            {...register('identifyBraceletNumber')}
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
