import wbsBookingsApi from '../apis/wbsBookingsApi';
import { IActivityScheduleBase, WaveSideEnum } from '../../models/activities';
import { UserExperience } from '../../models/users';

interface IUpdateActivityScheduleBlocks {
  blockedDate: string;
}

interface IUpdateActivityScheduleProps {
  activityScheduleId: string;
  allowLinkBookings?: boolean;
  allowPartialTimeBookings?: boolean;
  bookingPrice?: number;
  daysOfWeek: Array<number>;
  endDate?: string | null;
  endTime: string;
  isActive?: boolean;
  isVerified?: boolean;
  minUserExperience: UserExperience;
  modalityId?: string | null;
  scheduleBlocks: IUpdateActivityScheduleBlocks[];
  startDate: string;
  startTime: string;
  totalVacancies: number;
  waveSide?: WaveSideEnum;
}

export type IUpdatedActivitySchedule = IActivityScheduleBase;

export const updateActivitySchedulesService = async ({
  activityScheduleId,
  allowLinkBookings,
  allowPartialTimeBookings,
  bookingPrice,
  daysOfWeek,
  endDate,
  endTime,
  isActive,
  isVerified,
  minUserExperience,
  modalityId,
  scheduleBlocks,
  startDate,
  startTime,
  totalVacancies,
  waveSide,
}: IUpdateActivityScheduleProps): Promise<IUpdatedActivitySchedule> => {
  const { data } = await wbsBookingsApi.put<IUpdatedActivitySchedule>(
    `/activities/schedules/${activityScheduleId}`,
    {
      allowLinkBookings,
      allowPartialTimeBookings,
      bookingPrice,
      daysOfWeek,
      endDate,
      endTime,
      isActive,
      minUserExperience,
      modalityId,
      scheduleBlocks,
      startDate,
      startTime,
      totalVacancies,
      waveSide,
    },
    { params: { isVerified } },
  );

  return data;
};
