import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/en-gb';
import './styles.css';
import { Box, useToast } from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import { lastDayOfWeek, subDays } from 'date-fns';
import BookingsModal from './components/BookingsModal';

import { useActivity } from '../../../../../../../../../../../hooks/activity';
import { IActivityEventsSchedule } from '../../../../../../../../../../../models/activities';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { EventComponent } from './components/EventComponent';
import { ToolbarComponent } from './components/ToolbarComponent';
import { listActivitiesSchedulesService } from '../../../../../../../../../../../services/Activities/ListActivitySchedulesService';

const localizer = momentLocalizer(moment);

export interface ICalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  schedules: IActivityEventsSchedule[];
}

export const ActivityCalendar: React.FC = () => {
  const toast = useToast();
  const { activity } = useActivity();
  const [loading, setLoading] = useState(false);

  const [schedulesEventsList, setSchedulesEventsList] = useState<
    ICalendarEvent[]
  >([]);
  const [startDate, setStartDate] = useState(
    subDays(lastDayOfWeek(new Date()), 6),
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule] =
    useState<ICalendarEvent | null>(null);

  const loadSchedules = useCallback(async () => {
    if (activity) {
      setLoading(true);

      try {
        const events = await listActivitiesSchedulesService({
          activityId: activity.id,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          addDay: 6,
        });

        const eventsParsed = events.reduce((parsedEvents, event) => {
          const eventId = event.date + event.startTime + event.endTime;

          const eventIndex = parsedEvents.findIndex(
            (schedule) => schedule.id === eventId,
          );

          if (eventIndex >= 0) {
            const eventsAux = parsedEvents;

            const eventSchedules = [
              ...parsedEvents[eventIndex].schedules,
              event,
            ];

            eventsAux[eventIndex] = {
              ...parsedEvents[eventIndex],
              schedules: eventSchedules,
              title: `${eventSchedules.reduce(
                (vacancies, e) => vacancies + e.vacancies - e.booked,
                0,
              )} Slot(s)`,
            };

            return eventsAux;
          }

          return [
            ...parsedEvents,
            {
              id: eventId,
              end: new Date(`${event.date} ${event.endTime}`),
              start: new Date(`${event.date} ${event.startTime}`),
              schedules: [event],
              title: `${event.vacancies - event.booked} Slot(s)`,
            },
          ];
        }, [] as ICalendarEvent[]);

        setSchedulesEventsList(eventsParsed);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os eventos, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        setLoading(false);
      }
    }
  }, [activity, toast, startDate]);

  const handleSelectEvent = useCallback((event: ICalendarEvent) => {
    setSelectedSchedule(event);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setSelectedSchedule(null);
  }, []);

  useEffect(() => {
    loadSchedules();
  }, [loadSchedules]);

  return (
    <Box>
      <BookingsModal
        event={selectedSchedule}
        isOpen={openModal}
        onClose={handleCloseModal}
      />

      <Calendar
        localizer={localizer}
        components={{
          toolbar: (e) => ToolbarComponent({ ...e, loading }),
          event: (e) => EventComponent(e),
        }}
        views={['week']}
        defaultView="week"
        events={schedulesEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ flex: 1, height: 600 }}
        messages={{
          next: 'Next',
          previous: 'Previous',
          today: 'Today',
          month: 'Month',
          week: 'Week',
          day: 'Day',
          agenda: 'Agenda',
          date: 'Date',
          time: 'Time',
          event: 'Event',
          allDay: 'All Day',
          tomorrow: 'Tomorrow',
          yesterday: 'Yesterday',
          work_week: 'Work Week',
          showMore: (total) => `+${total}`,
        }}
        onSelectEvent={(event) => handleSelectEvent(event)}
        onRangeChange={(range) => {
          const rangeDate = range as Date[];
          setStartDate(rangeDate[0]);
        }}
        culture="en-US"
      />
    </Box>
  );
};
