import wbsBookingsApi from '../apis/wbsBookingsApi';
import { IWalletBase, IWalletTransactionBase } from '../../models/wallets';

export interface IDetailedWallet extends IWalletBase {
  transactions: IWalletTransactionBase[];
}

export const showWalletsService = async (
  userId: string,
): Promise<IDetailedWallet | undefined> => {
  const { data } = await wbsBookingsApi.get<IDetailedWallet | undefined>(
    '/wallets',
    { params: { userId } },
  );

  return data;
};
