import { IBookingBase } from '../../models/bookings';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateBookingProps {
  bookingId: string;
  checkinAt?: Date;
  checkoutAt?: Date;
  identifyBraceletNumber?: string;
}

export type IUpdatedBooking = IBookingBase;

export const updateBookingsCheckinService = async ({
  bookingId,
  checkinAt,
  checkoutAt,
  identifyBraceletNumber,
}: IUpdateBookingProps): Promise<IUpdatedBooking> => {
  const { data } = await wbsBookingsApi.patch<IUpdatedBooking>(
    `/bookings/${bookingId}/checkin`,
    {
      checkinAt,
      checkoutAt,
      identifyBraceletNumber,
    },
  );

  return data;
};
