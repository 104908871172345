import { IUserBase, UserExperience } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export type IUserGuestListItem = IUserBase;

export interface IUserGuestList {
  items: IUserGuestListItem[];
  pages: number;
  total: number;
}

interface IListUserGuestProps {
  cpf?: string;
  email?: string;
  groupKey: 'GUEST' | 'DEPENDANT';
  hostId: string;
  minExperience?: UserExperience;
  name?: string;
  phone?: string;
  sort?: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const listUserGuestsService = async ({
  cpf,
  email,
  groupKey,
  hostId,
  minExperience,
  name,
  phone,
  sort,
  order,
  limit,
  page = 1,
}: IListUserGuestProps): Promise<IUserGuestList> => {
  const params = {
    cpf: cpf || undefined,
    email: email || undefined,
    groupKey,
    minExperience: minExperience || undefined,
    name: name || undefined,
    phone: phone || undefined,
    sort,
    order,
    limit,
    page,
  };

  const { data } = await wbsBookingsApi.get<IUserGuestList>(
    `/users/${hostId}/guests`,
    {
      params,
    },
  );

  return data;
};
