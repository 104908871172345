import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import { IVentureBase } from '../../models/ventures';
import { IUserBase } from '../../models/users';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface IUpdateUserAvatarProps {
  userId: string;
  avatarData: FormData;
}

export interface IUpdateUserAvatarDetailedFeatureGroup
  extends IFeatureGroupBase {
  features: IFeatureBase[];
}

export interface IUpdatedUserAvatar extends IUserBase {
  venture?: IVentureBase;
  featureGroup: IUpdateUserAvatarDetailedFeatureGroup;
}

export const updateUserAvatarsService = async ({
  avatarData,
  userId,
}: IUpdateUserAvatarProps): Promise<IUpdatedUserAvatar> => {
  const { data } = await wbsBookingsApi.patch<IUpdatedUserAvatar>(
    `/avatars/users/${userId}`,
    avatarData,
  );

  return data;
};
