import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../../../../../../../components/LinkButton';
import {
  IWalletTransactionBase,
  WalletTransactionType,
} from '../../../../../../../../../../../../../models/wallets';

export interface IWalletTransactionTableItem extends IWalletTransactionBase {
  formattedAmount: string;
  formattedCreatedAt: string;
}

interface IWalletTransactionTableProps extends BoxProps {
  transactions: IWalletTransactionTableItem[];
}

export const WalletTransactionTable = ({
  transactions,
  ...rest
}: IWalletTransactionTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Transaction</Th>

          <Th>Price</Th>

          <Th>Type</Th>

          <Th textAlign="center">Date</Th>

          <Th textAlign="right">Booking details</Th>
        </Tr>
      </Thead>

      {transactions.map((transaction) => (
        <Tbody key={transaction.id}>
          <Tr>
            <Td>
              <Box>
                <Text fontWeight="bold">{transaction.title}</Text>
                <Text fontSize="sm" color="gray.500">
                  {transaction.bookingDateTime}
                </Text>
              </Box>
            </Td>

            <Td>
              <Text
                color={transaction.type === 'INCOME' ? 'green.500' : 'red.500'}
              >
                {transaction.formattedAmount}
              </Text>
            </Td>

            <Td>{WalletTransactionType[transaction.type]}</Td>

            <Td textAlign="center">
              <Text>{transaction.formattedCreatedAt.split('-')[0]}</Text>
              <Text>{transaction.formattedCreatedAt.split('-')[1]}</Text>
            </Td>

            <Td textAlign="right">
              {!!transaction.bookingId && (
                <LinkButton
                  px={0}
                  to={{
                    pathname: '/bookings/details',
                    state: {
                      bookingId: transaction.bookingId,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              )}
            </Td>
          </Tr>
        </Tbody>
      ))}
    </Table>
  </Box>
);
