import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import wbsBookingsApi from '../apis/wbsBookingsApi';

interface ICreateFeatureGroupProps {
  key: string;
  authLevel: number;
  features: IFeatureBase[];
  name: string;
}

export type INewFeatureGroup = IFeatureGroupBase;

export const createFeatureGroupsService = async ({
  authLevel,
  key,
  features,
  name,
}: ICreateFeatureGroupProps): Promise<INewFeatureGroup> => {
  const { data } = await wbsBookingsApi.post<INewFeatureGroup>(
    '/feature-groups',
    {
      authLevel,
      key,
      features,
      name,
    },
  );

  return data;
};
