const AppErrorMessages = [
  {
    message: 'activity-schedule-conflict',
    ptBr: 'Já existe evento nesse horário para a mesma modalidade e nível de experiência.',
  },
  {
    message: 'activity-not-found',
    ptBr: 'Atividade não encontrada.',
  },
  {
    message: 'cnpj-already-in-use',
    ptBr: 'CNPJ is already in use.',
  },
  {
    message: 'email-already-in-use',
    ptBr: 'E-mail is already in use.',
  },
  {
    message: 'insufficient-privilege',
    ptBr: 'Unauthorized.',
  },
  {
    message: 'schedule-already-booked',
    ptBr: 'Já existe reserva ativa para essa atividade.',
  },
  {
    message: 'spot-already-booked',
    ptBr: 'Cliente já existe reserva ativa para esse local.',
  },
  {
    message: 'venture-not-found',
    ptBr: 'Tenant not found.',
  },
  {
    message: 'venture-ref-already-in-use',
    ptBr: 'Ref is already in use.',
  },
  {
    message: 'cpf-already-in-use',
    ptBr: 'Document is already in use.',
  },
  {
    message: 'feature-key-already-registered',
    ptBr: 'Chave is already in use.',
  },
  {
    message: 'feature-name-already-registered',
    ptBr: 'Name is already in use.',
  },
  {
    message: 'user-not-found',
    ptBr: 'User not found.',
  },
  {
    message: 'user-max-day-bookings',
    ptBr: 'Limite de reservas diárias atingido.',
  },
  {
    message: 'user-guest-max-day-bookings',
    ptBr: 'Limite de reservas diárias de convidados atingido.',
  },
  {
    message: 'past-start-time',
    ptBr: 'Hora inicial passada.',
  },
  {
    message: 'overlapping-time-range',
    ptBr: 'Intervalo de horário sobrepondo outra reserva.',
  },
  {
    message: 'booking-no-vacancies-available',
    ptBr: 'Não há vagas disponíveis.',
  },
  {
    message: 'booking-time-range-invalid',
    ptBr: 'Intervalo de horário fora dos limites do evento.',
  },
  {
    message: 'invalid-amount',
    ptBr: 'Valor inválido, confira o saldo.',
  },
  {
    message: 'wallet-not-found',
    ptBr: 'Member não possui carteira cadastrada.',
  },
  {
    message: 'wallet-without-credits',
    ptBr: 'Carteira sem créditos.',
  },
];

interface ITRanslateErrorProps {
  message: string;
  language?: 'ptBr';
}

export const translateError = ({
  message,
  language = 'ptBr',
}: ITRanslateErrorProps): string | undefined =>
  AppErrorMessages.find((errorMessage) => errorMessage.message === message)?.[
    language
  ];
