import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
  VStack,
  useToast,
  HStack,
  Text,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router';
import moment from 'moment';
import { DatePicker } from '../../../../../../../../../../../../../components/Form/DatePicker';
import { MaskedInput } from '../../../../../../../../../../../../../components/Form/MaskedInput';
import {
  AsyncSelect,
  AsyncSelectOption,
} from '../../../../../../../../../../../../../components/Form/AsyncSelect';
import { listUsersService } from '../../../../../../../../../../../../../services/Users/ListUsersService';
import { IActivityItemBase } from '../../../../../../../../../../../../../models/activities';
import { useActivity } from '../../../../../../../../../../../../../hooks/activity';
import { translateError } from '../../../../../../../../../../../../../utils/errors';
import { listActivitiesSchedulesService } from '../../../../../../../../../../../../../services/Activities/ListActivitySchedulesService';
import { ReactMultiSelect } from '../../../../../../../../../../../../../components/Form/ReactMultiSelect';
import { SelectOption } from '../../../../../../../../../../../../../components/Form/ReactSelect';
import { createBookingsService } from '../../../../../../../../../../../../../services/Bookings/CreateBookingsService';
import { ConfirmationModal } from '../../../../../../../../../../../../../components/ConfirmationModal';
import {
  createBookingQueuesService,
  ICreateBookingQueueProps,
} from '../../../../../../../../../../../../../services/BookingQueues/CreateBookingQueuesService';

type NewBookingFormData = {
  bookedDate: Date;
  description?: string;
  endTime?: string;
  itemsId?: string[];
  startTime?: string;
  userId: string;
};

interface IBookingQueueData {
  errorMessage:
    | 'booking-no-vacancies-available'
    | 'user-max-day-bookings'
    | 'user-guest-max-day-bookings';
  queueData: ICreateBookingQueueProps;
}

export interface INewBookingData extends NewBookingFormData {
  items?: IActivityItemBase[];
}

enum ErrorMessage {
  'booking-no-vacancies-available' = 'There are no available slots. Would you like to add a reservation to the waiting list?',
  'user-max-day-bookings' = 'Reservation limit reached. Would you like to add a reservation to the waiting list?',
  'user-guest-max-day-bookings' = 'Guest reservation limit reached. Would you like to add a reservation to the waiting list?',
}

interface IBookingRegisterModalProps {
  activityScheduleId: string;
  isOpen: boolean;
  onClose: () => void;
}

const registerBookingFormSchema = Yup.object().shape({
  bookedDate: Yup.date()
    .required('Required')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  endTime: Yup.string().when('startTime', {
    is: (val?: string) => !!val,
    then: Yup.string()
      .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
      .nullable()
      .test(
        'is-greater',
        'Horário final deve ser maior que o inicial',
        function isSameOrAfter(value) {
          return moment(value, 'HH:mm').isSameOrAfter(
            moment(this.parent.startTime, 'HH:mm'),
          );
        },
      )
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  itemsId: Yup.array()
    .nullable()
    .transform((value, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  startTime: Yup.string()
    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Horário inválido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  userId: Yup.string()
    .uuid()
    .required('Required')
    .transform((value) => value.value),
});

export const BookingRegisterModal = ({
  activityScheduleId,
  isOpen,
  onClose,
}: IBookingRegisterModalProps): JSX.Element => {
  const { activity } = useActivity();
  const toast = useToast();

  const { push } = useHistory();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);

  const [includeDates, setIncludeDates] = useState<Date[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [itemsSelectOptions, setItemsSelectOptions] = useState<SelectOption[]>(
    [],
  );
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [bookingQueueData, setBookingQueueData] =
    useState<IBookingQueueData | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  const { register, formState, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(registerBookingFormSchema),
  });

  const { errors } = formState;

  const activitySchedule = useMemo(
    () =>
      activity?.schedules.find(
        (schedule) => schedule.id === activityScheduleId,
      ),
    [activity?.schedules, activityScheduleId],
  );

  useEffect(() => {
    async function loadSchedules(activityId: string): Promise<void> {
      if (activityScheduleId) {
        try {
          const eventsSchedule = await listActivitiesSchedulesService({
            activityId,
            activityScheduleId,
            selectedMonth,
          });

          const scheduleMonthDaysAvailable = eventsSchedule.map((event) => {
            const [year, month, day] = event.date.split('-');

            return new Date(+year, +month - 1, +day);
          });

          setIncludeDates(scheduleMonthDaysAvailable);
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao carregar dados',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao carregar as datas disponíveis, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    }

    if (activity) {
      loadSchedules(activity.id);
    }
  }, [activity, selectedYear, selectedMonth, toast, activityScheduleId]);

  useEffect(() => {
    if (activity?.items.length) {
      setItemsSelectOptions(
        activity.items
          .filter((item) => item.isActive)
          .map((item) => ({
            label: item.name,
            value: item.id,
          })),
      );
    }
  }, [activity?.items]);

  const handleLoadUserSelectOption = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      if (!activity) {
        return [];
      }

      const { items: users } = await listUsersService({
        name,
        featureGroups: ['MEMBER', 'GUEST', 'DEPENDANT'],
        minExperience: activitySchedule?.minUserExperience,
        ventureId: activity.spot.ventureId,
        limit: 4,
      });

      const parsedUsersSelectOption: AsyncSelectOption[] = [
        ...users.map((user) => ({
          label: user.name,
          value: user.id,
        })),
      ];

      return parsedUsersSelectOption;
    },
    [activity, activitySchedule?.minUserExperience],
  );

  const handleToggleConfirmationModal = useCallback(() => {
    setIsConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleBookingConfirm = useCallback(() => {
    setIsVerified(true);
  }, []);

  const handleBookingQueueConfirm = useCallback(async () => {
    if (!bookingQueueData) {
      return;
    }

    try {
      const bookingQueue = await createBookingQueuesService(
        bookingQueueData.queueData,
      );

      toast({
        title: 'Cadastrado com sucesso',
        description: 'A reserva foi cadastrada corretamente na fila.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      push('/booking-queues/details', {
        bookingQueueId: bookingQueue.id,
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha no cadastro',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao cadastrar a reserva na fila, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [bookingQueueData, push, toast]);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleBookingSubmit: SubmitHandler<NewBookingFormData> = useCallback(
    async ({
      bookedDate,
      description,
      endTime,
      itemsId,
      startTime,
      userId,
    }) => {
      if (activity) {
        const selectedItems = activity.items.filter((item) =>
          itemsId?.includes(item.id),
        );

        const bookedDateString = bookedDate.toLocaleDateString('fr-CA', {
          timeZone: 'America/Sao_Paulo',
        });

        try {
          const booking = await createBookingsService({
            activityScheduleId,
            bookedDate,
            description,
            endTime: new Date(`${bookedDateString}T${endTime}:00.000Z`),
            items: selectedItems,
            isVerified,
            startTime: new Date(`${bookedDateString}T${startTime}:00.000Z`),
            userId,
          });

          toast({
            title: 'Cadastrado com sucesso',
            description: 'A reserva foi cadastrada corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          setIsVerified(false);

          setIsConfirmationModalVisible(false);

          push('/bookings/details', {
            bookingId: booking.id,
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (err.response?.data.message === 'spot-already-booked') {
              setIsConfirmationModalVisible(true);

              return;
            }

            if (
              err.response?.data.message &&
              [
                'booking-no-vacancies-available',
                'user-max-day-bookings',
                'user-guest-max-day-bookings',
              ].includes(err.response.data.message)
            ) {
              setBookingQueueData({
                errorMessage: err.response.data.message,
                queueData: {
                  activityId: activity.id,
                  activityScheduleId,
                  bookedDate,
                  description,
                  items: selectedItems,
                  modalities: activitySchedule?.modality
                    ? [activitySchedule?.modality]
                    : undefined,
                  userId,
                },
              });

              return;
            }

            if (err.response?.data.message === 'wallet-not-found') {
              toast({
                title: 'Carteira faltando',
                description:
                  'Member não possui carteira cadastrada, confira o pagamento da reserva.',
                status: 'warning',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });

              toast({
                title: 'Cadastrado com sucesso',
                description: 'A reserva foi cadastrada corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
              push('/bookings/details', {
                bookingId: err.response?.data.metaData.bookingId,
              });

              return;
            }

            if (err.response?.data.message === 'wallet-without-credits') {
              toast({
                title: 'Carteira sem crédito',
                description:
                  'Carteira sem créditos, confira o pagamento da reserva.',
                status: 'warning',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });

              toast({
                title: 'Cadastrado com sucesso',
                description: 'A reserva foi cadastrada corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });

              push('/bookings/details', {
                bookingId: err.response?.data.metaData.bookingId,
              });

              return;
            }

            toast({
              title: 'Falha no cadastro',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao cadastrar a reserva, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [
      activity,
      activitySchedule?.modality,
      activityScheduleId,
      isVerified,
      push,
      toast,
    ],
  );

  const handleMonthChange = useCallback((date: Date) => {
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  }, []);

  const handleCloseModal = useCallback(() => {
    reset();

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ConfirmationModal
        isOpen={isConfirmationModalVisible}
        onClose={handleToggleConfirmationModal}
        onConfirm={handleBookingConfirm}
        message="Já existe reservas pendentes para esse Member nesse local, deseja
        criar nova reserva?"
        title="Confirmação de reserva"
      />

      {!!bookingQueueData && (
        <ConfirmationModal
          isOpen={!!bookingQueueData}
          onClose={() => setBookingQueueData(null)}
          onConfirm={handleBookingQueueConfirm}
          message={ErrorMessage[bookingQueueData.errorMessage]}
          title="Confirmação de reserva na fila"
        />
      )}

      <ModalOverlay />

      <ModalContent
        as="form"
        ref={formRef}
        onSubmit={handleSubmit(handleBookingSubmit)}
      >
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Register booking
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            {!!activitySchedule?.modality && (
              <Heading size="md" fontWeight="normal" color="blue.500">
                <HStack>
                  <Text>Resource:</Text>

                  <Text>{activitySchedule.modality.title}</Text>
                </HStack>
              </Heading>
            )}

            <DatePicker
              label="Data"
              includeDates={includeDates}
              isClearable={false}
              onMonthChange={handleMonthChange}
              control={control}
              error={errors.bookedDate}
              {...register('bookedDate')}
            />

            <AsyncSelect
              label="Member"
              name="userId"
              loadOptions={handleLoadUserSelectOption}
              control={control}
              error={errors.userId}
            />

            {!!activitySchedule?.allowPartialTimeBookings && (
              <HStack spacing="8">
                <MaskedInput
                  label="Horário início"
                  mask="time"
                  error={errors.startTime}
                  {...register('startTime')}
                />

                <MaskedInput
                  label="Horário fim"
                  mask="time"
                  error={errors.endTime}
                  {...register('endTime')}
                />
              </HStack>
            )}

            {!!activity?.items.length && (
              <ReactMultiSelect
                label="Itens"
                name="itemsId"
                options={itemsSelectOptions}
                control={control}
                error={errors.itemsId}
              />
            )}

            <MaskedInput
              label="Description"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.description}
              {...register('description')}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
