import { IUserCategoryBase } from '../../models/ventures';
import wbsBookingsApi from '../apis/wbsBookingsApi';

export const listUserCategoriesService = async (
  ventureId: string,
): Promise<IUserCategoryBase[]> => {
  const { data } = await wbsBookingsApi.get<IUserCategoryBase[]>(
    `/user-categories/ventures/${ventureId}`,
  );

  return data;
};
