import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import { translateError } from '../../../../../utils/errors';
import { listFeatureGroupsService } from '../../../../../services/FeatureGroups/ListFeatureGroupsService';
import { validateCpf } from '../../../../../utils/validateCPF';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { useAuth } from '../../../../../hooks/auth';

type NewUserFormData = {
  bio?: string;
  cpf?: string;
  email: string;
  name: string;
  phone?: string;
  featureGroupId: string;
  ventureId: string;
};

const registerUserFormSchema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cpf: Yup.string()
    .length(11, 'Valor inválido, Required 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail Required')
    .transform((value) => value.toLowerCase()),
  featureGroupId: Yup.string().uuid().required('Required'),
  name: Yup.string().required('Name Required'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ventureId: Yup.string().required('Required'),
});

export const UserRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const { user } = useAuth();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [featureGroupsSelectOptions, setFeatureGroupsSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(registerUserFormSchema),
  });

  const { errors } = formState;

  const selectedFeatureGroupId = watch('featureGroupId');

  const selectedFeatureGroupName = useMemo(
    () =>
      featureGroupsSelectOptions?.find(
        (featureGroup) => featureGroup.value === selectedFeatureGroupId,
      )?.label,
    [featureGroupsSelectOptions, selectedFeatureGroupId],
  );

  useEffect(() => {
    async function loadFeatureGroups(): Promise<void> {
      const { items: featureGroups } = await listFeatureGroupsService({});

      const parsedFeatureGroupsSelectOptions: SelectOption[] = [
        ...featureGroups
          .filter(
            (featureGroup) =>
              featureGroup.authLevel >= user.featureGroup.authLevel &&
              featureGroup.key !== 'APP_STORE_USER' &&
              featureGroup.key !== 'MEMBER' &&
              featureGroup.key !== 'GUEST',
          )
          .map((featureGroup) => ({
            label: featureGroup.name,
            value: featureGroup.id,
          })),
      ];

      setFeatureGroupsSelectOptions(parsedFeatureGroupsSelectOptions);
    }

    loadFeatureGroups();
  }, [user.featureGroup.authLevel]);

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();

      setVenturesSelectOptions([
        { label: 'Todos', value: ' ' },
        ...ventures.map((venture) => ({
          label: venture.name,
          value: venture.id,
        })),
      ]);
    }

    if (!user.ventureId) {
      loadVentures();
    } else {
      setValue('ventureId', user.ventureId);
    }
  }, [setValue, user.ventureId]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewUser: SubmitHandler<NewUserFormData> = useCallback(
    async ({ bio, cpf, email, name, phone, featureGroupId, ventureId }) => {
      const cleanVentureId = ventureId.replace(/\s/g, '');

      if (selectedFeatureGroupName !== 'Master' && !cleanVentureId) {
        setError('ventureId', {
          message: 'Tenant is required',
        });

        return;
      }

      try {
        const newUser = await createUsersService({
          bio,
          cpf,
          email,
          name,
          phone,
          featureGroupId,
          ventureId: cleanVentureId || null,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateUserAvatarsService({
            avatarData: formData,
            userId: newUser.id,
          });
        }

        toast({
          title: 'Successfully registered',
          description: 'The employee was registered successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/users/details', { userId: newUser.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'An error occurred while registering the tenant user, please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, selectedFeatureGroupName, setError, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Create tenant user
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Full name"
            error={errors.name}
            {...register('name')}
          />

          {!user.ventureId ? (
            <>
              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <MaskedInput
                  label="Document"
                  mask="cpf"
                  error={errors.cpf}
                  {...register('cpf')}
                />

                <MaskedInput
                  label="E-mail"
                  type="email"
                  textTransform="lowercase"
                  error={errors.email}
                  {...register('email')}
                />

                <InternationalPhoneInput
                  label="Phone"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />
              </SimpleGrid>

              <Grid
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(1, 1fr)',
                  'repeat(12, 1fr)',
                ]}
                gap="8"
                width="100%"
              >
                <GridItem colSpan={[12, 12, 4]}>
                  <ReactSelect
                    label="Tipo"
                    name="featureGroupId"
                    options={featureGroupsSelectOptions}
                    control={control}
                    error={errors.featureGroupId}
                  />
                </GridItem>

                <GridItem colSpan={[12, 12, 8]}>
                  <ReactSelect
                    label="Tenant"
                    name="ventureId"
                    options={venturesSelectOptions}
                    control={control}
                    error={errors.ventureId}
                  />
                </GridItem>
              </Grid>
            </>
          ) : (
            <>
              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <MaskedInput
                  label="Document"
                  mask="cpf"
                  error={errors.cpf}
                  {...register('cpf')}
                />

                <MaskedInput
                  label="E-mail"
                  type="email"
                  textTransform="lowercase"
                  error={errors.email}
                  {...register('email')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <InternationalPhoneInput
                  label="Phone"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />

                <ReactSelect
                  label="Tipo"
                  name="featureGroupId"
                  options={featureGroupsSelectOptions}
                  control={control}
                  error={errors.featureGroupId}
                />
              </SimpleGrid>
            </>
          )}

          <MaskedInput
            label="Biography"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
