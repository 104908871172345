import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { useAuth } from '../../../../../hooks/auth';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import deleteUserAvatarsService from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { maskCpf } from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import { listFeatureGroupsService } from '../../../../../services/FeatureGroups/ListFeatureGroupsService';
import { validateCpf } from '../../../../../utils/validateCPF';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';

type UpdateUserFormData = {
  bio?: string;
  cpf?: string;
  email: string;
  name: string;
  phone?: string;
  featureGroupId: string;
  ventureId: string;
};

interface ILocationState {
  userId: string;
}

const userUpdateFormSchema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cpf: Yup.string()
    .length(11, 'Valor inválido, Required 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail Required')
    .transform((value) => value.toLowerCase()),
  featureGroupId: Yup.string().uuid().required('Required'),
  name: Yup.string().required('Name Required'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ventureId: Yup.string().required('Required'),
});

export const UserUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { updateUser, user } = useAuth();

  const { state } = useLocation<ILocationState>();

  const [updatingUser, setUpdatingUser] = useState<IDetailedUser>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [featureGroupsSelectOptions, setFeatureGroupsSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const { register, handleSubmit, formState, reset, control, watch, setError } =
    useForm({
      resolver: yupResolver(userUpdateFormSchema),
    });

  const { errors } = formState;
  const { userId } = state;

  const selectedFeatureGroupId = watch('featureGroupId');

  const selectedFeatureGroupName = useMemo(
    () =>
      featureGroupsSelectOptions?.find(
        (featureGroup) => featureGroup.value === selectedFeatureGroupId,
      )?.label,
    [featureGroupsSelectOptions, selectedFeatureGroupId],
  );

  useEffect(() => {
    async function loadUser(): Promise<void> {
      try {
        const userData = await showUsersService(userId);
        setAvatarUrl(userData.avatarUrl || undefined);

        setUpdatingUser(userData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Failed to load data',
            description:
              translateError({ message: err.response?.data.message }) ||
              'An error occurred while loading employee data, please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadUser();
  }, [userId, reset, toast]);

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();

      setVenturesSelectOptions([
        { label: 'Todos', value: ' ' },
        ...ventures.map((venture) => ({
          label: venture.name,
          value: venture.id,
        })),
      ]);
    }

    loadVentures();
  }, []);

  useEffect(() => {
    if (updatingUser) {
      reset({
        bio: updatingUser.bio,
        cpf: updatingUser.cpf ? maskCpf(updatingUser.cpf) : undefined,
        email: updatingUser.email,
        name: updatingUser.name,
        phone: updatingUser.phone,
        featureGroupId: updatingUser.featureGroupId,
        ventureId: updatingUser.venture?.id || ' ',
      });
    }
  }, [reset, updatingUser]);

  useEffect(() => {
    async function loadFeatureGroups(): Promise<void> {
      const { items: featureGroups } = await listFeatureGroupsService({});

      const parsedFeatureGroupsSelectOptions: SelectOption[] = [
        ...featureGroups
          .filter(
            (featureGroup) =>
              featureGroup.authLevel >= user.featureGroup.authLevel &&
              featureGroup.key !== 'APP_STORE_USER' &&
              featureGroup.key !== 'MEMBER' &&
              featureGroup.key !== 'GUEST',
          )
          .map((featureGroup) => ({
            label: featureGroup.name,
            value: featureGroup.id,
          })),
      ];

      setFeatureGroupsSelectOptions(parsedFeatureGroupsSelectOptions);
    }

    loadFeatureGroups();
  }, [user.featureGroup.authLevel]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(userId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    if (userId === user.id) {
      delete user.avatar;
      delete user.avatarUrl;

      updateUser(user);
    }
  }, [userId, handleToggleDeleteConfirmationModal, updateUser, user]);

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = useCallback(
    async ({ bio, cpf, email, name, phone, featureGroupId, ventureId }) => {
      const cleanVentureId = ventureId.replace(/\s/g, '');

      if (selectedFeatureGroupName !== 'Master' && !cleanVentureId) {
        setError('ventureId', {
          message: 'Tenant is required',
        });

        return;
      }

      try {
        const updatedUser = await updateUsersService({
          userId,
          bio,
          cpf,
          email,
          name,
          phone,
          featureGroupId,
          ventureId: cleanVentureId || null,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const userWithUpdatedAvatar = await updateUserAvatarsService({
            userId,
            avatarData: formData,
          });

          if (userWithUpdatedAvatar.id === user.id) {
            updateUser(userWithUpdatedAvatar);
          }
        } else if (updatedUser.id === user.id) {
          updateUser(updatedUser);
        }

        toast({
          title: 'Successfully edited',
          description: 'The employee was edited successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Failed to edit',
            description:
              translateError({ message: err.response?.data.message }) ||
              'An error occurred while editing the employee, please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [
      selectedFeatureGroupName,
      setError,
      userId,
      avatar,
      user.id,
      toast,
      goBack,
      updateUser,
    ],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente Delete?"
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateUser)}
      >
        <Heading size="lg" fontWeight="normal">
          Edit user
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Full name"
            error={errors.name}
            {...register('name')}
          />

          {!user.ventureId ? (
            <>
              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <MaskedInput
                  label="Document"
                  mask="cpf"
                  error={errors.cpf}
                  {...register('cpf')}
                />

                <MaskedInput
                  label="E-mail"
                  type="email"
                  textTransform="lowercase"
                  error={errors.email}
                  {...register('email')}
                />

                <InternationalPhoneInput
                  label="Phone"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />
              </SimpleGrid>

              <Grid
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(1, 1fr)',
                  'repeat(12, 1fr)',
                ]}
                gap="8"
                width="100%"
              >
                <GridItem colSpan={[12, 12, 4]}>
                  <ReactSelect
                    label="Tipo"
                    name="featureGroupId"
                    options={featureGroupsSelectOptions}
                    control={control}
                    error={errors.featureGroupId}
                  />
                </GridItem>

                <GridItem colSpan={[12, 12, 8]}>
                  <ReactSelect
                    label="Tenant"
                    name="ventureId"
                    options={venturesSelectOptions}
                    control={control}
                    error={errors.ventureId}
                  />
                </GridItem>
              </Grid>
            </>
          ) : (
            <>
              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <MaskedInput
                  label="Document"
                  mask="cpf"
                  error={errors.cpf}
                  {...register('cpf')}
                />

                <MaskedInput
                  label="E-mail"
                  type="email"
                  textTransform="lowercase"
                  error={errors.email}
                  {...register('email')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
                <InternationalPhoneInput
                  label="Phone"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />

                <ReactSelect
                  label="Tipo"
                  name="featureGroupId"
                  options={featureGroupsSelectOptions}
                  control={control}
                  error={errors.featureGroupId}
                />
              </SimpleGrid>
            </>
          )}
          <MaskedInput
            label="Biography"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
