import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { useAuth } from '../../../../../hooks/auth';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import deleteUserAvatarsService from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { maskCpf } from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import { validateCpf } from '../../../../../utils/validateCPF';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import { UserExperience } from '../../../../../models/users';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';

type UpdateGuestFormData = {
  bio?: string;
  cpf?: string;
  email: string;
  experience: UserExperience;
  healthCheck?: Date;
  name: string;
  phone?: string;
};

interface ILocationState {
  guestId: string;
}

const guestUpdateFormSchema = Yup.object().shape({
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cpf: Yup.string()
    .length(11, 'Valor inválido, Required 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail Required')
    .transform((value) => value.toLowerCase()),
  experience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 4 e 5'],
      UserExperience['Nível 5 e 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  healthCheck: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string().required('Name Required'),
  phone: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const GuestUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { updateUser, user } = useAuth();

  const { state } = useLocation<ILocationState>();

  const [updatingGuest, setUpdatingGuest] = useState<IDetailedUser>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(guestUpdateFormSchema),
  });

  const { errors } = formState;
  const { guestId } = state;

  const experienceSelectOptions = useMemo(
    () => [
      {
        label: 'Nível 1 e 2',
        value: UserExperience['Nível 1 e 2'],
      },
      {
        label: 'Nível 3 e 4',
        value: UserExperience['Nível 3 e 4'],
      },
      {
        label: 'Nível 4 e 5',
        value: UserExperience['Nível 4 e 5'],
      },
      {
        label: 'Nível 5 e 6',
        value: UserExperience['Nível 5 e 6'],
      },
      {
        label: 'Nível 6 e 7',
        value: UserExperience['Nível 6 e 7'],
      },
      {
        label: 'Nível 7',
        value: UserExperience['Nível 7'],
      },
      {
        label: 'Especial',
        value: UserExperience.Especial,
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadGuest(): Promise<void> {
      try {
        const guestData = await showUsersService(guestId);
        setAvatarUrl(guestData.avatarUrl || undefined);

        setUpdatingGuest(guestData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados do convidado, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadGuest();
  }, [guestId, reset, toast]);

  useEffect(() => {
    if (updatingGuest) {
      reset({
        bio: updatingGuest.bio,
        cpf: updatingGuest.cpf ? maskCpf(updatingGuest.cpf) : undefined,
        email: updatingGuest.email,
        experience: updatingGuest.experience,
        healthCheck: updatingGuest.healthCheck
          ? new Date(updatingGuest.healthCheck)
          : undefined,
        name: updatingGuest.name,
        phone: updatingGuest.phone,
      });
    }
  }, [reset, updatingGuest]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(guestId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    if (guestId === user.id) {
      delete user.avatar;
      delete user.avatarUrl;

      updateUser(user);
    }
  }, [guestId, handleToggleDeleteConfirmationModal, updateUser, user]);

  const handleUpdateGuest: SubmitHandler<UpdateGuestFormData> = useCallback(
    async ({ bio, cpf, email, experience, healthCheck, name, phone }) => {
      try {
        const updatedGuest = await updateUsersService({
          userId: guestId,
          bio,
          cpf,
          email,
          experience,
          healthCheck,
          name,
          phone,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const guestWithUpdatedAvatar = await updateUserAvatarsService({
            userId: guestId,
            avatarData: formData,
          });

          if (guestWithUpdatedAvatar.id === user.id) {
            updateUser(guestWithUpdatedAvatar);
          }
        } else if (updatedGuest.id === user.id) {
          updateUser(updatedGuest);
        }

        toast({
          title: 'Editado com sucesso',
          description: 'O convidado foi editado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao Edit',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao Edit o convidado, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [guestId, avatar, user.id, toast, goBack, updateUser],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente Delete?"
      />

      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateGuest)}
      >
        <Heading size="lg" fontWeight="normal">
          Edit convidado
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Full name"
            error={errors.name}
            {...register('name')}
          />

          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
              'repeat(12, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 6, 6, 4]}>
              <MaskedInput
                label="Document"
                mask="cpf"
                error={errors.cpf}
                {...register('cpf')}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6, 6, 4]}>
              <InternationalPhoneInput
                label="Phone"
                name="phone"
                control={control}
                error={errors.phone}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 12, 12, 4]}>
              <MaskedInput
                label="E-mail"
                type="email"
                textTransform="lowercase"
                error={errors.email}
                {...register('email')}
              />
            </GridItem>
          </Grid>

          <Grid
            templateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(12, 1fr)',
              'repeat(12, 1fr)',
              'repeat(12, 1fr)',
            ]}
            gap="8"
            width="100%"
          >
            <GridItem colSpan={[12, 12, 6, 6, 6]}>
              <ReactSelect
                label="Nível de experiência"
                name="experience"
                options={experienceSelectOptions}
                control={control}
                error={errors.experience}
              />
            </GridItem>

            <GridItem colSpan={[12, 12, 6, 6, 6]}>
              <DatePicker
                label="Exame médico"
                maxDate={new Date()}
                control={control}
                error={errors.healthCheck}
                {...register('healthCheck')}
              />
            </GridItem>
          </Grid>

          <MaskedInput
            label="Biography"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.bio}
            {...register('bio')}
          />
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
